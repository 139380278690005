import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const EventDescription = (props) => {

    const [EventDescription, setEventDescription] = useState('');
    const [EventKeyWordsMetages, setEventKeyWordsMetages] = useState('');
    const [EventBannerfile, setEventBannerfile] = useState('');
    const [EventBannerImage, setEventBannerImage] = useState('');
    const [EventPhotos, setEventPhotos] = useState([]);
    const [EventPhotoDetails, setEventPhotoDetails] = useState([]);
    const [CommId, setCommId] = useState('');
    const [EventImgId, seEventImgId] = useState('');
    const [ActionFlag, setActionFlag] = useState('');

    const [Loader, setLoader] = useState(false);
    const ref = useRef();

    const [EventDescriptionError, setEventDescriptionError] = useState('');
    const [EventKeywordsError, setEventKeywordsError] = useState('');
    const [EventBannerfileError, setEventBannerfileError] = useState('');
    const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE');
    const [DeleteCommPopup, setDeleteCommPopup] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [PhotoOpen, setPhotoOpen] = useState(false);
    const [EventPhotosError, setEventPhotosError] = useState('');

    const [DescriptionImage, setDescriptionImage] = useState([]);
    const [EditImageFlag, setEditImageFlag] = useState(false);
    const [EventOldDescription, setEventOldDescription] = useState('');
    const [BackgroundColor, setBackgroundColor] = useState('');
    
    const [BackgroundStatus, setBackgroundStatus] = useState(0);
    const [YesStatus, setYesStatus] = useState('');
    const [NoStatus, setNoStatus] = useState('active');

    // console.log(BackgroundColor);
    const uploadFileRef = useRef();

    const openLightbox = (index) => {
        setImageIndex(index);
        setIsOpen(true);
    };

    const openPhotoLightbox = (index) => {
        //console.log(index);
        setPhotoIndex(index);
        setPhotoOpen(true);
    };

    const closeLightbox = () => {
        setPhotoOpen(false);
    };

    const uplodSingleFile = (e) => {

        let file = e.target.files[0];
        setEventBannerfile(file);
        // if (file.size < imageSizeLimit) {
        //     setEventBannerfile(file);
        // } else {
        //     toast.error('Image size exceeds 2MB limit.');
        // }
        // console.log(file.name);
    };
   

    const uploadMultiFiles = (e) => {
        let files = []
        // for (let i = 0; i < e.target.files.length; i++) {
        //     files.push(e.target.files[i])
        // }
        //setEventPhotos(files);

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const isValidFormat = validateFileFormat(file);
            const isValidSize = validateFileSize(file);
      
            if(!isValidFormat) {
                setEventPhotos([]);
                uploadFileRef.current.value = [];
                setEventPhotosError("File does not support. You must use png, jpg, jpeg");
            }else if(!isValidSize){
                setEventPhotos([]);
                uploadFileRef.current.value = [];
                setEventPhotosError("Please upload a file smaller than 5 MB");
            }else{
                files.push(e.target.files[i]);
                setEventPhotos(files);
                setEventPhotosError('');
            }
        }
    };
    // console.log(EventPhotos);

    const validateFileFormat = (file) => {
        // console.log(file.name);
        const allowedFormats = ["jpg", "png", "jpeg", "JPG", "PNG", "JPEG"];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return allowedFormats.includes(fileExtension);
    };

    const validateFileSize = (file) => {
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        return file.size <= maxSizeInBytes;
      };
  //console.log(EventPhotos);

    const EventDetailsData = props.EventData ? props.EventData : '';
    const EventImageDetails = props.EventImgData ? props.EventImgData : '';

    useEffect(() => {
        window.scrollTo(0, 0);
        if (EventDetailsData) {
            // console.log(EventDetailsData);
            // console.log("Event Description : "+EventDetailsData,EventImageDetails);
            setCommId(EventDetailsData.id)
            setEventKeyWordsMetages(EventDetailsData.event_keywords !== null ? EventDetailsData.event_keywords : '');
            setEventBannerImage(EventDetailsData.banner_image); setEventPhotoDetails(EventImageDetails);
            setBackgroundColor(EventDetailsData.banner_bg_color); setBackgroundStatus(EventDetailsData.background_status);

            if(EventDetailsData.background_status === 1 || EventDetailsData.background_status === '1'){
                setYesStatus('active'); setNoStatus('');
            }else{
                setNoStatus('active'); setYesStatus('');
            }
            
            if(EventDetailsData.content_image && EventDetailsData.content_image !== ''){
                setEventDescription(EventDetailsData.event_description !== null ? EventDetailsData.event_description+''+EventDetailsData.content_image : ''); 
                setEventOldDescription(EventDetailsData.event_description !== null ? EventDetailsData.event_description : '');
            }else{
                setEventDescription(EventDetailsData.event_description !== null ? EventDetailsData.event_description : ''); 
                setEventOldDescription(EventDetailsData.event_description !== null ? EventDetailsData.event_description : '');
            }
        }
    }, [EventDetailsData])
   // console.log(EventBannerImage);

    const handleValidation = () => {
        // console.log(EventBannerfile.type);
        var flag = true;

        if (EventDescription === "") {
            setEventDescriptionError("Please Enter Description");
            flag = false;
        } else {
            setEventDescriptionError("");
        }

        if (EventKeyWordsMetages === "" || EventKeyWordsMetages === null || EventKeyWordsMetages === 'null') {
            //eventNameRef.current.focus();
            setEventKeywordsError("Please Enter Keywords/Metatages");
            flag = false;
        } else {
            setEventKeywordsError("");
        }

        const fileExtension = EventBannerfile ? EventBannerfile.name.split(".").at(-1) : '';
        const allowedFileTypes = ["jpg", "png", "jpeg", "JPG", "PNG", "JPEG"];

        if (EventBannerfile === "" && EventBannerImage === "") {
            setEventBannerfileError("Please select banner images");
            flag = false;
        } else 
        if (EventBannerfile !== "") {
            if (!allowedFileTypes.includes(fileExtension)) {
                setEventBannerfileError("File does not support. You must use png, jpg, jpeg");
                ref.current.value = "";
                flag = false;
            } else if (EventBannerfile.size > 5e6) {
                setEventBannerfileError("Please upload a file smaller than 5 MB");
                ref.current.value = "";
                flag = false;
            } else {
                setEventBannerfileError("");
            }
        }

        return flag;
    };

    // console.log(EventKeyWordsMetages);
    const handleSubmit = (e) => {
        // alert("submit")
        e.preventDefault();
        const flag = handleValidation();
        // console.log(flag);
        if (flag) {
            setLoader(true);
            props.setEventResponceTabs(2);
            const formData = new FormData();
            for (let i = 0; i < EventPhotos.length; i++) {
                formData.append("event_photos[]", EventPhotos[i]);
            }
            formData.append("event_banner", EventBannerfile);
            formData.append("event_id", localStorage.getItem("EventId"));
            formData.append("event_description", EventDescription);
            formData.append("event_keywords", EventKeyWordsMetages);
            formData.append("description_image", DescriptionImage);
            formData.append("background_color", BackgroundColor);
            formData.append("background_status", BackgroundStatus);
             
            // console.log(formData);
            axios.post("event_description", formData)
                .then((response) => {
                    if (response.status === 200) {
                        setLoader(false);
                        props.setEventResponceTabs(3);
                        // props.setEventDescriptionMsg(response.data.message);
                        toast.success(response.data.message)
                    }
                })
                .catch(() => {
                    setLoader(false);
                    console.log('error');
                });
        }
    };
   
    //DRAG AND DROP FUNCTIONALITY
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        let files = []
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            files.push(e.dataTransfer.files[i])
        }
        // const files = Array.from(e.dataTransfer.files);
        // Handle the dropped files (e.g., upload or process them)
        // console.log(files);
    };
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        // Handle the selected file
        // console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    const handleDivClick = () => {
        // Trigger click event on file input
        fileInputRef.current.click();
    };

    const fileInputRef = React.createRef();

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        const timestampSeconds = Math.floor(Date.now() / 1000);
                        const filename = "http://127.0.0.1:8000/uploads/event_description/"+timestampSeconds + '_' + file.name;

                        const formData = new FormData();
                        formData.append("event_id", localStorage.getItem("EventId"));
                        formData.append("event_description", file);
                         
                        axios.post("event_description", formData)
                        .then((res) => {
                            resolve({ default: `${filename}` });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const handleDescriptionChange = (e, editor) => {
        const data = editor.getData();
        setEventDescription(data)
    };

    const handleComm = (comm_id, operate) => {
     
        if (operate === "delete") {
            setDeleteCommPopup(true);
        }
    }

    const DeleteImages = () => {
         //console.log('ss');
        if (CommId !== "") {
            axios.post('delete_event_images',
                {
                    'event_id': CommId,
                    'event_img_id': EventImgId,
                    'common_flag': ActionFlag
                }
            )
            .then((response) => {
                // console.log(response);
                props.setEventApiCall(true)
                toast.success(response.data.message)
                setCommId('')
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    //-------------- Ckedit to upolad image
    function MyCustomUploadAdapterPlugin(editor) {
        // console.log(editor);
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
        };
        // return ClassicEditor.create(document.querySelector('#editor'), editor);
    }

    class UploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }
    
        upload() {
            return new Promise((resolve, reject) => {
                // const formData = new FormData();
                this.loader.file
                .then(file => {
                   setDescriptionImage(file); 
                   setEditImageFlag(true);
                });
            });
        }
    
        abort() {
            // Implement abort functionality if needed
        }
    }
    // console.log(DescriptionImage);
    // useEffect(() => {
    //     if(EditImageFlag){
    //         setEventDescription('<figure class="image"><img></figure>'+EventOldDescription);
    //     }
    // }, [EditImageFlag])
    // console.log(EventDescription);

    return (
        <>
            {
                DeleteCommPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                    
                                                    { ActionFlag && ActionFlag === 'banner_delete' ?
                                                       <p className="account__login--header__desc">Are you sure you want to delete this banner image?</p>
                                                    :
                                                      <p className="account__login--header__desc">Are you sure you want to delete this photo?</p>
                                                    }
                                                   
                                                </div>  
                                                <div className="d-flex gap-4">
                                                  
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteCommPopup(false);
                                                    }}>Cancel</button>
                                                  
                                                    <button className="account__login--btn primary__btn mt-2" onClick={(e) => {  DeleteImages(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* <ToastContainer theme="coloured" /> */}
            {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            <div className="main checkout__mian">
                <form method="post" onSubmit={handleSubmit} >

                    <div className="checkout__content--step section__shipping--address ">
                        <div className="section__header d-flex align-items-center justify-content-between mb-4">
                            <h2 className="section__header--title h3">Event Description </h2>
                        </div>
                        
                        <div className="row ">
                        {/* <div className="mb-2">
                            <div className="section__header mb-4">
                                <h2 className="section__header--title h3">Event Description <span className="checkout__input--label__star">*</span></h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 mb-20">
                                    <div className="order-notes">
                                        <textarea className="checkout__notes--textarea__field border-radius-5" placeholder="Enter event description" rows="5" value={EventDescription} onChange={(e) => { setEventDescription(e.target.value); }} ></textarea>
                                        <small className="text-danger">{EventDescriptionError}</small>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-sm-12">
                        <label className="label mb-3" htmlFor="input1">Description <span className="checkout__input--label__star">*</span></label>
                            <div className="form-floating mt-3 mb-0">
                           
                                {/* <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    rows="5"
                                    style={{ minHeight: "100px", display: "inline-block", resize: "both" }}
                                    value={EventDescription} onChange={(e) => { setEventDescription(e.target.value); }}
                                />
                                <label>Event Description<span className="checkout__input--label__star">*</span></label> */}
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin],
                                        extraPlugins: [MyCustomUploadAdapterPlugin],
                                    }}
                                    editor={ClassicEditor}
                                    data={EventDescription ? EventDescription : ""}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                            <small className="text-danger">{EventDescriptionError}</small>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-floating mt-3 mb-0">
                                <textarea
                                    type="text"
                                    className="form-control resp-textarea"
                                    placeholder=""
                                    rows="5"
                                    value={EventKeyWordsMetages} onChange={(e) => { setEventKeyWordsMetages(e.target.value); }}
                                />
                                <label>Keywords/ Metatags <span className="checkout__input--label__star">*</span> <small style={{color: 'gray'}}>(Search engine looks for this)</small> </label>
                            </div>
                            <small className="text-danger">{EventKeywordsError}</small>
                        </div>

                       

                        <div className="row py-3">
                            <h2 className="section__header--title h3 mb-4">Event Images</h2>

                            <div className="col-lg-6 col-md-6 mb-4">
                                <div className="order-notes">
                                    <label className="checkout__input--label mb-3" htmlFor="input1">Event Banner <span className="checkout__input--label__star">*</span> <small style={{color: 'gray'}}>(In jpg, jpeg, png formats. Max upto 5MB. 
                                        <br/> Dimensions- 1920 px x 744 px)</small> </label>
                                    <input type="file" className="form-control" ref={ref} onChange={uplodSingleFile} />
                                    {EventBannerfile && (
                                        <div className="my-4 e-photos-container">
                                            {<img src={URL.createObjectURL(EventBannerfile) || EventBannerImage} alt='' style={{ width:"100%", maxHeight: "300px",  objectFit: "contain"}} />}
                                            <div className="e-btn-view">
                                                    {/* <a href={URL.createObjectURL(EventBannerfile) || EventBannerImage} target='_blank' rel="noreferrer" className="e-view-btn p-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                                                    </a>
                                                    <a href={URL.createObjectURL(EventBannerfile) || EventBannerImage} target='_blank' rel="noreferrer" className="e-delete-btn p-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                    </a> */}
                                                </div>
                                        </div>
                                    )}

                                    {EventBannerImage && EventBannerfile === '' ?
                                        <div className="my-4 e-photos-container">
                                            
                                                <img src={EventBannerImage} alt=''  style={{ width:"100%", maxHeight: "300px",  objectFit: "contain"}} />
                                                
                                                <div className="e-btn-view">
                                                    {/* <a href={EventBannerImage} target='_blank' rel="noreferrer" className="e-view-btn p-3"> */}
                                                    <a onClick={() => openLightbox(0)} rel="noreferrer" className="e-view-btn p-3">
                                                    
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                                                    </a>
                                                    <a rel="noreferrer" className="e-delete-btn p-3" onClick={() => { setCommId(CommId); setActionFlag('banner_delete'); handleComm(CommId, 'delete');  }} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                    </a>
                                                </div>

                                        </div>
                                        : ''}

                                    <small className="text-danger">{EventBannerfileError}</small>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 mb-4">
                                <div className="order-notes">
                                    {/* Event Photos */}
                                    <label className="checkout__input--label mb-3" htmlFor="input1">Event Communication Creatives <small style={{color: 'gray'}}>(you can choose multiple photos)</small> </label><br /><br />
                                    <input type="file" name="file" className="form-control mb-4" multiple onChange={(e) => uploadMultiFiles(e)} ref={uploadFileRef}/>
                                    
                                    <div className='row' style={{maxHeight: "300px", overflow: "auto"}}>
                                        {EventPhotos.map((file, index) => (
                                            <div className='col-md-6 e-photos-container' key={index} >
                                                <img key={index} src={URL.createObjectURL(file)} alt='' style={{width: "100%", height: "140px", objectFit: "contain"}} />
                                                <div className="e-btn-view">
                                                        {/* <a href={index} src={URL.createObjectURL(file)} target='_blank' rel="noreferrer" className="e-view-btn p-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                                                        </a>
                                                        <a href={index} src={URL.createObjectURL(file)} target='_blank' rel="noreferrer" className="e-delete-btn p-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                        </a> */}
                                                    </div>
                                            </div>
                                        ))}
                                    
                                        {EventPhotoDetails ?
                                            EventPhotoDetails.map((item, index) => (
                                                <div className='col-md-6 py-3 e-photos-container' key={index}>
                                                    
                                                    <img key={index} src={item.image} alt='' style={{width: "100%", height: "140px", objectFit: "contain"}}  />
                                                  

                                                    <div className="e-btn-view">
                                                        {/* <a href={item.image} target='_blank' rel="noreferrer" className="e-view-btn p-3"> */}
                                                        <a rel="noreferrer" className="e-view-btn p-3" onClick={(e) => { e.preventDefault(); openPhotoLightbox(index); }} >
                                                        
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                                                        </a>
                                                        <a rel="noreferrer" className="e-delete-btn p-3" onClick={() => { setCommId(CommId); seEventImgId(item.id); setActionFlag('photo_delete'); handleComm(CommId, 'delete');  }} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                        </a>
                                                    </div> 


                                                </div>
                                            )) : ''}

                                            <small className="text-danger">{EventPhotosError}</small>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-2">
                           
                            <div className="section__header checkout__section--header mb-4 d-flex justify-content-between">
                                <div>
                                    <label className="checkout__input--label mb-3" htmlFor="input1">Banner Background </label>
                                    <   br/>
                                    <div className="event-visibility hand_cursor" style={{width:"fit-content"}}>
                                        <div className={'visibility gap-1 ' + YesStatus} onClick={() => {   setBackgroundStatus(1); setYesStatus('active'); setNoStatus(''); }}> Yes 
                                        </div>

                                        <div className={'visibility gap-1 ' + NoStatus} onClick={() => { setBackgroundStatus(0); setYesStatus(''); setNoStatus('active'); }} >
                                        No
                                        </div> 
                                    </div>
                                </div>

                               { BackgroundStatus === 1 ?
                                <div className="col-md-6 mb-2">
                                    <div>
                                        <label className="checkout__input--label mb-3" htmlFor="input1">Select Background Color </label>
                                        <input type="color" name="file" className="form-control my-2" value={BackgroundColor} onChange={(e) => { setBackgroundColor(e.target.value); }} />                                    
                                    </div>
                                </div>
                                
                            : null }

                               </div>


                            </div>
                            

                        </div>


                      
                      </div>
                    </div>

                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e)=>{props.setEventResponceTabs(1)}} style={{cursor: 'pointer'}}>Back</div>

                        <input type="submit" name="command" value="Save & Next (3/11)" className="continue__shipping--btn primary__btn border-radius-5" />

                    </div>
                </form>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={EventBannerImage} // Determine which image to show based on imageIndex
                    onCloseRequest={() => setIsOpen(false)}
                    reactModalStyle={{
                        overlay: { zIndex: 9999 }
                    }}
                />
            )}

            { PhotoOpen && (
              <Lightbox
                mainSrc={`${EventPhotoDetails[photoIndex].image}`}
                nextSrc={EventPhotoDetails[(photoIndex + 1) % EventPhotoDetails.length]}
                prevSrc={EventPhotoDetails[(photoIndex + EventPhotoDetails.length - 1) % EventPhotoDetails.length]}
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + EventPhotoDetails.length - 1) % EventPhotoDetails.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % EventPhotoDetails.length)
                }
              />
            )}

        </>
    )
}

export default EventDescription;

