import React, { useEffect, useState, useCallback, useRef } from 'react';
import { json, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../axios';
import HeaderSecond from '../HeaderSecond';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import { ToastContainer } from 'react-toastify';
import RegistrationSuccess from '../img/success.gif'
import { Calculation } from './Calculation';
import PaymentConfirmPopup from "../Event/PaymentConfirmPopup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../Loader';
import { differenceInYears, parseISO, parseJSON } from 'date-fns';
import { useDispatch } from "react-redux";
import { token } from "../features/TokenSlice";

const CustomTooltip = ({ text,HintType,HintImage }) => (
  <div className={HintType === 2 ? "custom-tooltip custom-tooltip-img": "custom-tooltip"}>
    { HintType === 2 && HintImage !== '' ?
       <img src={HintImage} style={{width:"auto", zIndex:"9"}} /> : text
    }
  </div>
);

const TicketRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [TotalAttendee, setTotalAttendee] = useState(0);
  const [AllTickets, setAllTickets] = useState([]);
  const { EventId } = useParams();
  const [FormQuestions, setFormQuestions] = useState([]);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [FinalAmount, setFinalAmount] = useState(0);
  const [TotalDiscount, setTotalDiscount] = useState(0);
  const [EventData, setEventData] = useState([]);
  const [AttendeeType, setAttendeeType] = useState([]);

  const [Error, setError] = useState('');
  const [Success, setSuccess] = useState(false);
  const [ShowIndex, setShowIndex] = useState(1);
  const [Amount, setAmount] = useState([]);
  const [Flag, setFlag] = useState(false);
  const [EventUrl, setEventUrl] = useState('');
  const [UtmCampaign, setUtmCampaign] = useState("");
  const [Tooltip, setTooltip] = useState('');
  const [GstArray, setGstArray] = useState([]);
  const [ApiCall, setApiCall] = useState(false);
  const [GstCall, setGstCall] = useState(false);
  const [ExtraAmtCall, setExtraAmtCall] = useState(false);
  const [TempTicketId, setTempTicketId] = useState("");
  const [TermsAndCondition, setTermsAndCondition] = useState(false);
  const [TermsAndConditionError, setTermsAndConditionError] = useState("");

  const [OrgGstPercentage, setOrgGstPercentage] = useState('');
  const [TicketYtcrBasePrice, setTicketYtcrBasePrice] = useState('');
  const [YtcrFeePercentage, setYtcrFeePercentage] = useState('');
  const [PlatformFeePercentage, setPlatformFeePercentage] = useState('');
  const [PaymentGatewayFeePercentage, setPaymentGatewayFeePercentage] = useState('');
  const [PaymentGatewayGstPercentage, setPaymentGatewayGstPercentage] = useState('');
  const [CollectGst, setCollectGst] = useState('');
  const [PriceTaxesStatus, setPriceTaxesStatus] = useState('');

  const [LoaderFlag, setLoaderFlag] = useState(false);
  const [PaymentDetailsFlag, setPaymentDetailsFlag] = useState(false);
  const [ProceedTab, setProceedTab] = useState(true);
  const [AgeCatFlag, setAgeCatFlag] = useState(false);
  const [Key, setKey] = useState('');
  const [Index, setIndex] = useState('');
  const [PaymentConfirmPopupflag, setPaymentConfirmPopupflag] = React.useState(false);
  const [BookTicktesArray, setBookTicktesArray] = useState([]);
  const [TermsConditions, setTermsConditions] = useState([]);
  const [TermsConditionsFlag, setTermsConditionsFlag] = useState(false);
  const [BookProcessFlag, setBookProcessFlag] = useState(false);
  const [BookingPayId, setBookingPayId] = useState('')
  const [EventStartTime, setEventStartTime] = useState('');
  const [RaceCategoryCharges, setRaceCategoryCharges] = useState([]);
  const [OtherAmountReset, setOtherAmountReset] = useState(1);
  const [SendEmailFlag, setSendEmailFlag] = useState(false);
  
  const [AllowUniqueRegistration, setAllowUniqueRegistration] = useState(0);
  
  const [AttendeeIdAmt, setAttendeeIdAmt] = useState(0);
  const [AttendeeFlag, setAttendeeFlag] = useState(false);
  const [OptionFlag, setOptionFlag] = useState(0);
  const [AllEmailsDetails, setAllEmailsDetails] = useState([]);
  const dispatch = useDispatch();
  const [LoginExpiredFlag, setLoginExpiredFlag] = useState(false);
  const [Login, setLogin] = useState(false);

  // const [DuplicateEmailIds, setDuplicateEmailIds] = useState([]);
  // const [DuplicateMobileNo, setDuplicateMobileNo] = useState([]);

  // console.log(FormQuestions.length);
  // console.log(JSON.stringify(AllTickets))
  const [dupMob, setdupMob] = useState(0);
 
  useEffect(() => {
    if (EventId > 0) {
      localStorage.setItem("RegEventId", EventId)
    }
  }, [EventId])

  useEffect(() => {
    const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
    // console.log(RacesToken);
    if(RacesToken && RacesToken !== ''){
       // setLogin(false);
       if (RacesToken) {
        const payload = {
          "user_id": localStorage.getItem('ID') ? localStorage.getItem('ID') : 0,
        }
        axios.post("/checkUserLastLoginDetails", payload)
          .then((response) => {
            if (response.status === 200) {
              if(response.data.data === 1){
                //  toast.error("Login session expired");
               // alert("Login session expired");
                setLoginExpiredFlag(true);
              }else{
                setLogin(false);
              }
            }
          })
          .catch((error) => {
             console.log('error');
          })
        }
    }else{
       setLogin(true);
    }
  }, []);

  const LoginExpired = () => {
    setLoginExpiredFlag(false);
        //--------------- Logout user
        localStorage.removeItem("Token");
        localStorage.removeItem("Name");
        localStorage.removeItem("Modules");
        localStorage.removeItem("ID");
        localStorage.removeItem("booking_pay_id");
        localStorage.removeItem("RacesToken");
        localStorage.removeItem("LoginAsOrganiser");
        localStorage.removeItem("Access_Right_Flag");
        localStorage.removeItem("OrgUserAccessModules");
        localStorage.removeItem("Role_Name");
        localStorage.removeItem("SuperAdmin");
        localStorage.removeItem("EMAIL");
        localStorage.removeItem("MOBILE");
        localStorage.removeItem("EventDetailsId");
        localStorage.removeItem("RegEventId");
        dispatch(token(""));
        navigate('/');
        setLogin(true);
  }

  
  //--- get location throgh data
  useEffect(() => {
    if (location) {
      // console.log(location.state);
      if (location.state) {
        setTotalAttendee(location.state.TotalAttendee);
        setAllTickets(location.state.AllTickets);
        setTotalPrice(location.state.FinalTotalPrice);
        setTotalDiscount(location.state.TotalDiscount);
        setEventData(location.state.EventData);
        setFinalAmount(location.state.FinalTotalPrice);
        // console.log(location.state.FinalTotalPrice);
        setUtmCampaign(location.state.UtmCampaign)
        setFlag(true); setApiCall(true)
      }
    }
  }, [AllTickets, TotalAttendee, location])

  // CREATE EVENT SHARE URL
  useEffect(() => {
    // console.log(EventData);
    if (Flag) {
      let eventURL = window.location.origin + "/event/" + EventId + "#attendees";
      var eventName = EventData.display_name;
      var copyText = window.location.origin + process.env.PUBLIC_URL;
      // console.log(eventName);
      const formattedEventName = eventName.replace(/\s/g, '_');
      copyText = copyText + "/e/" + formattedEventName;
      setEventUrl(copyText)
      setFlag(false);
    }
  }, [Flag, EventData, EventData.display_name, EventId])

  // GET FROM QUESTIONS 
  useEffect(() => {
    if (ApiCall) {
      // console.log(JSON.stringify(AllTickets));
      setLoaderFlag(true);
      if (EventId !== 0) {
        axios.post("get_form_questions", {
          "event_id": EventId,
          "total_attendee": TotalAttendee,
          "AllTickets": AllTickets
        })
          .then((response) => {
            // console.log(response);
            setLoaderFlag(false);
        
            if (response.data.data.FormQuestions.length === 0) {
              setError("No questions available yet for this event category");
              setProceedTab(false);
            }
            // console.log(response.data.data.FormQuestions);
            setFormQuestions(response.data.data.FormQuestions);
            setOrgGstPercentage(response.data.data.OrgGstPercentage)
            setTicketYtcrBasePrice(response.data.data.TicketYtcrBasePrice)
            setYtcrFeePercentage(response.data.data.YTCR_FEE_PERCENT)
            setPlatformFeePercentage(response.data.data.PLATFORM_FEE_PERCENT)
            setPaymentGatewayFeePercentage(response.data.data.PAYMENT_GATEWAY_FEE_PERCENT)
            setPaymentGatewayGstPercentage(response.data.data.PAYMENT_GATEWAY_GST_PERCENT)
            setCollectGst(response.data.data.CollectGst);
            setPriceTaxesStatus(response.data.data.PriceTaxesStatus);
            setEventStartTime(response.data.data.EventStartTime);
            setRaceCategoryCharges(response.data.data.race_category_charges_details);
           
            setAllowUniqueRegistration(response.data.data.AllowUniqueRegistration);

            setTermsConditions(response.data.data.TermsConditions);
            if (response.data.data.TermsConditions.length > 0) {
              setTermsConditionsFlag(true);
            }
            localStorage.setItem("MAX_UPLOAD_FILE_SIZE", response.data.data.MAX_UPLOAD_FILE_SIZE)
            // setDuplicateEmailIds(response.data.data.DuplicatedEmailIds);
            // setDuplicateMobileNo(response.data.data.DuplicatedMobileNo)
            
            //---------- ticket key wise array set
            if (response.data.data.FormQuestions !== "") {
              const keysArray = Object.keys(response.data.data.FormQuestions);
              let obj = [];
              keysArray.forEach((item, index) => {
                AllTickets.forEach((ticket) => {
                  if (parseInt(ticket.id) === parseInt(item)) {
                    obj.push(ticket);
                  }
                })
              });

              // console.log(obj);
              obj.forEach((item, index) => {
                if (item.count > 0) {
                  const TicketPrice = (item.show_early_bird === 1) ? parseFloat(item.discount_ticket_price) : parseFloat(item.ticket_price);
                  // (item.hasOwnProperty("appliedCouponId")
                  let TicketPriceFloat = (item.hasOwnProperty("appliedCouponAmount")) ? TicketPrice - item.appliedCouponAmount : TicketPrice;

                  item.ticket_show_price = (parseInt(item.ticket_status) === 1) ? TicketPriceFloat : 0;
                  item.YtcrFee = !isNaN(item.YtcrFee) && (parseInt(item.ticket_status) === 1) ? item.count * item.YtcrFee : 0;
                  item.YtcrAmount = !isNaN(item.YtcrAmount) && (parseInt(item.ticket_status) === 1) ? item.count * item.YtcrAmount : 0;
                  item.PaymentGatewayAmount = !isNaN(item.PaymentGatewayAmount) && (parseInt(item.ticket_status) === 1) ? item.count * item.PaymentGatewayAmount : 0;
                  item.PaymentGatewayWithGst = !isNaN(item.PaymentGatewayWithGst) && (parseInt(item.ticket_status) === 1) ? item.count * item.PaymentGatewayWithGst : 0;
                  item.RegistrationFee = !isNaN(item.RegistrationFee) && (parseInt(item.ticket_status) === 1) ? item.count * item.RegistrationFee : 0;
                  item.RegistrationGstPercentage = !isNaN(item.registration_18_percent_GST) && (parseInt(item.ticket_status) === 1) ? item.count * item.registration_18_percent_GST : 0;
                }
              });
              setGstArray(obj);
              setGstCall(true);

            }
          })
          .catch((error) => {
            setLoaderFlag(false);
            console.log(error);
          });
      }
      setApiCall(false)
    }
  }, [EventId, TotalAttendee, AllTickets, ApiCall]);

  // console.log(FormQuestions)

  useEffect(() => {

    if (GstArray.length > 0 && GstCall) {
      let CartItemTotal = 0;

      GstArray.forEach((item, index) => {
        if (parseInt(item.ticket_status) === 1) {
          
          // let ticket_show_price = 0;
          // ticket_show_price = parseFloat(item.count * parseFloat(item.ticket_show_price)).toFixed(2);
          // // console.log(item.YtcrFee,item.YtcrAmount);
          // let RegistrationFee = item.RegistrationFee !== "undefined" ? item.RegistrationFee : 0;
          // let YtcrFee = item.YtcrFee !== "undefined" ? item.YtcrFee : 0;
          // let YtcrAmount = item.YtcrAmount !== "undefined" ? item.YtcrAmount : 0;
          // let PaymentGatewayAmount = item.PaymentGatewayAmount !== "undefined" ? item.PaymentGatewayAmount : 0;
          // let PaymentGatewayWithGst = item.PaymentGatewayWithGst !== "undefined" ? item.PaymentGatewayWithGst : 0;
          // // console.log(RegistrationFee,YtcrFee,YtcrAmount,PaymentGatewayAmount,PaymentGatewayWithGst);
          // CartItemTotal += (YtcrFee) + (YtcrAmount) + (PaymentGatewayAmount) + (PaymentGatewayWithGst) + (RegistrationFee) + parseFloat(ticket_show_price);
          // console.log(CartItemTotal);
          //console.log(item.Extra_Amount_Payment_Gateway,item.Extra_Amount_Payment_Gateway_Gst);
          if(item.Extra_Amount_Payment_Gateway !== '0.00' && item.Extra_Amount_Payment_Gateway_Gst !== '0.00'){
          
            CartItemTotal += ((parseFloat(item.BuyerPayment)* (item.count)) + parseFloat(item.Extra_Amount_Payment_Gateway) + parseFloat(item.Extra_Amount_Payment_Gateway_Gst));
          }else{
            CartItemTotal += parseFloat(item.BuyerPayment)* (item.count);
          }
         
          if(item.BuyerAmtWithoutPaymentGateway && item.BuyerAmtWithoutPaymentGateway !== '0'){
             CartItemTotal += parseFloat(item.BuyerAmtWithoutPaymentGateway);
          }
         
        }
      });
      // console.log(CartItemTotal);
      setFinalAmount(CartItemTotal);
    }
  }, [GstArray, GstCall])

  // console.log(FinalAmount);
  const [ParitcipantFiles, setFiles] = useState([]);
  const [FinalParitcipantFiles, setUploadFiles] = useState([]);
  //console.log(FinalParitcipantFiles);
 
  const handleFileChange = (e, temp, index, SubIndex, QIndex, general_form_id, Key) => {
    const file = e.target.files[0];
    // if (file && (file.type.startsWith('image/') || file.type.startsWith('application/'))) {
    if (file) {
      setFiles(prevFiles => [...prevFiles, file]);
      // setFiles(prevFiles => {
      //   const existingFileIndex = prevFiles.findIndex(f => f.general_form_id === general_form_id && f.Key === Key);
      //   if (existingFileIndex > -1) {
      //     const updatedFiles = [...prevFiles];
      //     updatedFiles[existingFileIndex] = { general_form_id, Key, file };
      //     return updatedFiles;
      //   } else {
      //     return [...prevFiles, { general_form_id, Key, file }];
      //   }
      // });
    }
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    const acceptedFileTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    if (file) {
      if ((file.type.startsWith('image/') || file.type.startsWith('application/')) && file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
        // File is an image or application type and is within size limit
        setUploadFiles(prevFiles1 => [...prevFiles1, file]);
      } else {
        // File type is not supported or size exceeds 2MB
        // console.log("Error: File type not supported or size exceeds 2MB.");
        // You can optionally show an error message or handle it in another way
      }
    }
  }
  
  let mobflattenedData = [];
  let mobileEntries = [];
  let mobileValues = [];

  let flattenedData = [];
  let emailEntries = [];
  let emailValues = [];
  //-------------- key - ticket id, subIndex - particept no , QIndex - ques array index
  const handleFormAnswers = async (e, Key, SubIndex, QIndex, chkId, itemCountId, itemCount, itemSold, itemTempCount) => {
  //  console.log(Key,SubIndex,QIndex);
    // let aaa = { ...ParitcipantFiles };
  // console.log(chkId); 

    let TempFormQuestions = { ...FormQuestions };
    const arrayToModify = TempFormQuestions[Key];
    const newArray = [...arrayToModify];
   
    if (newArray[SubIndex][QIndex].question_form_type === "file") { // && newArray[SubIndex][QIndex].ActualValue === ""
      const file = e.target.files[0];
      // console.log(file);
     
      if (file) {
        const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE') ? localStorage.getItem('MAX_UPLOAD_FILE_SIZE') : '2097152'; // 2 MB limit
        // console.log(imageSizeLimit);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg','application/pdf'];

        if (file.size < imageSizeLimit) {
          if (allowedTypes.includes(file.type)) {
            newArray[SubIndex][QIndex].ActualValue = file.name;
            newArray[SubIndex][QIndex].Error = "";
          } else {
            newArray[SubIndex][QIndex].ActualValue = file.name;
            newArray[SubIndex][QIndex].Error = "Only jpg, png and pdf allowed.";
          }
        } else {
          newArray[SubIndex][QIndex].ActualValue = file.name;
          newArray[SubIndex][QIndex].Error = "File size exceeds 2MB limit.";
        }

      } else {
        newArray[SubIndex][QIndex].Error = "";
      }

    }
    else {
     
      if(newArray[SubIndex][QIndex].question_form_type === "checkbox"){
        newArray[SubIndex][QIndex].ActualValue = 0;
      }else{
        newArray[SubIndex][QIndex].ActualValue = e.target.value;
      }
      

      //------------ new check t-shirt limit validation
      // if (newArray[SubIndex][QIndex].question_form_type === "select" && newArray[SubIndex][QIndex].question_label === "T-shirt Size"){
      //   // console.log('s');
      //   const dropdownArray = JSON.parse(newArray[SubIndex][QIndex].question_form_option);
      //   // console.log(itemCountId, itemCount, itemSold, itemTempCount);
      //   if (itemCount !== null && itemSold !== null) {
      //     console.log(itemCount,itemSold);
      //     if (parseFloat(itemCount) <= parseFloat(itemSold)) {
      //       console.log('dd');
      //       newArray[SubIndex][QIndex].Error = 'The ' + newArray[SubIndex][QIndex].question_label + ' you want to add is out of stock.'
      //     }
      //   }
      // }

      // Validations
      if ((newArray[SubIndex][QIndex].ActualValue === "" || newArray[SubIndex][QIndex].ActualValue === "" === 0) && newArray[SubIndex][QIndex].is_manadatory === 1) {
        // console.log(newArray[SubIndex][QIndex].question_form_type);
        if (newArray[SubIndex][QIndex].question_form_type === "select" || newArray[SubIndex][QIndex].question_form_type === "radio" || newArray[SubIndex][QIndex].question_form_type === "countries" || newArray[SubIndex][QIndex].question_form_type === "file") {
          newArray[SubIndex][QIndex].Error = 'Please select ' + newArray[SubIndex][QIndex].question_label;
        } else {
          newArray[SubIndex][QIndex].Error = 'Please enter ' + newArray[SubIndex][QIndex].question_label;
        }

      } else if (newArray[SubIndex][QIndex].question_form_type === "email" && newArray[SubIndex][QIndex].ActualValue !== "") {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = 'Please enter valid email address';
          // flag = false;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else if (newArray[SubIndex][QIndex].question_form_type === "mobile" && newArray[SubIndex][QIndex].ActualValue !== "") {
        if (!/^[0-9]{10}$/i.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = "Please enter valid 10-digit " + newArray[SubIndex][QIndex].question_label;
          // flag = false;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else if (newArray[SubIndex][QIndex].question_form_type === "amount" && newArray[SubIndex][QIndex].ActualValue !== "") {
        // if (!/^\d+(?:\.\d{1,2})?$/i.test(e.target.value) && !/^\d{0,10}$/i.test(e.target.value)) {
        if (!/^\d+(?:\.\d{1,2})?$/i.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = "Please enter valid " + newArray[SubIndex][QIndex].question_label;
          // flag = false;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else if (newArray[SubIndex][QIndex].question_form_name === "pincode" && newArray[SubIndex][QIndex].ActualValue !== "") {
        if (!/^\d{6}$/.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = "Please enter a valid 6 digit " + newArray[SubIndex][QIndex].question_label;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else if (newArray[SubIndex][QIndex].question_form_name === "emergency_contact_name" && newArray[SubIndex][QIndex].ActualValue !== "") {
        if (!/^[a-zA-Z\s]+$/.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = "Please enter a valid " + newArray[SubIndex][QIndex].question_label;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else if (newArray[SubIndex][QIndex].question_form_name === "emergency_contact_number" && newArray[SubIndex][QIndex].ActualValue !== "") {
        if (!/^[0-9]{10}$/i.test(e.target.value)) {
          newArray[SubIndex][QIndex].Error = "Please enter a valid " + newArray[SubIndex][QIndex].question_label;
        } else {
          newArray[SubIndex][QIndex].Error = '';
        }
      } else {
        newArray[SubIndex][QIndex].Error = '';
      }

      // -------------- Limit Check Validations------------------
      if (newArray[SubIndex][QIndex].ActualValue !== "" && newArray[SubIndex][QIndex].limit_check === 1) {
        if (newArray[SubIndex][QIndex].question_form_type === "text") {
          if (newArray[SubIndex][QIndex].limit_length !== "") {
            const arrayConfig = JSON.parse(newArray[SubIndex][QIndex].limit_length);
            const maxLength = arrayConfig.max_length;
            const minLength = arrayConfig.min_length;
            // console.log(arrayConfig, maxLength, minLength);
            if (newArray[SubIndex][QIndex].ActualValue.length > maxLength || newArray[SubIndex][QIndex].ActualValue.length < minLength) {
              if (maxLength === minLength) {  // mobile no
                newArray[SubIndex][QIndex].Error = newArray[SubIndex][QIndex].question_label + '  should be exactly ' + minLength + ' digits ';
              } else {   // text box 
                newArray[SubIndex][QIndex].Error = newArray[SubIndex][QIndex].question_label + ' character length should be between ' + minLength + ' - ' + maxLength + ' characters';
              }
            }
          }
        }
      }
     
      // ----------------- radio button functionality
      if (newArray[SubIndex][QIndex].question_form_type === "radio" && newArray[SubIndex][QIndex].ActualValue !== "" && newArray[SubIndex][QIndex].question_form_option !== "") {
        var ActualValue = parseInt(newArray[SubIndex][QIndex].ActualValue);
        var JsonArr = JSON.parse(newArray[SubIndex][QIndex].question_form_option);
        JsonArr.forEach(function (item) {
          item.checked = false;
          if (parseInt(item.id) === ActualValue)
            item.checked = true;
        });
        var updatedQuestionFormOption = JSON.stringify(JsonArr);
        newArray[SubIndex][QIndex].question_form_option = updatedQuestionFormOption;
      }

      // ----------------- checkbox button functionality
      if (newArray[SubIndex][QIndex].question_form_type === "checkbox" && newArray[SubIndex][QIndex].ActualValue !== "" && newArray[SubIndex][QIndex].question_form_option !== "") {
        // console.log(newArray[SubIndex][QIndex].question_form_option);
        var ActualValue = parseInt(newArray[SubIndex][QIndex].ActualValue);
        var JsonArr = JSON.parse(newArray[SubIndex][QIndex].question_form_option);
        //  console.log(JsonArr);
        const updatedItems = JsonArr.map(item =>
          item.id === chkId ? { ...item, checked: !item.checked } : item
        );
    
        var updatedQuestionFormOption = JSON.stringify(updatedItems);
        
        // newArray[SubIndex][QIndex].ActualValue = e.target.value;
        let ids = [];
        for (let i = 0; i < updatedItems.length; i++) {
          if (updatedItems[i].checked === true) {
            ids.push(updatedItems[i].id);
          }
        }
        const checkboxIds = ids.join(',');
        // console.log(checkboxIds);
        if(checkboxIds !== ''){
          newArray[SubIndex][QIndex].ActualValue = checkboxIds;
        }
       
        newArray[SubIndex][QIndex].question_form_option = updatedQuestionFormOption;
      }

      //console.log(newArray);
      // ---------------------- 23May 2024 --- birthday condition to check age limit

      if (newArray[SubIndex][QIndex].question_form_name === "date_of_birth") {

        let AgeLimit = AllTickets.find(ticket => ticket.id === parseInt(Key));
        if (AgeLimit) {
          const apply_age_limit = AgeLimit.apply_age_limit;
          if (parseInt(apply_age_limit) === 1) {
            const age_start = AgeLimit.age_start;
            const age_end = AgeLimit.age_end;
            let dateOfBirth = e.target.value;
            let dobDate = parseISO(dateOfBirth);
            const newDate = new Date(EventStartTime * 1000);

            let age = differenceInYears(newDate, dobDate);
            if (age < age_start || age > age_end) {
              newArray[SubIndex][QIndex].Error = 'Age should be between ' + age_start + ' and ' + age_end + ' years';
            } else {
              newArray[SubIndex][QIndex].ActualValue = e.target.value;
            }
          }
        }

      }
      // ---------------------------------------End 23May 2024

      //----------------- Time picker validation on 08-08-24
      if (newArray[SubIndex][QIndex].question_form_type === "time") {
        let Timevalue = e.target.value.replace(/[^0-9:]/g, ''); 
        //  console.log(Timevalue.length);
          // && Timevalue.length > newArray[SubIndex][QIndex].ActualValue.length
          if ((Timevalue.length === 2 || Timevalue.length === 5)) {
              Timevalue += ':';
          }

          if (Timevalue.length > 8) {
              Timevalue = Timevalue.slice(0, 8);
          }

          const parts = Timevalue.split(':');
          if (parts.length > 0 && parts[0].length === 2) {
              const hours = parseInt(parts[0], 10);
              if (hours < 0 || hours > 23) {
                Timevalue = '';
              }
          }
          if (parts.length > 1 && parts[1].length === 2) {
              const minutes = parseInt(parts[1], 10);
              if (minutes < 0 || minutes > 59) {
                Timevalue = '';
              }
          }
          if (parts.length > 2 && parts[2].length === 2) {
              const seconds = parseInt(parts[2], 10);
              if (seconds < 0 || seconds > 59) {
                Timevalue = '';
              }
          }
          newArray[SubIndex][QIndex].ActualValue = Timevalue;

          if(newArray[SubIndex][QIndex].ActualValue === "" && newArray[SubIndex][QIndex].is_manadatory === 1){
             newArray[SubIndex][QIndex].Error = 'Please Enter ' + newArray[SubIndex][QIndex].question_label;
          }else if(newArray[SubIndex][QIndex].ActualValue !== ""){
          
            const ValidTime = newArray[SubIndex][QIndex].ActualValue.split(':');
            const ValidateTime = ValidTime.join('');
            // console.log(ValidateTime);
            if (ValidateTime.length !== 6 || ValidTime.includes('')) {
              newArray[SubIndex][QIndex].Error = 'Invalid '+newArray[SubIndex][QIndex].question_label+' time. Please Enter time in HH:MM:SS format.';
            }
          }
      }

      // console.log(newArray);

      // get states of selected country
      if (newArray[SubIndex][QIndex].question_form_type === "countries" && newArray[SubIndex][QIndex].ActualValue !== "" && newArray[SubIndex][QIndex].question_label !== "Nationality") {
        let countryId = parseInt(newArray[SubIndex][QIndex].ActualValue);
        let states = await getStates(countryId);
        if (states.length > 0) {
          // eslint-disable-next-line array-callback-return
          newArray[SubIndex].map((q, qIndex) => {
            if (q.question_form_type === "states") {
              q.question_form_option = JSON.stringify(states);
            }
            if (q.question_form_type === "cities") {
              q.question_form_option = "";
            }
          })
          TempFormQuestions[Key] = newArray;
          setFormQuestions(TempFormQuestions);
        }
      } else if (newArray[SubIndex][QIndex].question_form_type === "countries" && newArray[SubIndex][QIndex].ActualValue === "" && newArray[SubIndex][QIndex].is_manadatory === 1) {
        newArray[SubIndex][QIndex].Error = 'Please select ' + newArray[SubIndex][QIndex].question_label;
      }
      // end get states of selected country
     
      // console.log(newArray[SubIndex][QIndex].ActualValue);
      // get states of selected country
      if (newArray[SubIndex][QIndex].question_form_type === "states" && newArray[SubIndex][QIndex].ActualValue !== "") {
        let stateId = parseInt(newArray[SubIndex][QIndex].ActualValue);
        let cities = await getCities(stateId);
        // console.log(cities)
        if (cities.length > 0) {
          // eslint-disable-next-line array-callback-return
          newArray[SubIndex].map((q, qIndex) => {
            if (q.question_form_type === "cities") {
              q.question_form_option = JSON.stringify(cities);
            }
          })
          TempFormQuestions[Key] = newArray;
          setFormQuestions(TempFormQuestions);
        }else{
          newArray[SubIndex].map((q, qIndex) => {
            if (q.question_form_type === "cities") {
              q.question_form_option = "";
            }
          })
          TempFormQuestions[Key] = newArray;
          setFormQuestions(TempFormQuestions);
        }
      }
      // end get states of selected country

      //----------------------

      //------------- added new in unique email address
      if(newArray[SubIndex][QIndex].question_form_type === "email" && newArray[SubIndex][QIndex].ActualValue !== "" && AllowUniqueRegistration === 1){
        // console.log(question);
          // flattenedData = newArray.flat();
          flattenedData = Object.values(TempFormQuestions).flat().flat();
          emailEntries = flattenedData.filter(entry => entry.question_form_type === 'email');
          emailValues = emailEntries.map(entry => entry.ActualValue.toLowerCase());
          // console.log(emailValues);
          const removeBlankValues = (array) => {
            return array.filter(email => email.trim() !== '');
          };

          const findDuplicates = (emails) => {
          const cleanedArray = removeBlankValues(emails);

          const emailCount = cleanedArray.reduce((countMap, email) => {
            countMap[email] = (countMap[email] || 0) + 1;
            return countMap;
          }, {});
          
          return Object.entries(emailCount)
            .filter(([email, count]) => count > 1)
            .map(([email]) => email);
          };
      
        const duplicateEmails = findDuplicates(emailValues);
        // console.log(newArray[SubIndex][QIndex].ActualValue.toLowerCase());

        //--------------- check on db email exsist or not
        // let isDuplicate = false;
        // if(DuplicateEmailIds !== ''){ // .toLowerCase()
        //   isDuplicate = DuplicateEmailIds.includes(newArray[SubIndex][QIndex].ActualValue.toLowerCase());
        // }

        if(duplicateEmails && duplicateEmails.length > 0){
          newArray[SubIndex][QIndex].Error = 'Email address already used. please enter another email';
        }
        // else if(DuplicateEmailIds !== '' && isDuplicate === true){
        //   newArray[SubIndex][QIndex].Error = 'Email address already used. please enter another email';
        // }
        else{
          newArray[SubIndex][QIndex].Error = '';
        }
      }

      //------------- added new in unique mobile no
      if(newArray[SubIndex][QIndex].question_form_type === "mobile" && newArray[SubIndex][QIndex].ActualValue !== "" && AllowUniqueRegistration === 1){
        // console.log(question);  newArray[SubIndex][QIndex].ActualValue === "mobile"
          // mobflattenedData = newArray.flat();
          mobflattenedData = Object.values(TempFormQuestions).flat().flat();
          mobileEntries = mobflattenedData.filter(entry => entry.question_form_type === 'mobile');
          mobileValues = mobileEntries.map(entry => entry.ActualValue);
         
          const removeBlankValues = (array) => {
            return array.filter(mobile => mobile.trim() !== '');
          };

          const findDuplicates = (mobs) => {
          const cleanedArray = removeBlankValues(mobs);

          const mobCount = cleanedArray.reduce((countMap, mobile) => {
            countMap[mobile] = (countMap[mobile] || 0) + 1;
            return countMap;
          }, {});
          
          return Object.entries(mobCount)
            .filter(([mobile, count]) => count > 1)
            .map(([mobile]) => mobile);
          };
      
          const duplicateMobile = findDuplicates(mobileValues);
          
          //--------------- check on db mobile exsist or not
          // let isDuplicate1 = false;
          // if(DuplicateMobileNo !== ''){
          //   isDuplicate1 = DuplicateMobileNo.includes(newArray[SubIndex][QIndex].ActualValue);
          // }
          
          //console.log(duplicateEmails);
          if(duplicateMobile && duplicateMobile.length > 0){
             newArray[SubIndex][QIndex].Error = 'Mobile number already used. please enter another';
          }
          // else if(DuplicateMobileNo !== '' && isDuplicate1 === true){
          //   newArray[SubIndex][QIndex].Error = 'Mobile number already used. please enter another';
          // }
          else{
              newArray[SubIndex][QIndex].Error = '';
              // setError('');
          }
      }
      
    }
    newArray[SubIndex][QIndex].TicketId = Key;

    // ---------------------------------------AGE CATEGORY - 24 MAY 2024
    // let AgeCriteriaData = [];
    let gender = "";
    let dob = "";

    // eslint-disable-next-line array-callback-return
    newArray[SubIndex].map((question, questionI) => {
      if (question.user_field_mapping === "gender" && question.ActualValue !== "") {
        gender = question.ActualValue;
      }
      if (question.user_field_mapping === "dob" && question.ActualValue !== "") {
        dob = question.ActualValue;
      }
    });
    // console.log(gender, dob);
    if (gender !== "" && dob !== "") {
      const age = differenceInYears(new Date(), parseISO(dob));
      // console.log(age)
      if (age !== "") {
        setKey(Key);
        setIndex(SubIndex);
        setAgeCatFlag(true);
      }
    } else {
      // console.log("here to remove")
      newArray[SubIndex] = newArray[SubIndex].filter(question => question.question_form_name !== 'AgeCriteriaData');
      TempFormQuestions[Key] = newArray;
      setFormQuestions(TempFormQuestions);
      setAgeCatFlag(false); // Ensure flag is reset if conditions are not met
    }
    // END AGE CATEGORY - 24 MAY 2024
    // console.log(newArray)
    TempFormQuestions[Key] = newArray;
    // console.log((TempFormQuestions));
    setFormQuestions(TempFormQuestions);

  }

  // console.log(ParitcipantFiles)

  const getStates = async (countryId) => {
    setLoaderFlag(true);
    let AllStates = [];
    if (countryId !== "" && countryId !== 0) {
      try {
        const response = await axios.post('state', { country_id: countryId });
        if (response.data.status === 200) {
          if(response.data.data.AllState.length > 0){
            AllStates = response.data.data.AllState;
            setLoaderFlag(false);
          }else{
            AllStates = [];
            setLoaderFlag(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoaderFlag(false);
      }
    }
    return AllStates;
  }

  const getCities = async (stateId) => {
    // console.log(stateId);
    // console.log("Fetching cities for stateId:", stateId);
    setLoaderFlag(true);
    let AllCity = [];
    if (stateId !== "") {
      try {
        
        const response = await axios.post('city', { state_id: stateId});
        if (response.data.status === 200) {
          if(response.data.data.AllCities.length > 0){
            AllCity = response.data.data.AllCities;
            setLoaderFlag(false);
          }else{
            AllCity = [];
            setLoaderFlag(false);
          }
        }else{
          AllCity = [];
          setLoaderFlag(false);
        }
       
      } catch (error) {
        console.log(error);
        setLoaderFlag(false);
      }
    }
    return AllCity;
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    if (AgeCatFlag && Key !== "" && Index !== "") {
      const fetchAgeCriteria = async () => {
        let TempFormQuestions = { ...FormQuestions };
        const arrayToModify = TempFormQuestions[Key];
        const newArray = [...arrayToModify];
        const ageCriteriaExists = newArray[Index].some(q => q.question_form_name === 'AgeCriteriaData');

        let gender = "";
        let dob = "";

        newArray[Index].forEach(question => {
          if (question.user_field_mapping === "gender" && question.ActualValue) {
            gender = question.ActualValue;
          }
          if (question.user_field_mapping === "dob" && question.ActualValue) {
            dob = question.ActualValue;
          }
        });
        // console.log(gender , dob)
        if (gender && dob) {

          const newDate = new Date(EventStartTime * 1000);
          // console.log(newDate)
          const age = differenceInYears(newDate, parseISO(dob));
          // console.log(age)
          if (age) {
            const payload = {
              "event_id": parseInt(EventId),
              "ticket_id": parseInt(Key),
              "gender": gender,
              "age": age
            };

            try {
              const response = await axios.post("get_age_criteria", payload);
              if (response.status === 200) {
                const AgeCriteriaData = response.data.data.AgeCriteriaData;

                if (AgeCriteriaData.length > 0) {
                  if (!ageCriteriaExists) {
                    newArray[Index].push({
                      question_form_name: 'AgeCriteriaData',
                      data: AgeCriteriaData,
                      question_form_type: "age_category"
                    });
                  }
                }

                else {  // remove age category
                  newArray[Index] = newArray[Index].filter(q => q.question_form_name !== 'AgeCriteriaData');
                }
                TempFormQuestions[Key] = newArray;
                setFormQuestions(TempFormQuestions);
              }
            } catch (error) {
              console.error(error);
            }
          }
          // }
        } else {  // remove age category
          newArray[Index] = newArray[Index].filter(q => q.question_form_name !== 'AgeCriteriaData');
          TempFormQuestions[Key] = newArray;
          setFormQuestions(TempFormQuestions);
        }
        setAgeCatFlag(false);
        setKey("");
        setIndex("");
        // }
      };
      // }
      fetchAgeCriteria();

    }
  }, [AgeCatFlag, EventId, EventStartTime, FormQuestions, Index, Key]);


  const getMatchingFile = (question) => {
    return ParitcipantFiles.filter(file => file.name === question.ActualValue);
  };

  const [FileValidationFlag,setFileValidationFlag] = useState(true);
  // console.log(FormQuestions)
  const [ErrorRemoveFlag,setErrorRemoveFlag] = useState(0);
  
  //----------------- hendle next button to validation
  const handleNext = async (e, IndexValue, SubIndexValue, attendeeNumber) => {
    // console.log(IndexValue,SubIndexValue);
    let ErrFlag = true;
    let ChildErrFlag = true;
    let updatedFormQuestions = { ...FormQuestions };
    let mainArrayIndex = null;
    let subArrayIndex = null;

    let flattenedData = [];
    let emailEntries = [];
    let emailValues = [];

    let mobflattenedData = [];
    let mobileEntries = [];
    let mobileValues = [];
    let dup = 0;

    for (const [mainIndex, ticket] of Object.entries(updatedFormQuestions)) {
      for (const [subIndex, form] of ticket.entries()) {
        // console.log(mainIndex);
        if (IndexValue === mainIndex && SubIndexValue === subIndex) {
          for (const question of form) {
            // console.log(question.ActualValue)
            if (parseInt(question.is_manadatory) === 1) {
              if ( (question.ActualValue === "" || question.ActualValue === 0 || question.ActualValue === "0") && question.question_form_name !== "sub_question") {
                if (question.question_form_type === "select" || question.question_form_type === "radio" || question.question_form_type === "file") {
                  question.Error = 'Please select ' + question.question_label;
                  setError('Please select ' + question.question_label);
                  // setFileValidationFlag(false);
                } else if(question.question_form_type === "checkbox") {
                
                  var CheckBoxFormOption = JSON.parse(question.question_form_option);
                  // console.log(CheckBoxFormOption);
                  let Validationflag = 0;
                  for (var i = 0; i < CheckBoxFormOption.length; i++) {
                    if (CheckBoxFormOption[i].checked === true) {
                      Validationflag = 1;
                      break;
                    }
                  }
                 //console.log(Validationflag);
                  if(Validationflag === 0){
                    question.Error = 'Please select at least one option';
                  }

                } else{
                  // new added
                  if(question.question_form_type === 'country' || question.question_form_type === 'countries' || question.question_form_type === 'states' || question.question_form_type === 'cities'){
                  
                    question.Error = 'Please select ' + question.question_label;
                    setError('Please select ' + question.question_label);
                  }else{
                    question.Error = 'Please Enter ' + question.question_label;
                    setError('Please Enter ' + question.question_label);
                  }
                  
                 // question.Error = 'Please enter ' + question.question_label;
                }
                ErrFlag = false;
                break;
              }

              //------------ new check t-shirt limit validation
              if (question.is_manadatory === 1 && question.question_form_type === "select" && question.question_option_limit_flag === 1){
                // console.log(question.ActualValue);
                const dropdownArray = JSON.parse(question.question_form_option);
                let Validationflag1 = 0;
                let Displabel = '';
                for (var i = 0; i < dropdownArray.length; i++) {
                  //console.log(dropdownArray[i].id,parseInt(question.ActualValue));
                  if (dropdownArray[i].id === parseInt(question.ActualValue)) {
                    if (dropdownArray[i].count <= (parseFloat(dropdownArray[i].current_count))) {
                    // console.log('test');
                      Displabel = dropdownArray[i].label;
                      Validationflag1 = 1;
                      break;
                    }
                    
                  }
                }

                if(Validationflag1 === 1){
                  question.Error = 'The '+question.question_label+' you want to add is out of stock.';
                  setError('The '+question.question_label+' you want to add is out of stock.');
                  ErrFlag = false;
                  break;
                }
              }

              if (question.ActualValue !== "" && question.question_form_option !== "") {
                const options = JSON.parse(question.question_form_option);
                const optionWithValue = options.find(option => option.id === parseInt(question.ActualValue));
                if (optionWithValue && optionWithValue.child_question_id) {
                  // Child question ID is present
                  mainArrayIndex = mainIndex;
                  subArrayIndex = subIndex;
                  ChildErrFlag = getValidationOfChildQuestions(optionWithValue.child_question_id, mainArrayIndex, subArrayIndex);
                  // console.log(ChildErrFlag);
                  if (!ChildErrFlag) {
                    ErrFlag = false;
                    break;
                  }
                }
              }
              if (question.ActualValue === "" && question.question_form_name !== "sub_question") {
                // question.Error = 'Please enter ' + question.question_label;
                if (question.question_form_type === "select" || question.question_form_type === "radio") {
                  question.Error = 'Please select ' + question.question_label;
                  setError('Please select ' + question.question_label);
                } else {
                  question.Error = 'Please enter ' + question.question_label;
                  setError('Please select ' + question.question_label);
                }
                ErrFlag = false;
                break;
              }

              // if (question.question_form_type === "email" && question.ActualValue !== "") {
              //   if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(question.ActualValue)) {
              //     question.Error = 'Please enter valid email address';
              //     setError('Please enter valid email address');
              //     ErrFlag = false;
              //     break;
              //   }
              // }
             
              if (question.question_form_type === "email" && question.ActualValue !== "") {
                //-------------- new added email validation on 05-11-24
                if(question.event_id === 74){
                  // const regex = /^.*@larsentoubro\.com$/;
                  const regex = /^[a-zA-Z0-9._%+-]+@larsentoubro\.com$/;
                  if (!regex.test(question.ActualValue)) {
                    question.Error = 'The form only accepts L&T specific domain email ids';
                    setError('The form only accepts L&T specific domain email ids');
                    ErrFlag = false;
                    break;
                  }
                }else{
  
                  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(question.ActualValue)) {
                    question.Error = 'Please enter valid email address';
                    setError('Please enter valid email address');
                    ErrFlag = false;
                    break;
                  }
                }
              }
             

              if (question.question_form_type === "mobile" && question.ActualValue !== "") {
                if (!/^[0-9]{10}$/i.test(question.ActualValue)) {
                  question.Error = "Please enter valid 10-digit " + question.question_label;
                  setError("Please enter valid 10-digit " + question.question_label);
                  ErrFlag = false;
                  break;
                }
              }
              if (question.question_form_type === "amount" && question.ActualValue !== "") {
                if (!/^\d+(?:\.\d{1,2})?$/i.test(question.ActualValue)) {
                  question.Error = "Please enter valid " + question.question_label + " amount";
                  setError("Please enter valid " + question.question_label + " amount");
                  ErrFlag = false;
                  break;
                } else {
                  question.Error = '';
                }
              }
              if (question.question_form_name === "pincode" && question.ActualValue !== "") {
                if (!/^\d{6}$/.test(question.ActualValue)) {
                  question.Error = "Please enter a valid 6 digit " + question.question_label;
                  setError("Please enter a valid 6 digit " + question.question_label);
                  ErrFlag = false;
                  break;
                } else {
                  question.Error = '';
                }
              }
              if (question.question_form_name === "emergency_contact_name" && question.ActualValue !== "") {
                if (!/^[a-zA-Z\s]+$/.test(question.ActualValue)) {
                  question.Error = "Please enter a valid " + question.question_label;
                  setError("Please enter a valid " + question.question_label);
                  ErrFlag = false;
                  break;
                } else {
                  question.Error = '';
                }
              }
              if (question.question_form_name === "emergency_contact_number" && question.ActualValue !== "") {
                if (!/^[0-9]{10}$/i.test(question.ActualValue)) {
                  question.Error = "Please enter a valid " + question.question_label;
                  setError("Please enter a valid " + question.question_label);
                  ErrFlag = false;
                  break;
                } else {
                  question.Error = '';
                }
              }
              //LIMIT LENGTH VALIDATION
              if (question.ActualValue !== "" && question.limit_check === 1) {
                if (question.question_form_type === "text") {
                  if (question.limit_length !== "") {
                    const arrayConfig = JSON.parse(question.limit_length);
                    const maxLength = arrayConfig.max_length;
                    const minLength = arrayConfig.min_length;
                    // console.log(arrayConfig, maxLength, minLength);
                    if (question.ActualValue.length > maxLength || question.ActualValue.length < minLength) {
                      if (maxLength === minLength) {
                        question.Error = question.question_label + '  should be exactly ' + minLength + ' digits ';
                        setError(question.question_label + '  should be exactly ' + minLength + ' digits');
                      } else {
                        question.Error = question.question_label + ' character length should be between ' + minLength + ' - ' + maxLength + ' characters';
                        setError(question.question_label + ' character length should be between ' + minLength + ' - ' + maxLength + ' characters');
                      }
                      ErrFlag = false;
                      break;
                    }
                  }
                }
              }
              // END 
             // console.log(question.question_form_type);
              if (question.question_form_type === "countries" && question.ActualValue === "" && parseInt(question.is_manadatory) === 1) {
                // console.log("first")
                question.Error = 'Please select ' + question.question_label;
                setError('Please select ' + question.question_label);
                ErrFlag = false;
                break;
              }
              
              //----------------- Time picker validation on 08-08-24
              if (question.question_form_type === "time") {

                if(question.ActualValue === ""){
                  question.Error = 'Please Enter ' + question.question_label;
                  setError('Please Enter ' + question.question_label);
                  ErrFlag = false;
                  break;
                }else if(question.ActualValue !== ""){
                
                  const ValidTime = question.ActualValue.split(':');
                  const ValidateTime = ValidTime.join('');
                  // console.log(ValidateTime);
                  if (ValidateTime.length !== 6 || ValidTime.includes('')) {
                    question.Error = 'Invalid '+question.question_label+' time. Please Enter time in HH:MM:SS format';
                    setError('Invalid '+question.question_label+' time. Please Enter time in HH:MM:SS format');
                    ErrFlag = false;
                    break;
                  }
                }
              }

            }
            // else if (question.question_form_type === "file" && question.ActualValue !== "" && question.Error !== '') {
            //   // question.Error = 'Please select ' + question.question_label;
            //   ErrFlag = false;
            //   setFileValidationFlag(false);
            //   // console.log(question)
            //   break;
            // }
     
            // console.log(ParitcipantFiles);
           //---------- file upload validation
            if(question.question_form_type === "file"){
                for (var i = 0; i < ParitcipantFiles.length; i++) {
                // console.log(ParitcipantFiles[i].name);
                  //console.log(ParitcipantFiles[i].name,'-----'+question.ActualValue.replace("C:\\fakepath\\", '')); 
                  if(ParitcipantFiles[i].name === question.ActualValue.replace("C:\\fakepath\\", '')){ 
      
                      if (ParitcipantFiles) {
                        const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE') ? localStorage.getItem('MAX_UPLOAD_FILE_SIZE') : '2097152'; // 2 MB limit
                        const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
                        // console.log(ParitcipantFiles[i].type); 
                        if (ParitcipantFiles[i].size < imageSizeLimit) {
                          if (allowedTypes.includes(ParitcipantFiles[i].type)) {
                                question.Error = "";
                                setError("");
                               // setFileValidationFlag(true);
                               // console.log('yes11');
                                setErrorRemoveFlag(1);
                          } else {
                           
                                question.Error = "Only jpg, png and pdf allowed.";
                                setError("Only jpg, png and pdf allowed.");
                                ErrFlag = false;
                                setFileValidationFlag(false);
                                //console.log(ParitcipantFiles[i].name+'------Only jpg, png and pdf allowed.');
                                break;
                               
                          }
                        } else {
                         
                                question.Error = 'File size exceeds 2MB limit.';
                                setError("File size exceeds 2MB limit.");
                                ErrFlag = false;
                                setFileValidationFlag(false);
                                //console.log('File size exceeds 2MB limit.');
                                break;
                        }
                      }
                      //  else {
                      //           //newArray[SubIndex][QIndex].Error = "";
                      //           setFileValidationFlag(true);
                      //           console.log('yes');
                      // }
              
                      setFormQuestions(updatedFormQuestions);
                  }
                 
                }
            }

            //------------- added new in unique email address
            if(question.question_form_type === "email" && question.ActualValue !== "" && AllowUniqueRegistration === 1){
             
                // flattenedData = ticket.flat();
                flattenedData = Object.values(updatedFormQuestions).flat().flat();
                emailEntries = flattenedData.filter(entry => entry.question_form_type === 'email');
                emailValues = emailEntries.map(entry => entry.ActualValue.toLowerCase());
                // newItems.forEach(item => updatedItems.push(item));
                // AllEmailsDetails.push(entry.ActualValue);

                //  console.log(emailValues);
        
                const removeBlankValues = (array) => {
                  return array.filter(email => email.trim() !== '');
                };

                const findDuplicates = (emails) => {
                const cleanedArray = removeBlankValues(emails);

                const emailCount = cleanedArray.reduce((countMap, email) => {
                  countMap[email] = (countMap[email] || 0) + 1;
                  return countMap;
                }, {});
                
                return Object.entries(emailCount)
                  .filter(([email, count]) => count > 1)
                  .map(([email]) => email);
                };
            
              const duplicateEmails = findDuplicates(emailValues);
            
              //--------------- check on db email exsist or not
              // let isDuplicate = false;
              // if(DuplicateEmailIds !== ''){
              //   isDuplicate = DuplicateEmailIds.includes(question.ActualValue.toLowerCase());
              // }

              if(duplicateEmails && duplicateEmails.length > 0){
                  question.Error = 'Email address already used. please enter another email';
                  setError('Email address already used. please enter another email');
                  ErrFlag = false;
                  break;
              }
              // else if(DuplicateEmailIds !== '' && isDuplicate === true){
              //     question.Error = 'Email address already used. please enter another email';
              //     setError('Email address already used. please enter another email');
              //     ErrFlag = false;
              //     break;
              // }
              // else{
              //     question.Error = '';
              //     setError('');
              // }

              let EmailAddressDuplicateCheck = await checkParticipantDuplicate(EventId, question.ActualValue, 0);
              if(EmailAddressDuplicateCheck === false){
                  question.Error = 'Email address already used. please enter another email';
                  setError('Email address already used. please enter another email');
                  ErrFlag = false;
                  break;
              }

            }
            
            //------------- added new in unique mobile no
            if(question.question_form_type === "mobile" && question.ActualValue !== "" && AllowUniqueRegistration === 1){
              // console.log(question);
                // mobflattenedData = ticket.flat();
                mobflattenedData = Object.values(updatedFormQuestions).flat().flat();
                mobileEntries = mobflattenedData.filter(entry => entry.question_form_type === 'mobile');
                mobileValues = mobileEntries.map(entry => entry.ActualValue);
               
                const removeBlankValues = (array) => {
                  return array.filter(mobile => mobile.trim() !== '');
                };

                const findDuplicates = (mobs) => {
                const cleanedArray = removeBlankValues(mobs);

                const mobCount = cleanedArray.reduce((countMap, mobile) => {
                  countMap[mobile] = (countMap[mobile] || 0) + 1;
                  return countMap;
                }, {});
                
                return Object.entries(mobCount)
                  .filter(([mobile, count]) => count > 1)
                  .map(([mobile]) => mobile);
                };
            
              const duplicateMobile = findDuplicates(mobileValues);

              //--------------- check on db mobile exsist or not
              // let isDuplicate1 = false;
              // if(DuplicateMobileNo !== ''){
              //   isDuplicate1 = DuplicateMobileNo.includes(question.ActualValue);
              // }
              
              //console.log(duplicateEmails);
              if(duplicateMobile && duplicateMobile.length > 0){
                  question.Error = 'Mobile number already used. please enter another';
                  setError('Mobile number already used. please enter another');
                  ErrFlag = false;
                  break;
              }
              // else if(DuplicateMobileNo !== '' && isDuplicate1 === true){
              //   question.Error = 'Mobile number already used. please enter another';
              //   setError('Mobile number already used. please enter another');
              //   ErrFlag = false;
              //   break;
              // }
              // else{
              //     question.Error = '';
              //     setError('');
              // }
              
              let MobileNumberDuplicateCheck = await checkParticipantDuplicate(EventId, 0, question.ActualValue);
              if(MobileNumberDuplicateCheck === false){
                  question.Error = 'Mobile number already used. please enter another';
                  setError('Mobile number already used. please enter another');
                  ErrFlag = false;
                  break;
              }
             
            }

            //----------------- end -----------------------------

            // ---------------------- 12-08-24 - birthday condition to check age limit
            if (question.question_form_name === "date_of_birth") {

              let AgeLimit = AllTickets.find(ticket => ticket.id === parseInt(mainIndex));

              if (AgeLimit) {
                const apply_age_limit = AgeLimit.apply_age_limit;
                // console.log(apply_age_limit);

                if (parseInt(apply_age_limit) === 1) {
                  const age_start = AgeLimit.age_start;
                  const age_end = AgeLimit.age_end;
                  let dateOfBirth = question.ActualValue;
                  let dobDate = parseISO(dateOfBirth);
                  const newDate = new Date(EventStartTime * 1000);

                  let age = differenceInYears(newDate, dobDate);
                  if (age < age_start || age > age_end) {
                    question.Error = 'Age should be between ' + age_start + ' and ' + age_end + ' years';
                    setError('Age should be between ' + age_start + ' and ' + age_end + ' years');
                    ErrFlag = false;
                    break;
                  } else {
                    question.ActualValue = question.ActualValue;
                    question.Error = '';
                    setError('');
                  }
                }
              }

            }
          // ------------------ End --------------------
   
          }
        }
        if (!ErrFlag) {
          break;
        }
      }
      if (!ErrFlag) {
        break;
      }
    }
    // console.log('first',FileValidationFlag);
    if (!ErrFlag) {
      // setError("Please fill all attendee details");
      setFormQuestions(updatedFormQuestions);
    }
    // else if (FileValidationFlag === false) {
    //   setFormQuestions(updatedFormQuestions);
    // }
    else {
      setShowIndex(attendeeNumber + 1);
    }
  }
  // console.log('first',FileValidationFlag);
  const ActiveTicket = (e, attendeeNumber) => {
    e.preventDefault();
    setShowIndex(attendeeNumber);
  }
  
  //----------- sub / child question validation
  const getValidationOfChildQuestions = (child_question_id, ticket_id, sub_index) => {
    // console.log(child_question_id);
    let ErrFlag = true;
    let updatedFormQuestions = { ...FormQuestions };
    for (const [mainIndex, ticket] of Object.entries(updatedFormQuestions)) {
      for (const [subIndex, form] of ticket.entries()) {
        for (const [questionIndex, question] of form.entries()) {
          if (question.ActualValue === "" && question.is_manadatory === 1 && question.general_form_id === parseInt(child_question_id) && parseInt(mainIndex) === parseInt(ticket_id) && parseInt(subIndex) === parseInt(sub_index)) {
            // console.log(question.question_label, question.general_form_id);
            // question.Error = 'Please enter ' + question.question_label;
            if (question.question_form_type === "select" || question.question_form_type === "radio") {
              question.Error = 'Please select ' + question.question_label;
              setError('Please select ' + question.question_label);
            } else {
              question.Error = 'Please enter ' + question.question_label;
              setError('Please enter ' + question.question_label);
            }
            ErrFlag = false;
          }
        }
      }
    }
    if (!ErrFlag) {
      setFormQuestions(updatedFormQuestions);
    }
    return ErrFlag;
  }

  // const [FileSubmitValidationFlag,setFileSubmitValidationFlag] = useState(true);

  // console.log(FinalAmount)
  // const getUniqueEmails = (array) => {
  //   return [...new Set(array)];
  // };
   
  const checkParticipantDuplicate = async (EventId, email, mobno) => {
    const payload = {
      "event_id": EventId,
      "email_id": email,
      "mobile_no": mobno
    };
    //console.log(payload);
    try {
      const response = await axios.post("/checkParticipantDuplicateEmailMobile", payload);
      
      if (response.status === 200) {
        if (response.data.data.email_validate_flag === 1) {
          return false; 
        }if (response.data.data.mobile_validate_flag === 1) {
          return false; 
        }else if (response.data.data.mobile_validate_flag === 0 || response.data.data.email_validate_flag === 0) {
          return true; 
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
    return true; 
  };
 
  //----------- handle final submit to check validation
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoaderFlag(true);
    let ErrFlag = true;

    setTermsAndConditionError("");
    if (!TermsAndCondition && TermsConditionsFlag) {
      setTermsAndConditionError("Accept Terms and condition");
      ErrFlag = false;
      setLoaderFlag(false);
      setError("")
    }

    let ChildErrFlag = true;
    let updatedFormQuestions = { ...FormQuestions };
    let mainArrayIndex = null;
    let subArrayIndex = null;

    let flattenedData = [];
    let emailEntries = [];
    let emailValues = [];

    let mobflattenedData = [];
    let mobileEntries = [];
    let mobileValues = [];

    // console.log(JSON.stringify(updatedFormQuestions));

    // const allQuestions = Object.values(updatedFormQuestions).flat().flat();
    // const mainArray =  allQuestions.find(item => item.question_form_type === 'email');
   
    // flattenedData = updatedFormQuestions.flat();
    // emailEntries = flattenedData.filter(entry => entry.question_form_type === 'email');
    // emailValues = emailEntries.map(entry => entry.ActualValue);

    
    for (const [mainIndex, ticket] of Object.entries(updatedFormQuestions)) {
      for (const [subIndex, form] of ticket.entries()) {
        for (const question of form) {
          
          // console.log(question.question_label + " : " + question.ActualValue, question.is_manadatory, question.question_form_name);
          // console.log(question.ActualValue);
          if ((question.ActualValue === "" || question.ActualValue === 0 || question.ActualValue === "0") && question.is_manadatory === 1 && question.question_form_name !== "sub_question") {

            if (question.question_form_type === "select" || question.question_form_type === "radio" || question.question_form_type === "file") {
              question.Error = 'Please select ' + question.question_label;
              // setFileValidationFlag(false);
              setError('Please select ' + question.question_label);
            } else if(question.question_form_type === "checkbox") {
                
              var CheckBoxFormOption = JSON.parse(question.question_form_option);
              // console.log(CheckBoxFormOption);
              let Validationflag = 0;
              for (var i = 0; i < CheckBoxFormOption.length; i++) {
                if (CheckBoxFormOption[i].checked === true) {
                  Validationflag = 1;
                  break;
                }
              }
             //console.log(Validationflag);
              if(Validationflag === 0){
                question.Error = 'Please select at least one option';
                setError('Please select at least one option');
              }

            } else {
              //console.log(question.question_form_type);
                if(question.question_form_type === 'country' || question.question_form_type === 'countries' || question.question_form_type === 'states' || question.question_form_type === 'cities'){
                 
                  question.Error = 'Please select ' + question.question_label;
                  setError('Please select ' + question.question_label);
                }else{
                  question.Error = 'Please Enter ' + question.question_label;
                  setError('Please Enter ' + question.question_label);
                }
            }
            // console.log(question.question_label, ErrFlag);
            ErrFlag = false;
            break;
          }

          //------------ new check t-shirt limit validation
          if (question.is_manadatory === 1 && question.question_form_type === "select" && question.question_option_limit_flag === 1){
            // console.log(question.ActualValue);
            const dropdownArray = JSON.parse(question.question_form_option);
            let Validationflag1 = 0;
            let Displabel = '';
            for (var i = 0; i < dropdownArray.length; i++) {
              //console.log(dropdownArray[i].id,parseInt(question.ActualValue));
              if (dropdownArray[i].id === parseInt(question.ActualValue)) {
                if (dropdownArray[i].count <= (parseFloat(dropdownArray[i].current_count))) {
                 // console.log('test');
                  Displabel = dropdownArray[i].label;
                  Validationflag1 = 1;
                  break;
                }
                
              }
            }

            if(Validationflag1 === 1){
              question.Error = 'The '+question.question_label+' you want to add is out of stock.';
              setError('The '+question.question_label+' you want to add is out of stock.');
              ErrFlag = false;
              break;
            }
          }

          //----------------- Time picker validation on 08-08-24
          if (question.question_form_type === "time" && question.is_manadatory === 1) {

            if(question.ActualValue === ""){
              question.Error = 'Please Enter ' + question.question_label;
              setError('Please Enter ' + question.question_label);
              ErrFlag = false;
              break;
            }else if(question.ActualValue !== ""){
            
              const ValidTime = question.ActualValue.split(':');
              const ValidateTime = ValidTime.join('');
              // console.log(ValidateTime);
              if (ValidateTime.length !== 6 || ValidTime.includes('')) {
                question.Error = 'Invalid '+question.question_label+' time. Please Enter time in HH:MM:SS format';
                setError('Invalid '+question.question_label+' time. Please Enter time in HH:MM:SS format');
                ErrFlag = false;
                break;
              }
            }
          }

          //------------- added new in unique email, mobile no
          if(question.ActualValue !== "" && question.question_form_type === "email" && AllowUniqueRegistration === 1 && AllEmailsDetails !== ""){

            flattenedData = Object.values(updatedFormQuestions).flat().flat();
            emailEntries = flattenedData.filter(entry => entry.question_form_type === 'email');
            emailValues = emailEntries.map(entry => entry.ActualValue.toLowerCase());

           
                // flattenedData = ticket.flat();
                // emailEntries = flattenedData.filter(entry => entry.question_form_type === 'email');
                // emailValues = emailEntries.map(entry => entry.ActualValue);
                // console.log(emailValues);
            // const uniqueEmailArray = getUniqueEmails(emailValues);
            const removeBlankValues = (array) => {
              return array.filter(email => email.trim() !== '');
            };

            const findDuplicates = (emails) => {
              const cleanedArray = removeBlankValues(emails);

              const emailCount = cleanedArray.reduce((countMap, email) => {
                countMap[email] = (countMap[email] || 0) + 1;
                return countMap;
              }, {});
              
              return Object.entries(emailCount)
                .filter(([email, count]) => count > 1)
                .map(([email]) => email);
            };
          
            const duplicateEmails = findDuplicates(emailValues);
            // console.log(duplicateEmails);

            //--------------- check on db email exsist or not
            // let isDuplicate = false;
            // if(DuplicateEmailIds !== ''){
            //   isDuplicate = DuplicateEmailIds.includes(question.ActualValue.toLowerCase());
            // }

            // console.log(isDuplicateEmail);
            if(duplicateEmails && duplicateEmails.length > 0){
                question.Error = 'Email address already used. please enter another email';
                setError('Email address already used. please enter another email');
                ErrFlag = false;
                break;
            }
            // else if(isDuplicateEmail === true){
            //     question.Error = 'Email address already used. please enter another email';
            //     setError('Email address already used. please enter another email');
            //     ErrFlag = false;
            //     break;
            // }
            // else{
            //     question.Error = '';
            //     setError('');
            // }

            let EmailAddressDuplicateCheck = await checkParticipantDuplicate(EventId, question.ActualValue, 0);
            if(EmailAddressDuplicateCheck === false){
                question.Error = 'Email address already used. please enter another email';
                setError('Email address already used. please enter another email');
                ErrFlag = false;
                break;
            }

          }

          //------------- added new in unique mobile no
          if(question.question_form_type === "mobile" && question.ActualValue !== "" && AllowUniqueRegistration === 1){
              // console.log(question);
                // mobflattenedData = ticket.flat();
                mobflattenedData = Object.values(updatedFormQuestions).flat().flat();
                mobileEntries = mobflattenedData.filter(entry => entry.question_form_type === 'mobile');
                mobileValues = mobileEntries.map(entry => entry.ActualValue);
               
                const removeBlankValues = (array) => {
                  return array.filter(mobile => mobile.trim() !== '');
                };

                const findDuplicates = (mobs) => {
                const cleanedArray = removeBlankValues(mobs);

                const mobCount = cleanedArray.reduce((countMap, mobile) => {
                  countMap[mobile] = (countMap[mobile] || 0) + 1;
                  return countMap;
                }, {});
                
                return Object.entries(mobCount)
                  .filter(([mobile, count]) => count > 1)
                  .map(([mobile]) => mobile);
                };
            
              const duplicateMobile = findDuplicates(mobileValues);

              //--------------- check on db mobile exsist or not
              // let isDuplicate1 = false;
              // if(DuplicateMobileNo !== ''){
              //   isDuplicate1 = DuplicateMobileNo.includes(question.ActualValue);
              // }
              //console.log(duplicateEmails);
              if(duplicateMobile && duplicateMobile.length > 0){
                  question.Error = 'Mobile number already used. please enter another';
                  setError('Mobile number already used. please enter another');
                  ErrFlag = false;
                  break;
              }

              let MobileNumberDuplicateCheck = await checkParticipantDuplicate(EventId, 0, question.ActualValue);
              if(MobileNumberDuplicateCheck === false){
                  question.Error = 'Mobile number already used. please enter another';
                  setError('Mobile number already used. please enter another');
                  ErrFlag = false;
                  break;
              }
          
          }
          
          //-------------------------

          if (question.ActualValue !== "" && question.is_manadatory === 1 && question.question_form_option !== "") {
            // console.log(question.question_form_name)
            const options = JSON.parse(question.question_form_option);
            const optionWithValue = options.find(option => option.id === parseInt(question.ActualValue));
            if (optionWithValue && optionWithValue.child_question_id) {
              mainArrayIndex = mainIndex;
              subArrayIndex = subIndex;
              ChildErrFlag = getValidationOfChildQuestions(optionWithValue.child_question_id, mainArrayIndex, subArrayIndex);
              // console.log(ChildErrFlag);
              if (!ChildErrFlag) {
                ErrFlag = false;
                break;
              }
            }
          }

          // if (question.question_form_type === "email" && question.ActualValue !== "") {
          //   if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(question.ActualValue)) {
          //     question.Error = 'Please enter valid email address';
          //     setError('Please enter valid email address');
          //     ErrFlag = false;
          //     break;
          //   }
          // }
          
          if (question.question_form_type === "email" && question.ActualValue !== "") {
              //-------------- new added email validation on 05-11-24
              if(question.event_id === 74){
                const regex = /^[a-zA-Z0-9._%+-]+@larsentoubro\.com$/;
                // const regex = /^@[a-zA-Z0-9._%+-]+\.com$/;  
                if (!regex.test(question.ActualValue)) {
                  question.Error = 'The form only accepts L&T specific domain email ids';
                  setError('The form only accepts L&T specific domain email ids');
                  ErrFlag = false;
                  break;
                }
              }else{

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(question.ActualValue)) {
                  question.Error = 'Please enter valid email address';
                  setError('Please enter valid email address');
                  ErrFlag = false;
                  break;
                }
              }
          }
          
          if (question.question_form_type === "mobile" && question.ActualValue !== "") {
            if (!/^[0-9]{10}$/i.test(question.ActualValue)) {
              question.Error = "Please enter valid 10-digit " + question.question_label;
              setError("Please enter valid 10-digit " + question.question_label);
              ErrFlag = false;
              break;
            }
          }
          if (question.question_form_name === "pincode" && question.ActualValue !== "") {
            if (!/^\d{6}$/.test(question.ActualValue)) {
              question.Error = "Please enter a valid 6 digit " + question.question_label;
              setError("Please enter a valid 6 digit " + question.question_label);
              ErrFlag = false;
              break;
            }
          }
          if (question.question_form_name === "emergency_contact_name" && question.ActualValue !== "") {
            if (!/^[a-zA-Z\s]+$/.test(question.ActualValue)) {
              question.Error = "Please enter a valid " + question.question_label;
              setError("Please enter a valid " + question.question_label);
              ErrFlag = false;
              break;
            } else {
              question.Error = '';
              setError('');
            }
          }
          if (question.question_form_name === "emergency_contact_number" && question.ActualValue !== "") {
            if (!/^[0-9]{10}$/i.test(question.ActualValue)) {
              question.Error = "Please enter a valid " + question.question_label;
              setError("Please enter a valid " + question.question_label);
              ErrFlag = false;
              break;
            } else {
              question.Error = '';
              setError('');
            }
          }
          //LIMIT LENGTH VALIDATION
          if (question.ActualValue !== "" && question.ActualValue !== null && question.limit_check === 1) {
            if (question.question_form_type === "text") {
              if (question.limit_length !== "") {
                const arrayConfig = JSON.parse(question.limit_length);
                const maxLength = arrayConfig.max_length;
                const minLength = arrayConfig.min_length;
                // console.log(question.ActualValue, question.question_form_name, arrayConfig, maxLength, minLength);
                if (question.ActualValue.length > maxLength || question.ActualValue.length < minLength) {
                  if (maxLength === minLength) {
                    question.Error = question.question_label + '  should be exactly ' + minLength + ' digits ';
                    setError(question.question_label + '  should be exactly ' + minLength + ' digits ');
                  } else {
                    question.Error = question.question_label + ' character length should be between ' + minLength + ' - ' + maxLength + ' characters';
                    setError(question.question_label + ' character length should be between ' + minLength + ' - ' + maxLength + ' characters');
                  }
                  ErrFlag = false;
                  break;
                }
              }
            }
          }
          // END 
          if (question.question_form_type === "amount" && question.ActualValue !== "") {
            // if (!/^\d+(?:\.\d{1,2})?$/i.test(e.target.value) && !/^\d{0,10}$/i.test(e.target.value)) {
            if (!/^\d+(?:\.\d{1,2})?$/i.test(question.ActualValue)) {
              question.Error = "Please enter valid " + question.question_label;
              setError("Please enter valid " + question.question_label);
              ErrFlag = false;
              break;
            } else {
              question.Error = '';
              setError('');
            }
          }

          if(question.question_form_type === "file"){
            for (var i = 0; i < ParitcipantFiles.length; i++) {
            // console.log(ParitcipantFiles[i].name);
              //console.log(ParitcipantFiles[i].name,'-----'+question.ActualValue.replace("C:\\fakepath\\", '')); 
              if(ParitcipantFiles[i].name === question.ActualValue.replace("C:\\fakepath\\", '')){ 
  
                  if (ParitcipantFiles) {
                    const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE') ? localStorage.getItem('MAX_UPLOAD_FILE_SIZE') : '2097152'; // 2 MB limit
                    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
                    // console.log(ParitcipantFiles[i].type); 
                    if (ParitcipantFiles[i].size < imageSizeLimit) {
                      if (allowedTypes.includes(ParitcipantFiles[i].type)) {
                            question.Error = "";
                            setError('');
                           // setFileValidationFlag(true);
                           // console.log('yes11');
                           setErrorRemoveFlag(1);
                      } else {
                            question.Error = "Only jpg, png and pdf allowed.";
                            setError('Only jpg, png and pdf allowed.');
                            ErrFlag = false;
                            setFileValidationFlag(false);
                            //console.log(ParitcipantFiles[i].name+'------Only jpg, png and pdf allowed.');
                            break;
                           
                      }
                    } else {
                    
                            question.Error = 'File size exceeds 2MB limit.';
                            setError('File size exceeds 2MB limit.');
                            ErrFlag = false;
                            setFileValidationFlag(false);
                            //console.log('File size exceeds 2MB limit.');
                            break;
                    }
                  }
                
                  setFormQuestions(updatedFormQuestions);
              }
             
            }
          }

        //----------------------------- End -------------------------

        // ---------------------- 12-08-24 - birthday condition to check age limit
        if (question.question_form_name === "date_of_birth") {

          let AgeLimit = AllTickets.find(ticket => ticket.id === parseInt(mainIndex));

          if (AgeLimit) {
            const apply_age_limit = AgeLimit.apply_age_limit;
            // console.log(apply_age_limit);

            if (parseInt(apply_age_limit) === 1) {
              const age_start = AgeLimit.age_start;
              const age_end = AgeLimit.age_end;
              let dateOfBirth = question.ActualValue;
              let dobDate = parseISO(dateOfBirth);
              const newDate = new Date(EventStartTime * 1000);

              let age = differenceInYears(newDate, dobDate);
              if (age < age_start || age > age_end) {
                question.Error = 'Age should be between ' + age_start + ' and ' + age_end + ' years';
                setError('Age should be between ' + age_start + ' and ' + age_end + ' years');
                ErrFlag = false;
                break;
              } else {
                question.ActualValue = question.ActualValue;
                question.Error = '';
                setError('');
              }
            }
          }

        }
      // ------------------ End --------------------


        }
        if (!ErrFlag) {
          break;
        }
      }
      if (!ErrFlag) {
        break;
      }
    }

    if (!ErrFlag) {
      setFormQuestions(updatedFormQuestions);
    }
   
    // console.log(FormQuestions) //FinalParitcipantFiles
    
    //------------------------------------ payment gateway calling
    //if (PaymentDetailsFlag === false) {
      // ErrFlag = false;

      if (!ErrFlag) {
        // console.log(1);
        if (TermsAndCondition && TermsConditionsFlag) 
          // setError("Please fill all participant details");
        setFormQuestions(updatedFormQuestions);
        setLoaderFlag(false);
        setPaymentConfirmPopupflag(false);
      } 
      // else if (FileValidationFlag === false) {
      //   // console.log(2);
      //   setFormQuestions(updatedFormQuestions);
      //   setLoaderFlag(false);
      //   setPaymentConfirmPopupflag(false);
      // }
      
      else {
        // console.log(3);
        setError("");
        // setPaymentConfirmPopupflag(false);
        setLoaderFlag(true);
        const payload = {
          "event_id": EventId,
          "total_attendees": TotalAttendee,
          "FormQuestions": FormQuestions,
          "TotalPrice": FinalAmount,
          "TotalDiscount": TotalDiscount,
          "AllTickets": AllTickets,
          "ExtraPricing": Amount,
          "EventUrl": EventUrl,
          "UtmCampaign": UtmCampaign,
          "GstArray": GstArray
        }
      //console.log(payload,JSON.stringify(FormQuestions),JSON.stringify(GstArray));
        setBookTicktesArray(payload);

        //-------------------
        //------------------------------ Free Booking -----------------------------------
        if ((FinalAmount === '0.00' || FinalAmount === 0 || FinalAmount === '0' || FinalAmount <= 0) && (FinalAmount !== '' && EventId !== undefined)) {
          setLoaderFlag(true);
          setBookingPayId('');
          setPaymentConfirmPopupflag(false);
        
          const formData = new FormData();

          for (let i = 0; i < FinalParitcipantFiles.length; i++) {
            formData.append("fils_array[]", FinalParitcipantFiles[i]);
          }

          formData.append('event_id', EventId);
          formData.append('amount', FinalAmount);
          formData.append('ticket_type', 'free');
          formData.append('booking_tickets_array', JSON.stringify(payload));
          //console.log(payload1);
          axios.post("bookingPaymentProcess", formData)
            .then((response) => {
              // console.log(response);
              if (response.status === 200) {
                localStorage.setItem("booking_pay_id", response.data.data.booking_pay_id);
                setBookingPayId(response.data.data.booking_pay_id);
                setLoaderFlag(false);
                setBookProcessFlag(true);
                setSendEmailFlag(true);
                setSuccess(true);
              }else{
                toast.error("Error while booking.");
              }
            })
            .catch(() => {
              console.log('error');
              setLoaderFlag(false);
              setBookProcessFlag(false);
              setSendEmailFlag(false);
              setSuccess(false);
            });

        } else {
            if(EventId === undefined || EventId <= 0 || FinalAmount === '' || EventId === ''){
              //------------ log entry
              const formData = new FormData();
              formData.append('event_id', EventId);
              formData.append('amount', FinalAmount);
              formData.append('booking_tickets_array', JSON.stringify(payload));
              //console.log(payload1);
              axios.post("ticketBookingLog", formData)
                .then((response) => {
                  if (response.status === 200) {
                   
                  }
                })
                .catch(() => {
                  console.log('error');
                });

              toast.error("Something went wrong");
              const timer = setTimeout(() => {
                navigate('/');
                }, 4000); // Adjust the delay as needed (in milliseconds)
              return () => clearTimeout(timer);
            }else{
              setPaymentConfirmPopupflag(true); //---------- Paid Booking
            }
        }

        setLoaderFlag(false);

      }
   // }

  };

  // console.log(ErrorRemoveFlag);
  useEffect(() => {
    if(ErrorRemoveFlag === 1){
      setFileValidationFlag(true);
      setErrorRemoveFlag(0);
    }
  }, [ErrorRemoveFlag]);
  
  //-------------- temp hide
  // useEffect(() => {
  //   if (BookProcessFlag) {
  //     setLoaderFlag(true);
  //     const payload = {
  //       booking_pay_id: BookingPayId,
  //     };
  //     //console.log(payload);
  //     axios.post("book_tickets", payload)
  //       .then((response) => {
  //         //console.log(response);
  //         if (response.status === 200) {
  //           setLoaderFlag(false);
  //           setSuccess(true);
  //           setSendEmailFlag(true);
  //         } else {
  //           toast.error("Error while booking.")
  //         }
  //         setBookProcessFlag(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoaderFlag(false);
  //         // setBookingPayId('');
  //         setBookProcessFlag(false);
  //         setSendEmailFlag(true);
  //       });
  //   }

  // }, [BookProcessFlag]);

  const url = window.location.origin;
  const local_booking_pay_id = localStorage.getItem("booking_pay_id");
  //---------- free tickets send email
  useEffect(() => {
    if (BookingPayId !== '' && SendEmailFlag === true) {
      //console.log(BookingPayId); BookingPayId,
      const payload = {
        booking_pay_id: BookingPayId !== '' ? BookingPayId : local_booking_pay_id,
        event_id: EventId,
        event_url: url
      };
      // console.log(payload);
      axios.post("send_email_payment_success", payload)
        .then((response) => {
          //console.log(response);
          if (response.data.success === 200) {
            toast.success(response.data.message);
            localStorage.removeItem('booking_pay_id');
          } else {
            toast.error(response.data.message);
          }
          setBookingPayId('');
          setSendEmailFlag(false);
        })
        .catch((error) => {
          console.log(error);
          setBookingPayId('');
          //setLoader(false);
        });
    } else {
      // navigate('/events')
    }

  }, [SendEmailFlag]);

  //----------------------------------------------------------------------------------------------------------------

  let attendeeNumber = 0;
  const BackToHome = () => {
    localStorage.removeItem('EventId');
    localStorage.removeItem('EventType');
    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    // console.log(CountryCode,CountryName);
    navigate('/' + CountryCode + '/' + CountryName);
  }

  const HandleClose = useCallback((e) => {
    setSuccess(false);
    localStorage.removeItem('EventId');
    localStorage.removeItem('EventType');
    navigate('/my_bookings')
  }, [navigate])

  useEffect(() => {
    if (Success) {
      const handleEscKeyPress = (e) => {
        if (e.key === 'Escape') {
          HandleClose(e);
        }
      };
      document.addEventListener('keydown', handleEscKeyPress);
      return () => {
        document.removeEventListener('keydown', handleEscKeyPress);
      };
    }
  }, [HandleClose, Success]);

  const ShowChildQuestions = (parentIndex, parentFormIndex, qIndex, general_form_id = null, child_question_id = null, child_question_ids = null, attendeeNumber, Key) => {
    // console.log(child_question_ids);
    var myDiv = document.getElementById('questions_' + attendeeNumber + child_question_id);
    // console.log(myDiv);
    if (myDiv !== null) {
      if (myDiv.classList.contains('d-none')) {
        myDiv.classList.remove(`d-none`);
      }
    }

    // if(child_question_id == null){
    //   myDiv.classList.add('d-none');
    // }
    // console.log(child_question_ids);
    if ((child_question_id === null || child_question_id === 0) && child_question_ids !== null) {
      const idsArray = child_question_ids.split(',').map(id => id.trim());
      // console.log(idsArray);
      idsArray.forEach(id => {
        const myDiv = document.getElementById('questions_' + attendeeNumber + id);
        // console.log(myDiv);
        if (myDiv !== null) {
          myDiv.classList.add('d-none');

          // ------------------------------------
          let TempFormQuestions = { ...FormQuestions };
          const arrayToModify = TempFormQuestions[Key][parentFormIndex];
          const newArray = [...arrayToModify];
          // console.log(newArray);
          newArray.forEach(formId => {
            // console.log(id,formId.general_form_id);
            if (parseInt(id) === parseInt(formId.general_form_id)) {
              formId.ActualValue = "";
            }
            TempFormQuestions[Key][parentFormIndex] = newArray;
            // console.log(TempFormQuestions);

            setFormQuestions(TempFormQuestions);
          });
        }
      });
    }
    // setFormQuestions(FormQuestions);
  }

  // EXTRA PRICING
  const HandleAmount = (child_question_ids, question_label, parent_question_id, e, ticket_id, aNumber, question_form_type, general_form_id, question_id, price = 0, count = null, option_id = null, sold_items = null) => {
  //  console.log(count,sold_items);
  // console.log(child_question_ids, question_label, parent_question_id, ticket_id, aNumber, question_form_type, general_form_id, question_id,price);
   
    var othervalue = 0;
    if (question_form_type === "amount" && price !== 0) {
      othervalue = e.target.value;
    }
    
    if (question_form_type === "select" && price !== 0) {
      othervalue = price;
    }
   
    if(price === 0 && othervalue === 0){
      //console.log('aa');
      
      setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.question_form_type === question_form_type && item.parent_question_id === parent_question_id && item.general_form_id === general_form_id)));
      setTempTicketId(parseInt(ticket_id));
      setOtherAmountReset(0);
    }
    // console.log(price);
    if(question_form_type === "select" && price === 0){   // && price === 0
      setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.question_form_type !== question_form_type && item.parent_question_id === general_form_id)));

        const filteredData = Amount.filter(item => item.attendee_no !== aNumber);
        setAmount(filteredData);
    }
  
    if (count !== null && sold_items !== null) {
      if (count <= sold_items) {
        setError('The ' + question_label + ' you want to add is out of stock.')
        return;
      }
      setOtherAmountReset(1);
    }

    var value = 0;
    if (question_form_type === "amount") {
      value = e.target.value;
    }
    // console.log(value);

    if (question_form_type === "select" && price !== 0) {
      value = price;
    }

    if (question_form_type === "select"){
      setOptionFlag(option_id);
      setAttendeeIdAmt(aNumber);
    }
   
    //  console.log(value);
  
    if (value !== 0) {
     
      const existingIndex = Amount.findIndex(item => item.aNumber === aNumber && item.general_form_id === general_form_id);
      // console.log(existingIndex); //&& question_form_type !== "amount"

      if (existingIndex !== -1 ) {
       
        //------------- new added for addition value remove purpose 
        setAmount(prevAmount => [
          ...prevAmount,
          {
            value: value,
            ticket_id: ticket_id,
            aNumber: aNumber,
            question_form_type: question_form_type,
            general_form_id: general_form_id,
            question_id: question_id,
            parent_question_id: parent_question_id,
            question_label: question_label,
            count: count,
            option_id: option_id,
            attendee_no: aNumber,
            amtFlag: true
            // new_flag: question_form_type === "select" ? true : false
          }
        ]);
       
        setAmount(prevAmount => {
          const updatedAmount = [...prevAmount];
         updatedAmount[existingIndex].value = parseFloat(value);
          return updatedAmount;
        });
      } else {
        
        setAmount(prevAmount => [
          ...prevAmount,
          {
            value: value,
            ticket_id: ticket_id,
            aNumber: aNumber,
            question_form_type: question_form_type,
            general_form_id: general_form_id,
            question_id: question_id,
            parent_question_id: parent_question_id,
            question_label: question_label,
            count: count,
            option_id: option_id,
            attendee_no: aNumber,
            amtFlag: true
            // new_flag: question_form_type === "select" ? true : false
          }
        ]);
      }
     
      // setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.question_form_type !== question_form_type && item.parent_question_id === general_form_id)));
      setOtherAmountReset(1);
    } else {
      
      setOtherAmountReset(1);
        // particular array remove with value remove
      if (value === 0 && price === null) {
       //console.log(1);
        setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.question_form_type !== question_form_type && item.parent_question_id === general_form_id)));
      }
      if (value === 0 && price === 0) {
       //console.log(2);
        setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.question_form_type === question_form_type && item.parent_question_id === parent_question_id && item.general_form_id === general_form_id)));
      }
      if (parent_question_id === 0 && value === 0 && price === 0) {
       // console.log(3);
        const idsArray = child_question_ids.split(',').map(id => id.trim());
        idsArray.forEach(id => {
          setAmount(prevAmount => prevAmount.filter(item => !(item.aNumber === aNumber && item.ticket_id === ticket_id && item.general_form_id === parseInt(id))));
        });
      }
    }
   
    setTempTicketId(parseInt(ticket_id));
    setExtraAmtCall(true);
    // -----------------------------------------------------------------------------
    // console.log(Amount);
  };
  
  
  useEffect(() => {
    
    // setAmount(Amount);
    for (let i of Amount) {
      if(i.attendee_no === AttendeeIdAmt){
        i.amtFlag = i.option_id === OptionFlag ? true : false; 
      }
    }

    const filteredData = Amount.filter(item => item.amtFlag === true);
    // console.log(filteredData);
    setAmount(filteredData);
  },[OptionFlag,AttendeeIdAmt])

  // console.log(AttendeeIdAmt);
  //------------ all amount data set
  useEffect(() => {
   
    // console.log(Amount);
    if ((ExtraAmtCall && TempTicketId !== "") || OtherAmountReset === 0 || OtherAmountReset === 1) {
      //-----------------------
      const TicketArr = AllTickets.find(ticket => ticket.id === parseInt(TempTicketId));
    //  console.log(TicketArr);
      Calculation(TicketArr, CollectGst, PriceTaxesStatus, Amount, RaceCategoryCharges, OtherAmountReset);

      setTempTicketId("")
      setExtraAmtCall(false);
    }
  }, [AllTickets, Amount, CollectGst, ExtraAmtCall, OrgGstPercentage, PaymentGatewayFeePercentage, PaymentGatewayGstPercentage, PriceTaxesStatus, TempTicketId, TicketYtcrBasePrice, YtcrFeePercentage,OtherAmountReset])

  // CALCULATE FINALAMOUNT = TOTALPRICE  + EXTRA PRICING  extra price added
  // console.log(AllTickets);
  useEffect(() => {
 
    let extraPrice = 0;
    let itemValue = 0;
    let totalAmount = AllTickets.reduce((acc, item) => {
    //  console.log(Amount);
      if (parseInt(item.ticket_status) !== 2) {
       
        itemValue = (isNaN(parseFloat(item.BuyerPayment))) ? 0.00 : parseFloat(item.BuyerPayment) * (item.count);
      //  console.log(itemValue);
        if (Amount && Array.isArray(Amount)) {
          for (let i of Amount) {
            if (parseInt(i.ticket_id) === item.id && i.value) {
              itemValue += parseFloat(i.value);
            }
          }
        }
        
      }
      //----------- free ticket
      if (parseInt(item.ticket_status) === 2) {
        if (Amount && Array.isArray(Amount)) {
          for (let i of Amount) {
            if (parseInt(i.ticket_id) === item.id && i.value) {
              itemValue += parseFloat(i.value);
            }
          }
        }
        if (Amount.length === 0) {
          itemValue = 0;
        }
      }
        
      //------------- other amount added to tax added
      if(item.Extra_Amount_Payment_Gateway !== undefined && item.Extra_Amount_Payment_Gateway_Gst !== undefined && item.Extra_Amount_Payment_Gateway !== '0.00' && item.Extra_Amount_Payment_Gateway_Gst !== '0.00'){
       
        extraPrice = parseFloat(item.Extra_Amount_Payment_Gateway) + parseFloat(item.Extra_Amount_Payment_Gateway_Gst);
        itemValue += extraPrice;
        
      }
  
      return acc + itemValue;
      // console.log(itemValue);
      // return itemValue;
    }, 0);
    //console.log(totalAmount);
    if (totalAmount !== NaN && totalAmount >= 0) {
      setFinalAmount(totalAmount.toFixed(2));
    }

  }, [AllTickets, Amount, TotalPrice,OptionFlag,AttendeeIdAmt]);

 

//console.log(FinalAmount);
  // Auto populate field for booking form
  // console.log(FormQuestions);

  const HandleAttendeeMapping = (value, Key, QIndex, AttendeeNumber) => {

    let AttendeeType = new Array(AttendeeNumber); // Initialize an array of length AttendeeNumber
    let i = 0;
    while (i < AttendeeNumber) {
      if (i + 1 === AttendeeNumber) {
        AttendeeType[i] = value;
      }
      i++;
    }
    setAttendeeType(AttendeeType);
   

    let TempFormQuestions = { ...FormQuestions };
    const arrayToModify = TempFormQuestions[Key];
    const newArray = [...arrayToModify];
    if (value === "myself") {
      let UserData = [];
      setLoaderFlag(true);
      axios.get("get_profile")
        .then((response) => {
          UserData = response.data.data.userData[0];
         // console.log(newArray);
          // ------------------------------------------
          // eslint-disable-next-line array-callback-return
          newArray[QIndex].map((item) => {
            if (item.user_field_mapping !== "") {
              if (!["country", "state", "city","nationality","countries","id_proof_no","blood_group"].includes(item.user_field_mapping)) {
                // console.log(item.user_field_mapping,UserData[item.user_field_mapping])
                if(item.user_field_mapping !== 'country' || item.user_field_mapping !== 'nationality' || item.user_field_mapping !== 'countries' || item.user_field_mapping !== 'state' || item.user_field_mapping !== 'city' || item.user_field_mapping !== 'id_proof_no' || item.user_field_mapping !== 'blood_group'){
                  item.ActualValue = UserData[item.user_field_mapping] ? UserData[item.user_field_mapping] : "";
                  item.Error = "";
                }
              }
            }
            
            //------------- new added for unique registration check
            // if(item.question_form_type === "email"){
            //   AllEmailsDetails.push(item.ActualValue);
            // }

          })

          

          // let AgeCriteriaData = [];
          let gender = "";
          let dob = "";

          // eslint-disable-next-line array-callback-return
          newArray[QIndex].map((question, questionI) => {
            if (question.user_field_mapping === "gender" && question.ActualValue !== "") {
              gender = question.ActualValue;
            }
            if (question.user_field_mapping === "dob" && question.ActualValue !== "") {
              dob = question.ActualValue;
            }
            // console.log(gender, dob);
            if (gender !== "" && dob !== "") {

              // console.log(gender, dob);
              let dateOfBirth = dob;
              let dobDate = parseISO(dateOfBirth);
              // console.log(new Date(), dobDate)
              const newDate = new Date(EventStartTime * 1000);
              let age = differenceInYears(newDate, dobDate);
              // console.log(age)
              if (age !== "") {
                setKey(Key);
                setIndex(QIndex);
                setAgeCatFlag(true);
              }
            } else {
              newArray[QIndex] = newArray[QIndex].filter(question => question.question_form_name !== 'AgeCriteriaData');
              TempFormQuestions[Key] = newArray;
              setFormQuestions(TempFormQuestions);
            }
          })
          // ------------------------------------------
          // console.log(newArray)
          TempFormQuestions[Key] = newArray;
          // console.log(TempFormQuestions);
          setFormQuestions(TempFormQuestions);
          setLoaderFlag(false);
          // ------------------------------------------
        })
        .catch((error) => {
          console.log(error);
          setLoaderFlag(false);
        })
    } else {
      //----------- dropdown select option - other due to reset all fields
      // eslint-disable-next-line array-callback-return
      newArray[QIndex] = newArray[QIndex].filter((item) => {
        // if (item.user_field_mapping !== "") {
        //   item.ActualValue = "";
        // }

        if (item.user_field_mapping !== "") {
          if (!["country", "state", "city","nationality","countries","id_proof_no","blood_group"].includes(item.user_field_mapping)) {
            // console.log(item.user_field_mapping,UserData[item.user_field_mapping])
            if(item.user_field_mapping !== 'country' || item.user_field_mapping !== 'nationality' || item.user_field_mapping !== 'countries' || item.user_field_mapping !== 'state' || item.user_field_mapping !== 'city' || item.user_field_mapping !== 'id_proof_no' || item.user_field_mapping !== 'blood_group'){
              item.ActualValue = "";
              item.Error = "";
            }
          }
        }
        return item.question_form_name !== 'AgeCriteriaData';
      });
      TempFormQuestions[Key] = newArray;
      // console.log(TempFormQuestions);
      setFormQuestions(TempFormQuestions);
    }
  }
  //console.log(FormQuestions);
  return (
    <>
      {PaymentConfirmPopupflag === true ?
        <PaymentConfirmPopup setPaymentConfirmPopupflag={setPaymentConfirmPopupflag} EventId={EventId} FinalAmount={FinalAmount} BookTicktesArray={BookTicktesArray} NewFilesArray={FinalParitcipantFiles} />
        :
        ''
      }

      {LoaderFlag ? <Loader /> : null}
      <ToastContainer theme='colored' />
      <HeaderSecond Login={Login} setLogin={setLogin}/>

      {LoginExpiredFlag && (

          <div className="modal is-visible" data-animation="slideInUp">
              <div className="modal-dialog quickview__main--wrapper">
                  <div className="quickview__inner">
                      <div className="row">
                          <div className="login__section">
                              <div className="login__section--inner">
                                  <div className="p-5">
                                      <div className="account__login--header mb-25">
                                        
                                          <p className="account__login--header__desc">Login session expired</p>
                                        
                                      </div>
                                      <div className="d-flex gap-4">
                                          <button className="account__login--btn secondary__btn mt-2" onClick={() => { LoginExpired();}}>Ok</button>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      )}


      <main className="main__content_wrapper">
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                    Participant Details
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items" onClick={(e) => BackToHome()}>
                      <div className="text-white">
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white">
                        Safe Checkout
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Participant Details</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div className="main checkout__mian">

                  {/* EVENT NAME AND DATE */}
                  <div className="checkout__content--step section__shipping--address">
                    <div className="mb-5">
                      <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">

                        <div className="row w-100">
                          <div className="col-sm-10">
                            <h2 className="section__header--title h3 mb-3">{EventData.display_name}</h2>
                            <p>{EventData.start_date} | {EventData.city_name}</p>
                          </div>
                          <div className="col-sm-2 text-right">
                            <Link to={"/register_now/" + EventId}>
                              <div className="event-visibility hand_cursor ">
                                <div className="visibility gap-1 ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={20}
                                    viewBox="0 -960 960 960"
                                    width={20}
                                  >
                                    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                                  </svg>
                                  Back
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="attendee-details mb-5">
                    <div className="row">
                      {/* START: SAFECHECKOUT CARD */}
                      <div className="col-sm-12">
                        <div className="card p-0">
                          <form id="FormQue" encType='multipart/form-data'>
                            {Object.values(FormQuestions).map((ticket, index) => (
                              Object.values(ticket).map((form, i) => {
                                attendeeNumber++;
                                let temp = attendeeNumber;
                                // console.log(index)
                                return (
                                  <React.Fragment key={`fragment_${index}_${i}_${temp}`}>
                                    <div className="card-header p-4" onClick={(e) => ActiveTicket(e, temp)} key={`header_${index}_${i}_${temp}`}>
                                      <div className="row">
                                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 text-left">
                                          <p className="h4">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg> Participant - {attendeeNumber}</p>
                                        </div>

                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-right">
                                          <p className="h4">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z" /></svg>
                                            {
                                              AllTickets.map((all_ticket) => (
                                                (all_ticket.id === parseInt(Object.keys(FormQuestions)[index]) ? all_ticket.ticket_name : "")
                                              ))
                                            }
                                          </p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-sm-4 text-left">
                                          <div className="form-floating mt-3 mb-0 force-dropdown " >
                                            <select className="checkout__input--field border-radius-5 " required="" value={AttendeeType[temp - 1]} onChange={(e) => HandleAttendeeMapping(e.target.value, Object.keys(FormQuestions)[index], i, temp)} >
                                              <option value="">Registration For</option>
                                              <option value="myself">Myself</option>
                                              <option value="other">Other</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="card-body p-4" key={`body_${index}_${i}_${temp}`}>
                                      <div className={(ShowIndex === temp) ? "row mb-5" : "row mb-5 d-none"}>
                                        {form.map((question, questionIndex) => (
                                          <React.Fragment key={`question_${index}_${i}_${temp}_${questionIndex}`}>
                                            {
                                              question.question_form_type === "age_category" && question.data && question.data.length > 0 ? (
                                                // question.data.map((item, ageIndex) => (
                                                <div className="col-sm-6" key={`age_category_${index}_${i}_${temp}_${questionIndex}`}>
                                                  <div className="form-floating mt-3 mb-0 d-flex justify-content-end align-items-center">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder=""
                                                      value={question.data[0].age_category}
                                                      disabled
                                                    />
                                                    <label>
                                                      Age Category
                                                    </label>
                                                  </div>
                                                </div>
                                                // ))
                                              ) : (() => {
                                                switch (question.question_form_type) {
                                                  case 'text':
                                                  case 'date':
                                                  case 'amount':
                                                  case 'mobile':
                                                    return (
                                                      <>
                                                        <div
                                                          className={"col-sm-6 " + (question.parent_question_id === 0 ? "" : "d-none")}
                                                          key={`question_${index}_${i}_${temp}_${questionIndex}`}
                                                          id={"questions_" + temp + question.general_form_id}>

                                                          <div className="form-floating mt-3 mb-0 d-flex justify-content-end align-items-center">
                                                            <input
                                                              type={question.question_form_type}
                                                              className="form-control"
                                                              placeholder=""
                                                              value={question.ActualValue ? question.ActualValue : ""}
                                                              onChange={(e) => {

                                                                ShowChildQuestions(index, i, questionIndex, question.general_form_id, null, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);

                                                                handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);

                                                                HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id);
                                                              }
                                                              }
                                                            />
                                                            <label>
                                                              {question.question_label}
                                                              {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                            </label>

                                                            {question.question_hint !== "" ?
                                                              <div className={question.question_form_type === 'date' ? "px-3 mx-5" : "px-3"} style={{ position: "absolute" }} onMouseOver={() => setTooltip(question.id)}
                                                                onMouseLeave={() => setTooltip('')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                                {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                              </div> 
                                                              : null}
                                                          </div>
                                                          <small className="text-danger">{question.Error}</small>
                                                        </div>
                                                      </>
                                                    );

                                                  case 'email':
                                                    return (
                                                      <>
                                                        <div
                                                          className={"col-sm-6 " + (question.parent_question_id === 0 ? "" : "d-none")}
                                                          key={`question_${index}_${i}_${temp}_${questionIndex}`}
                                                          id={"questions_" + temp + question.general_form_id}>

                                                          <div className="form-floating mt-3 mb-0 d-flex justify-content-end align-items-center">
                                                            <input
                                                              type={question.question_form_type}
                                                              className="form-control"
                                                              placeholder=""
                                                              value={question.ActualValue ? question.ActualValue : ""}
                                                              onChange={(e) => {

                                                                ShowChildQuestions(index, i, questionIndex, question.general_form_id, null, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);

                                                                handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);

                                                                HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id);
                                                              }
                                                              }
                                                            />
                                                            <label>
                                                              {question.question_label}
                                                              {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                            </label>

                                                            {question.question_hint !== "" ?
                                                              <div className="px-3" style={{ position: "absolute" }} onMouseOver={() => setTooltip(question.id)}
                                                                onMouseLeave={() => setTooltip('')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                                {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                              </div>
                                                              : null}
                                                          </div>
                                                          <small className="text-danger">{question.Error}</small>
                                                        </div>
                                                      </>
                                                    );

                                                  case 'file':
                                                    return (
                                                      <div
                                                        className={question.parent_question_id === 0 ? "col-sm-6 newtooltip-container " + question.parent_question_id : "col-sm-6 " + question.parent_question_id + " d-none"} key={`file_${index}_${i}_${temp}_${questionIndex}`}
                                                        id={"questions_" + temp + question.general_form_id}>
                                                        <div className="form-floating mt-3 mb-0 d-flex justify-content-end align-items-center">
                                                          <input
                                                            type={question.question_form_type}
                                                            className="form-control"
                                                            placeholder=""
                                                            name={"file_" + temp + question.general_form_id + "[]"}
                                                            // value={question.ActualValue ? question.ActualValue : ""}
                                                            onChange={(e) => {
                                                              ShowChildQuestions(index, i, questionIndex, question.general_form_id, null, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);
                                                              handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);
                                                              handleFileChange(e, temp, index, i, questionIndex, question.general_form_id, Object.keys(FormQuestions)[index])
                                                            }
                                                            }
                                                          />
                                                          <label>
                                                            {question.question_label}
                                                            {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                          </label>

                                                          {question.question_hint !== "" ?
                                                            <div className="px-3" style={{ position: "absolute" }} onMouseOver={() => setTooltip(question.id)}
                                                              onMouseLeave={() => setTooltip('')}>
                                                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                              {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                            </div>
                                                            : null}

                                                        </div>
                                                        <small className="text-danger">{question.Error}</small>
                                                      </div>
                                                    );

                                                  case 'radio':
                                                    return (
                                                      <div
                                                        className={question.parent_question_id === 0 ? "col-sm-12 text-left my-3 newtooltip-container " + question.parent_question_id : "col-sm-12 text-left my-3 " + question.parent_question_id + " d-none"}
                                                        key={`radio_${index}_${i}_${temp}_${questionIndex}`}
                                                        id={"questions_" + temp + question.general_form_id}>
                                                        <label className="checkout__input--label mt-3 d-flex" htmlFor="input1">{question.question_label}
                                                          {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}

                                                          {question.question_hint !== "" ?
                                                            <div className="px-3 position-relative" onMouseOver={() => setTooltip(question.id)}
                                                              onMouseLeave={() => setTooltip('')}>
                                                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                              {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                            </div>
                                                            : null}
                                                        </label>

                                                        <div className="row mt-3 mb-0">

                                                          {JSON.parse(question.question_form_option) && JSON.parse(question.question_form_option).length > 0 ? (
                                                            JSON.parse(question.question_form_option).map((item, radioIndex) => (
                                                              <div className="col-sm-4" key={radioIndex}>
                                                                <div className="plans">
                                                                  <label className="plan basic-plan">

                                                                    <input
                                                                      name={`event_type_${index}_${i}_${radioIndex}_${questionIndex}`}
                                                                      type="radio"
                                                                      className="btn-check"
                                                                      id={`btn-check-outlined-${index}_${i}_${radioIndex}_${questionIndex}`}
                                                                      autoComplete="off"
                                                                      value={item.id}
                                                                      onChange={(e) => {
                                                                        ShowChildQuestions(index, i, questionIndex, question.general_form_id, item.child_question_id, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);

                                                                        HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id, 0);

                                                                        handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);
                                                                      }}
                                                                      checked={parseInt(question.ActualValue) === parseInt(item.id) ? true : false}
                                                                    />
                                                                    <div className="plan-content my-2">
                                                                      <div className="plan-details" >
                                                                        <span className="h3 m-0">{item.label}</span>
                                                                      </div>
                                                                    </div>
                                                                  </label>
                                                                </div>

                                                              </div>
                                                            ))
                                                          )
                                                            : null}
                                                        </div>
                                                        <small className="text-danger">{question.Error}</small>
                                                      </div>
                                                    );
                                                  
                                                  case 'checkbox':
                                                  return (
                                                    <div
                                                      className={question.parent_question_id === 0 ? "col-sm-12 text-left my-3 newtooltip-container " + question.parent_question_id : "col-sm-12 text-left my-3 " + question.parent_question_id + " d-none"}
                                                      key={`checkbox_${index}_${i}_${temp}_${questionIndex}`}
                                                      id={"questions_" + temp + question.general_form_id}>
                                                      <label className="checkout__input--label mt-3 d-flex" htmlFor="input1">{question.question_label}
                                                        {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}

                                                        {question.question_hint !== "" ?
                                                          <div className="px-3 position-relative" onMouseOver={() => setTooltip(question.id)}
                                                            onMouseLeave={() => setTooltip('')}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                            {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                          </div>
                                                          : null}
                                                      </label>

                                                      <div className="row mt-3 mb-0">

                                                        {JSON.parse(question.question_form_option) && JSON.parse(question.question_form_option).length > 0 ? (
                                                          JSON.parse(question.question_form_option).map((item, checkboxIndex) => (
                                                            <div className="col-sm-4" key={checkboxIndex}>
                                                              <div className="plans">
                                                                <label className="plan basic-plan">

                                                                  <input
                                                                    name={`event_type_${index}_${i}_${checkboxIndex}_${questionIndex}`}
                                                                    type="checkbox"
                                                                    className="btn-check"
                                                                    id={`btn-check-outlined-${index}_${i}_${checkboxIndex}_${questionIndex}`}
                                                                    autoComplete="off"
                                                                    value={item.id}
                                                                    onChange={(e) => {
                                                                      // ShowChildQuestions(index, i, questionIndex, question.general_form_id, item.child_question_id, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);

                                                                      HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id, 0);

                                                                      handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex, item.id);
                                                                    }}
                                                                    // checked={parseInt(question.ActualValue) === parseInt(item.id) ? true : false}
                                                                    checked={item.checked ? true : false}
                                                                  />
                                                                  <div className="plan-content my-2">
                                                                    <div className="plan-details" >
                                                                      <span className="h3 m-0">{item.label}</span>
                                                                    </div>
                                                                  </div>
                                                                </label>
                                                              </div>

                                                            </div>
                                                          ))
                                                        )
                                                          : null}
                                                      </div>
                                                      <small className="text-danger">{question.Error}</small>
                                                    </div>
                                                );

                                                  case 'select':
                                                  case 'countries':
                                                    // case 'states':
                                                    return (
                                                      <>
                                                        <div
                                                          className={"col-sm-6 newtooltip-container " + (question.parent_question_id === 0 ? "" : "d-none")}
                                                          key={`select_${index}_${i}_${temp}_${questionIndex}`}
                                                          id={"questions_" + temp + question.general_form_id}>
                                                          <div className="form-floating mt-3 mb-0" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <label className="select-lable"> {question.question_label}{question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                            </label>

                                                            <select className="form-control checkout__input--field border-radius-5" required="" value={question.ActualValue ? question.ActualValue : "101"}
                                                              onChange={(e) => {

                                                                const selectedOptionIndex = e.target.selectedIndex - 1;
                                                                const selectedOption = JSON.parse(question.question_form_option)[selectedOptionIndex];
                                                                // console.log(selectedOption)
                                                                const childQuestionId = selectedOption !== undefined ? selectedOption.child_question_id : 0 ;

                                                                const itemPrice = selectedOption !== undefined ? selectedOption.price : 0;
                                                                const itemCount = selectedOption !== undefined ? selectedOption.count : 0;
                                                                const itemCountId = selectedOption !== undefined ? selectedOption.id : 0;
                                                                const itemSold = selectedOption !== undefined ? selectedOption.current_count : 0;
                                                                const itemTempCount = selectedOption !== undefined ? selectedOption.select_count : 0;
                                                                // console.log(itemSale);
                                                                const childQuestionIds = selectedOption !== undefined ? question.child_question_ids : question.child_question_ids;


                                                              //  console.log(childQuestionId,childQuestionIds);
                                                             //   if(childQuestionId){
                                                                  ShowChildQuestions(index, i, questionIndex, question.general_form_id, childQuestionId, childQuestionIds, temp, Object.keys(FormQuestions)[index])
                                                               // }

                                                                handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);
// , 0, itemCountId, itemCount, itemSold, itemTempCount
                                                                HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id, itemPrice, itemCount, itemCountId, itemSold);
                                                              }}
                                                            >
                                                              <option value={0}>-- {question.question_label}-- </option>
                                                              {
                                                                question.question_form_option !== "" ?
                                                                  JSON.parse(question.question_form_option).map((item, optionIndex) => (
                                                                    <option key={`option_${index}_${i}_${temp}_${questionIndex}_${optionIndex}`} value={item.id}>
                                                                      {item.label}
                                                                    </option>
                                                                  ))
                                                                  : null
                                                              }

                                                            </select>
                                                            {question.question_hint !== "" ?
                                                              <div className="px-3" style={{ display: "flex", position: "absolute", top: "10px" }} onMouseOver={() => setTooltip(question.id)}
                                                                onMouseLeave={() => setTooltip('')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                                {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                              </div>
                                                              : null}

                                                          </div>
                                                          <small className="text-danger">{question.Error}</small>
                                                        </div>
                                                      </>
                                                    );

                                                  case 'states':
                                                  case 'cities': 
                                                    return (
                                                      <>
                                                        <div
                                                          className={"col-sm-6 newtooltip-container " + (question.parent_question_id === 0 ? "" : "d-none")}
                                                          key={`select_${index}_${i}_${temp}_${questionIndex}`}
                                                          id={"questions_" + temp + question.general_form_id}>
                                                          <div className="form-floating mt-3 mb-0" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <label className="select-lable"> Select  {question.question_label}{question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                            </label>


                                                            <select className="form-control checkout__input--field border-radius-5" required="" value={question.ActualValue ? question.ActualValue : ""}
                                                              onChange={(e) => {

                                                                const selectedOptionIndex = e.target.selectedIndex - 1;
                                                                const selectedOption = JSON.parse(question.question_form_option)[selectedOptionIndex];
                                                                //console.log(selectedOption)
                                                                const childQuestionId = selectedOption !== undefined ? selectedOption.child_question_id : '';

                                                                const itemPrice = selectedOption !== undefined ? selectedOption.price : 0;
                                                                const itemCount = selectedOption !== undefined ? selectedOption.count : 0;
                                                                const itemCountId = selectedOption !== undefined ? selectedOption.id : 0;
                                                                const itemSold = selectedOption !== undefined ? selectedOption.current_count : 0;

                                                                // console.log(childQuestionId);

                                                                if(childQuestionId){
                                                                  ShowChildQuestions(index, i, questionIndex, question.general_form_id, childQuestionId, question.child_question_ids, temp, Object.keys(FormQuestions)[index])
                                                                }

                                                                handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);

                                                                HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id, itemPrice, itemCount, itemCountId, itemSold);
                                                              }}
                                                            >
                                                              <option value={0}>-- Select {question.question_label}-- </option>
                                                              {
                                                                question.question_form_option !== "" ?

                                                                  JSON.parse(question.question_form_option).map((item, optionIndex) => (
                                                                    <option key={`option_${index}_${i}_${temp}_${questionIndex}_${optionIndex}`} value={item.id}>
                                                                      {item.name}
                                                                    </option>
                                                                  ))
                                                                  : null
                                                              }

                                                            </select>
                                                            {question.question_hint !== "" ?
                                                              <div className="px-3" style={{ display: "flex", position: "absolute", top: "10px" }} onMouseOver={() => setTooltip(question.id)}
                                                                onMouseLeave={() => setTooltip('')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                                {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                              </div>
                                                              : null}

                                                          </div>
                                                          <small className="text-danger">{question.Error}</small>
                                                        </div>
                                                      </>
                                                    );

                                                  case 'textarea':
                                                    return (
                                                      <div
                                                        className={question.parent_question_id === 0 ? "form-floating mt-3 mb-0 newtooltip-container d-flex flex-column justify-content-end align-items-center align-items-center" + question.parent_question_id : "form-floating mt-3 mb-0 " + question.parent_question_id + " d-none"}
                                                        key={`textarea_${index}_${i}_${temp}_${questionIndex}`}
                                                        id={"questions_" + temp + question.general_form_id}>
                                                        <textarea
                                                          type="text"
                                                          className="form-control "
                                                          placeholder=""
                                                          style={{ minHeight: "100px", display: "inline-block", resize: "both" }}
                                                          value={question.ActualValue ? question.ActualValue : ""}
                                                          onChange={(e) => {
                                                            ShowChildQuestions(index, i, questionIndex, question.general_form_id, null, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);
                                                            handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex)
                                                          }
                                                          }
                                                          rows={8} cols={50}
                                                        />
                                                        <label>{question.question_label}
                                                          {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                        </label>

                                                        {question.question_hint !== "" ?
                                                          <div className="px-3" style={{ position: "absolute", top: '0', right: '0', marginRight: '15px', marginTop: '15px' }} onMouseOver={() => setTooltip(question.id)}
                                                            onMouseLeave={() => setTooltip('')} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                            {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                          </div>
                                                          :
                                                          null}
                                                        <small className="text-danger">{question.Error}</small>
                                                      </div>
                                                    );
                                              //------------ new added time 

                                              case 'time':
                                                    return (
                                                      <>
                                                        <div
                                                          className={"col-sm-6 " + (question.parent_question_id === 0 ? "" : "d-none")}
                                                          key={`question_${index}_${i}_${temp}_${questionIndex}`}
                                                          id={"questions_" + temp + question.general_form_id}>

                                                          <div className="form-floating mt-3 mb-0 d-flex justify-content-end align-items-center">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="HH:mm:ss"
                                                              maxLength={8} 
                                                              value={question.ActualValue ? question.ActualValue : ""}
                                                              onChange={(e) => {

                                                                // ShowChildQuestions(index, i, questionIndex, question.general_form_id, null, question.child_question_ids, temp, Object.keys(FormQuestions)[index]);

                                                                handleFormAnswers(e, Object.keys(FormQuestions)[index], i, questionIndex);

                                                                // HandleAmount(question.child_question_ids, question.question_label, question.parent_question_id, e, Object.keys(FormQuestions)[index], temp, question.question_form_type, question.general_form_id, question.id);
                                                              }
                                                              }
                                                            />
                                                            <label>
                                                              {question.question_label+' (HH:MM:SS)'}
                                                              {question.is_manadatory ? <span className="checkout__input--label__star">*</span> : null}
                                                            </label>

                                                            {question.question_hint !== "" ?
                                                              <div className="px-3" style={{ position: "absolute" }} onMouseOver={() => setTooltip(question.id)}
                                                                onMouseLeave={() => setTooltip('')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

                                                                {Tooltip === question.id && <CustomTooltip text={question.question_hint} HintType = {question.hint_type} HintImage = {question.hint_image} />}
                                                              </div>
                                                              : null}
                                                          </div>
                                                          <small className="text-danger">{question.Error}</small>
                                                        </div>
                                                      </>
                                                    );

                                                  default:
                                                    return null;
                                                }
                                              })()}
                                          </React.Fragment>
                                        ))}
                                      </div>

                                      {
                                        attendeeNumber !== TotalAttendee ?
                                          <>
                                            <div className={(ShowIndex === attendeeNumber) ? "checkout__content--step__footer d-flex align-items-center justify-content-end gap-4" : "checkout__content--step__footer d-flex align-items-center justify-content-end gap-4 d-none"}>
                                              <input type="button" name="command" value={"Save & Next (" + attendeeNumber + "/" + TotalAttendee + ")"} className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => handleNext(e, Object.keys(FormQuestions)[index], i, temp)} />
                                            </div>
                                          </>
                                          :
                                          null
                                      }
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            ))}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* SUMMARY */}
              {FormQuestions && FormQuestions.length !== 0 ?
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 safe-checkout-summary">
                  <aside className="checkout__sidebar sidebar border-radius-10">
                    <h2 className="section__header--title text-center h3 mb-5">SUMMARY</h2>
                    <div className="card p-4 mb-4">
                      <div className="row my-3">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                          <p className="m-0">Price ({TotalAttendee} Registration)</p>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                          <p className="m-0">₹{parseFloat(TotalPrice).toFixed(2)}</p>
                        </div>
                        {/* EXTRA AMOUNT */}
                        <div>
                          {Amount.map((item, index) => {
                            let suffix = item.aNumber === 1 ? "st" : item.aNumber === 2 ? "nd" : item.aNumber === 3 ? "rd" : "th";
                            return (
                              <React.Fragment key={index}>
                                <div className="row">
                                  <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                    <p className="my-1">{`${item.aNumber}${suffix} Attendee ${item.question_label}`}</p>
                                  </div>
                                  <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                    <p className="my-1">₹{(item.value === "" || isNaN(item.value)) ? "0.00" : parseFloat(item.value).toFixed(2)}</p>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        {/* AllTickets GST */}
                        {/* {console.log(GstArray)} */}

                        <div>
                          {GstArray.map((item, index) => {
                            let total_platform_fee = 0;
                            let total_taxes = 0;
                            let sub_total = 0;
                            
                            if(item.Extra_Amount_Payment_Gateway !== '0.00'){
                              total_platform_fee = parseFloat(item.count * item.Total_Platform_Fee) + parseFloat(item.Extra_Amount_Payment_Gateway);
                            }else{
                              total_platform_fee = parseFloat(item.count * item.Total_Platform_Fee); 
                            }

                            if(item.Extra_Amount_Payment_Gateway_Gst !== '0.00'){
                              total_taxes = parseFloat(item.count * item.Total_Taxes) + parseFloat(item.Extra_Amount_Payment_Gateway_Gst);
                            }else{
                              total_taxes = parseFloat(item.count * item.Total_Taxes); 
                            }
                            
                            if(item.Extra_Amount_Payment_Gateway !== '0.00' && item.Extra_Amount_Payment_Gateway_Gst !== '0.00'){
                            // console.log(item.Extra_Amount_Payment_Gateway,item.Extra_Amount_Payment_Gateway_Gst,item.BuyerPayment);
                              sub_total = parseFloat(item.count * item.BuyerPayment) + parseFloat(item.Extra_Amount_Payment_Gateway) + parseFloat(item.Extra_Amount_Payment_Gateway_Gst);
                            }else{
                              sub_total = parseFloat(item.count * item.BuyerPayment);
                            }
                            
  

                            return (
                              <React.Fragment key={index}>
                                <hr style={{ backgroundColor: "transparent", borderTop: "2px dashed gray" }} />
                                <div className="row">
                                  <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                    <p className="m-0 h4">{item.ticket_name + " (x" + item.count + ")"}</p>
                                  </div>
                                  <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                    <p className="m-0">
                                    
                                      {/* {parseInt(item.ticket_status) === 1 ? <> ₹{parseFloat(item.discount_ticket_price ? (item.discount_ticket_price * item.count) : item.ticket_show_price).toFixed(2)}</> :
                                        <span className="total-discount">Free</span>
                                      } */}
                                      {parseInt(item.ticket_status) === 1 ? <> ₹{parseFloat(item.discount_ticket_price ? (item.discount_ticket_price * item.count) : (item.ticket_price * item.count)).toFixed(2)}</> :
                                        <span className="total-discount">Free</span>
                                      }
                                      
                                    </p>
                                  </div>
                                </div>
                                <br />

                                {(item.hasOwnProperty("appliedCouponId") && item.appliedCouponId !== 0 && parseInt(item.ticket_status) !== 2) ?
                                  <>
                                    <div className="row">
                                      <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                        <p className="m-0">{item.appliedCouponCode} (Coupon)</p>
                                      </div>
                                      <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                        <p className="m-0"><b></b>₹{parseFloat(item.appliedCouponAmount).toFixed(2)}</p>
                                      </div>
                                    </div>
                                    <br />
                                  </>
                                  : null}

                                {/* {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Convenience Fee</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Convenience_Fee).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Platform Fee</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Platform_Fee).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Payment Gateway Charges ({item.Payment_Gateway_Fee}%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Payment_Gateway_Charges).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                } */}

                                {/* {parseInt(item.ticket_status) !== 2 && item.Extra_Amount_Payment_Gateway !== '0.00' && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Extra Amount PG Charges ({item.Payment_Gateway_Fee}%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.Extra_Amount_Payment_Gateway).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }*/}
                                
                                {/* && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) */}
                                {parseInt(item.ticket_status) !== 2 && total_platform_fee !== 0 ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="h4 m-0"> Platform Fee</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(total_platform_fee).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                } 
                                
                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <hr /> : null
                                }

                                {/* {item.RegistrationFee !== "0.00" && item.RegistrationFee !== 0 && parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Registration Fee GST ({item.OrgGstPercentage + "%"})</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Registration_Fee_GST).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Convenience Fee GST (18%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Convenience_Fee_GST_18).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Platform Fee GST (18%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Platform_Fee_GST_18).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Payment Gateway GST (18%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.count * item.Payment_Gateway_GST_18).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && item.Extra_Amount_Payment_Gateway_Gst !== '0.00' && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0">Extra Amount PG GST (18%)</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(item.Extra_Amount_Payment_Gateway_Gst).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                } */}

                                {/* && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) */}
                                {parseInt(item.ticket_status) !== 2 && total_taxes !== 0 ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="h4 m-0">Taxes</p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0">₹{parseFloat(total_taxes).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <hr /> : null
                                }

                                {parseInt(item.ticket_status) !== 2 && (item.CollectGst === 1 && item.ExcPriceTaxesStatus === 2) ?
                                  <div className="row">
                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                      <p className="m-0"><strong>Sub Total</strong></p>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                      <p className="m-0"> ₹{parseFloat(sub_total).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  : null
                                }


                              </React.Fragment>
                            );
                          })}
                        </div>
                        {/*  */}
                      </div>
                      <hr style={{ backgroundColor: "transparent", borderTop: "2px solid gray" }} />
                      <div className="row my-3">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                          <p className="h4 m-0">Total Amount</p>

                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                          <p className="h4 m-0">₹{parseFloat(FinalAmount).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>

                    {/* {console.log(TermsConditions)} */}
                    {
                      TermsConditionsFlag ?
                        TermsConditions && TermsConditions.length > 0 ?
                          <>
                            <div className="col-lg-12 col-md-12 ">
                              <div className="form-check mt-3 mb-0">
                                <div className="form-check-group" style={{ paddingBottom: '10px' }}>
                                  <input type="checkbox" className="form-check-input" id="TermsAndCondition" checked={TermsAndCondition} onChange={(e) => {
                                    setTermsAndCondition(!TermsAndCondition)
                                  }} />
                                  <label className="form-check-label" htmlFor="exampleCheck1">
                                    <span className="org-title-link cursor-pointer" style={{ textDecoration: 'underline' }} onClick={() => {
                                      window.open('/event_terms_conditions/' + EventId, '_blank');
                                    }} >
                                      {TermsConditions[0].title}
                                    </span>
                                  </label>
                                  <small className="text-danger mb-5" style={{ textAlign: 'center' }}>{TermsAndConditionError}</small>
                                </div>
                              </div>
                            </div>
                          </>
                          : null
                        : null
                    }
                    {/* {console.log(FormQuestions)} */}
                    {ProceedTab ?
                      <>
                        <div className='card p-3 py-1 summary-proceed mobile-btn-ui'
                          onClick={(e) => HandleSubmit(e)}
                        >
                          <div className="row my-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 col-xxl-6">
                              <p className="h4 m-0"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113Z" /></svg> {TotalAttendee}</p>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-6 col-xxl-6 d-flex align-items-center justify-content-end">
                              <p className="h4 m-0">PROCEED <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg></p>
                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                    <small className="text-danger">{Error}</small>
                  </aside>

                  {/* <aside>
                    <div className="checkout__sidebar sidebar border-radius-10 mt-4">
                      <div className="form-floating mt-3 mb-0 d-flex align-items-center">
                        <input type="text" className="form-control" placeholder="" maxLength={100} />
                        <label>Enter coupon code</label>
                        <p className="apply-btn" style={{ position: "absolute", right: "20px" }} onClick={() => handleApplyCoupon()}>Apply</p>
                      </div>
                      {Coupons.map((item, index) => (
                        <div className="my-4 coupon-code-ui px-3">
                          <div className="card border-radius-10 mb-4">
                            <div className="row p-4">
                              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <img src={couponDiscount} alt="" style={{ width: "100%" }} />
                              </div>
                              <div className="col-sm-8">
                                <h4 className="">{item.discount_code}</h4>
                              
                                <small className="px-2 py-1 border-radius-5 dis-coupon-lable">
                                  {item.discount_amt_per_type === 1
                                    ? `Save ${item.discount_percentage}% on this event`
                                    : `Save ₹${item.discount_amount} on this event`}
                                </small>
                                <hr className="dotted-line" />
                                <small className="px-2 py-1 border-radius-5 close-popup-icon">{item.description}</small>
                              </div>
                              <div className="col-sm-2 d-flex justify-content-center hand_cursor">
                                {isCouponApplied(item.id) ? (
                                  <p className="apply-btn" onClick={() => handleRemoveCoupon(item)}>Remove</p>
                                ) : (
                                  <p className="apply-btn" onClick={() => handleApplyCoupon(item)}>Apply</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                  </aside> */}
                </div>
              : null }
              {/* END SUMMARY */}

            </div>
          </div>
        </section>
      </main >


      {/* SUCCESS POPUP */}
      {
        Success ?
          <div className="modal is-visible" data- animation="slideInUp" >
            <div className="modal-dialog quickview__main--wrapper">
              <header className="modal-header quickview__header">
                <button className="close-modal quickview__close--btn" aria-label="close modal" onClick={(e) => HandleClose(e)}>✕</button>
              </header>
              <div className="quickview__inner">
                <div className="row">
                  <div className="login__section">
                    <form action="#" >
                      <div className="login__section--inner">
                        <div className="p-2">
                          <div className="account__login--header mb-25 text-center">
                            <img className="mb-5" src={`${RegistrationSuccess}`} alt="" style={{ width: "30%" }} />
                            <h3 className="account__login--header__title mb-10 text__secondary">YAY!</h3>
                            <h3 className="account__login--header__title mb-10 text__primary">Your registration is successful..!</h3>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div >
          : null
      }
      {/* END SUCCESS POPUP */}
    </>
  );






};

export default TicketRegistration;