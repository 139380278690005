import React, { useEffect, useState } from "react";
import axios from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import noCoupons from "../img/other/no-coupon.png";

const FormQuestionPopup = (props) => {
 
  const [QuestionStatusMClass, setQuestionStatusMClass] = useState('active');
  const [QuestionStatusOpClass, setQuestionStatusOpClass] = useState('');
  const [QuestionStatus, setQuestionStatus] = useState(1);
  const [DisplayName, setDisplayName] = useState(props.questionName !== '' ? props.questionName : '');
  const [Loader, setLoader] = useState(false);
  const [LimitLengthCheck, setLimitLengthCheck] = useState(false);
  const [MinLength, setMinLength] = useState('');
  const [MaxLength, setMaxLength] = useState('');
  const [FeildMapping, setFeildMapping] = useState(props.fieldMappingValue !== '' ? props.fieldMappingValue : '');
  const [SubQustions, setSubQustions] = useState(false);
  const [QuestionType, setQuestionType] = useState('');
  const [QuestionTitle, setQuestionTitle] = useState('');
  const [questionInputType, setquestionInputType] = useState('');
  const [questionHint, setquestionHint] = useState('');
  
  const [SubQustionsArray, setSubQustionsArray] = useState([]);
  const [optionsArray, setoptionsArray] = useState(['']);
  // console.log(props.fieldMappingValue);
  const [SubQuestionStatusMClass, setSubQuestionStatusMClass] = useState('active');
  const [SubQuestionStatusOpClass, setSubQuestionStatusOpClass] = useState('');
  const [SubQuestionStatus, setSubQuestionStatus] = useState(1);
  
  const [DisplayNameError, setDisplayNameError] = useState('');
  const [MinLengthError, setMinLengthError] = useState('');
  const [MaxLengthError, setMaxLengthError] = useState('');
  const [QuestionTypeError, setQuestionTypeError] = useState('');
  const [QuestionTitleError, setQuestionTitleError] = useState('');
  const [questionInputTypeError, setquestionInputTypeError] = useState('');
  
  const [rows, setRows] = useState([]);
   
  const [PriceAddedFlag, setPriceAddedFlag] = useState(false);
  const [CountAddedFlag, setCountAddedFlag] = useState(false);
  const [AllowOtherAmtFlag, setAllowOtherAmtFlag] = useState(false);
  const [FormDetailsAll, setFormDetailsAll] = useState([]);
  const [FormName, setFormName] = useState(props.formName !== '' ? props.formName : 0);
  // console.log(FormType);
  const [ApplayTickets, setApplayTickets] = useState(1);
  const [AllTicktes, setAllTicktes] = useState('active');  
  const [SelectedTicktes, setSelectedTicktes] = useState('');
  const [TicketsData, setTicketsData] = useState([]);
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [SelectedTicketError, setSelectedTicketError] = useState('');

  const [HintType, setHintType] = useState(props.HintType !== '' ? props.HintType : 1);
  const [UploadHintfile, setUploadHintfile] = useState('');
  const [UploadedFileName, setUploadedFileName] = useState('');
  const [UploadfileError, setUploadfileError] = useState('');
  const [filedivFlag, setfiledivFlag] = useState(0);

  const [SubQueHintType, setSubQueHintType] = useState(1);
  const [UploadHintfile1, setUploadHintfile1] = useState('');
  const [UploadfileError1, setUploadfileError1] = useState('');
  const [UploadedFileName1, setUploadedFileName1] = useState('');
  
  const [MainQuestionHint, setMainQuestionHint] = useState(props.MainQuestionHint !== '' ? props.MainQuestionHint : '');
  
  useEffect(() => {
    if(props.TicketsDetails){
      setTicketsData(props.TicketsDetails !== '' ? props.TicketsDetails : []);
    }else{
      setButtonDisable(true);
    }
  }, [props.TicketsDetails])
  
  const handleTicketChange = (index, evnt) => {
   
    let TicketArray = [...TicketsData];
    TicketArray[index]['checked'] = evnt.target.checked;
    setTicketsData(TicketArray);
    // console.log(TicketsData);
  }

  // console.log(props.MainQuestionHint);

  const handleValidation = () => {
   
    var flag = true;
  
    if (DisplayName === "") {
      setDisplayNameError("Please enter display name");
      flag = false;
    } else {
      setDisplayNameError("");
    }

    if(LimitLengthCheck === true){
      if (MinLength === "") {
        setMinLengthError("Please enter Min value");
        flag = false;
      } else {
        setMinLengthError("");
      }

      if (MaxLength === "") {
        setMaxLengthError("Please enter Max value");
        flag = false;
      } else {
        setMaxLengthError("");
      }
    }else{ setMinLengthError(""); setMaxLengthError(""); }

    if(SubQustions === true){
      if (QuestionType === "") {
        setQuestionTypeError("Please select question option type");
        flag = false;
      } else {
        setQuestionTypeError("");
      }

      if(QuestionType !== ""){
        if (QuestionTitle === "") {
          setQuestionTitleError("Please enter question title");
          flag = false;
        } else {
          setQuestionTitleError("");
        }
      }else{ setQuestionTitleError(""); }

      if(QuestionType !== ""){
        if (questionInputType === "") {
          setquestionInputTypeError("Please select question input type ");
          flag = false;
        } else {
          setquestionInputTypeError("");
        }
      }else{ setquestionInputTypeError(""); }


    }else{ setQuestionTypeError(""); setQuestionTitleError(""); }

    var error_cat_flag = 0;
    if(ApplayTickets === 2){
      for (var j = 0; j < TicketsData.length; j++) {
        if (TicketsData[j]['checked'] === true) {
          error_cat_flag = 1;
        }
      }
      //console.log(error_cat_flag);
      if (error_cat_flag === 0) {
        setSelectedTicketError("Please select at least one race category");
        flag = false;
      } else {
        setSelectedTicketError("");
      }
    }else{
       setSelectedTicketError("");
    }

    //--------- new added
    if(HintType === '2' || HintType === 2){
    
      const fileExtension = UploadHintfile ? UploadHintfile.name.split(".").at(-1) : '';
      const allowedFileTypes = ["jpg","jpeg","png"];
  
      if (UploadHintfile !== '') {
        // console.log('ss');
          if (!allowedFileTypes.includes(fileExtension)) {
              setUploadfileError("File does not support. You must use jpg, jpeg, png");
              setfiledivFlag(1)
              flag = false;
          } else if (UploadHintfile.size > 2e6) {
              setUploadfileError("Please upload a file smaller than 2 MB");
              setfiledivFlag(1)
              flag = false;
          } else {
              setUploadfileError("");
              setfiledivFlag(0)
          }
      }
    }else{
      setUploadfileError("");
      setfiledivFlag(0)
    }
    
    //----------- sub quetions
    if(SubQueHintType === '2' || SubQueHintType === 2){
    
      const fileExtension = UploadHintfile1 ? UploadHintfile1.name.split(".").at(-1) : '';
      const allowedFileTypes = ["jpg","jpeg","png"];
  
      if (UploadHintfile1 !== '') {
        // console.log('ss');
          if (!allowedFileTypes.includes(fileExtension)) {
              setUploadfileError1("File does not support. You must use jpg, jpeg, png");
              flag = false;
          } else if (UploadHintfile1.size > 2e6) {
              setUploadfileError1("Please upload a file smaller than 2 MB");
              flag = false;
          } else {
              setUploadfileError1("");
          }
      }
    }else{
      setUploadfileError("");
    }

    return flag;
  };

  const handleSubmit1 = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {

      setLoader(true);
      // const payload = {
      //   event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
      //   user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
      //   general_form_id: props.questionId,
      //   sub_question_id: props.PreviousQueId,
      //   form_name: FormName,
      //   question_mandatory_status: QuestionStatus,
      //   display_label_name : DisplayName,
      //   limit_length_check : LimitLengthCheck,
      //   min_length : MinLength,
      //   max_length : MaxLength,
      //   field_mapping : FeildMapping,
      //   sub_question_flag : SubQustions === true ? 1 : 0,
      //   question_type : QuestionType,
      //   sub_question_title : QuestionTitle,
      //   hint_type: HintType,
      //   sub_question_array : rows,
      //   sub_question_mandatory_status : SubQuestionStatus,
      //   sub_question_form_type : questionInputType, // props.isSubquestion === 1 ? 'text' : 'select'
      //   sub_question_price_flag : PriceAddedFlag === true ? 1 : 0,
      //   sub_question_count_flag : CountAddedFlag === true ? 1 : 0,
      //   sub_question_other_amount : AllowOtherAmtFlag === true ? 1 : 0,
      //   parent_general_form_id: props.MainGeneralFormId,
      //   apply_ticket: ApplayTickets,
      //   main_question_hint: MainQuestionHint,
      //   ticket_selected_data: TicketsData ? JSON.stringify(TicketsData) : [],
      //   sub_que_hint_type: SubQueHintType,
      //   question_hint: questionHint,
      //   upload_sub_hint_file: UploadHintfile1,
        
      // };
      // console.log(payload);
      const formData = new FormData();
      formData.append("event_id", localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0);
      formData.append("user_id", localStorage.getItem("ID") ? localStorage.getItem("ID") : 0);
      formData.append("general_form_id", props.questionId);
      formData.append("sub_question_id", props.PreviousQueId);
      formData.append("form_name", FormName);
      formData.append("question_mandatory_status", QuestionStatus);
      formData.append("display_label_name", DisplayName);
      formData.append("limit_length_check", LimitLengthCheck);
      formData.append("min_length", MinLength);
      formData.append("max_length", MaxLength);
      formData.append("field_mapping", FeildMapping);
      formData.append("sub_question_flag", SubQustions === true ? 1 : 0);
      formData.append("question_type", QuestionType);
      formData.append("sub_question_title", QuestionTitle);
      formData.append("hint_type", HintType);
      formData.append("main_question_hint", MainQuestionHint);
      formData.append("upload_hint_file", UploadHintfile);
      formData.append("upload_file_name", UploadedFileName);
      formData.append("sub_question_array", JSON.stringify(rows)); 
      formData.append("sub_question_mandatory_status", SubQuestionStatus);
      formData.append("sub_question_form_type", questionInputType);
      formData.append("sub_question_price_flag", PriceAddedFlag === true ? 1 : 0);
      formData.append("sub_question_count_flag", CountAddedFlag === true ? 1 : 0);
      formData.append("sub_question_other_amount", AllowOtherAmtFlag === true ? 1 : 0);
      formData.append("parent_general_form_id", props.MainGeneralFormId ? props.MainGeneralFormId : 0);
      formData.append("apply_ticket", ApplayTickets);
      formData.append("ticket_selected_data", TicketsData ? JSON.stringify(TicketsData) : []);
      
      formData.append("sub_que_hint_type", SubQueHintType);
      formData.append("question_hint", questionHint);
      formData.append("upload_sub_hint_file", UploadHintfile1);
      formData.append("upload_sub_file_name", UploadedFileName1);

      axios.post("AddGeneralFormQuestions", formData)
        .then((response) => {
          setLoader(false);
         // console.log(response);
          if (response.status === 200) {
           
            if(props.subquesFlag === 1){
              props.setOpen1(false);
              props.setCloseFlag(1);
            }else{
              props.setEventResponceTabs(props.EventResponceTabs);
              props.setAddGeneralQuestions(false);
            }
          
            toast.success(response.data.message)
          }
        })
        .catch(() => {
          setLoader(false);
          console.log('error');
          //props.setOpenTree(false);
        });
      }
  };

  const handleSubQuestion = (index, value, flag) => {
    //console.log(index)
    setoptionsArray((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[index] = value;
      
      if(flag === 1){
        updatedAnswers.splice(index, 1);
        setSubQustionsArray(updatedAnswers);
      }else{
        if (value.trim() === '') {
          updatedAnswers.splice(index, 1);
          setSubQustionsArray(updatedAnswers);
        }
      }

      if (index === updatedAnswers.length - 1 && flag !== 1) { //&& value.trim() !== ''
        updatedAnswers.push('');
        setSubQustionsArray(updatedAnswers);
      }
      setSubQustionsArray(updatedAnswers);
      return updatedAnswers;
    });
  }
  // console.log(props.TicketsDetails);
  //------------------------------------------------------------------
  
  useEffect(() => {
    if(questionInputType === 'select' || questionInputType === 'checkbox'){
      setRows([...rows, {id: Date.now(), label: '', price: '', count: '' }]);
    }
  },  [questionInputType]);

  const addRow = () => {
    setRows([...rows, {id: Date.now(), label: '', price: '', count: '' }]);
  };

  const removeRow = (id) => {
    setRows(rows.filter(row => row.id !== id));
  };

  const handleInputChange = (id, column, value) => {
    setRows(rows.map(row => {
      if (row.id === id) {
        return { ...row, [column]: value };
      }
      return row;
    }));
  };

  useEffect(() => {
    const payload = {
      form_name: '',
      form_edit_id: 0,
      form_action_flag: 'form_details',
      form_flag: ''
    };
    axios.post("FormCommonDetails", payload)
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setFormDetailsAll(response.data.data.form_details);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  
  }, [])
  //console.log(props.FieldMappingDetails);

  const uplodFile = (e) => {
    let file = e.target.files[0];
    setUploadHintfile(file);
    setUploadedFileName(file.name);
  }

  const questionHintCheck = (e) => {
  // console.log(e.target.value);
    const Type = e.target.value;
    if(Type === '1' || Type === 1){
      setMainQuestionHint("");
    }else{
      setMainQuestionHint(props.MainQuestionHint !== '' ? props.MainQuestionHint : '');
    }
  }
  
  //----------- sub question upload file
  const uplodFile1 = (e) => {
    let file = e.target.files[0];
    setUploadHintfile1(file);
    setUploadedFileName1(file.name);
  }
  
  return (
    <>
    
      <div className="modal is-visible w-100" data-animation="slideInUp" >
        <div className="modal-dialog quickview__main--wrapper">
         
        <form method="post" onSubmit={handleSubmit1} encType="multipart/form-data" >
          <div className="quickview__inner">
            <div className="row">

              <div className="login__section">

               
                  <div className="login__section--inner" >
                    <div className="p-5">
                      <div className="account__login--header mb-25">
                        <h3 className="account__login--header__title mb-10">Questions Details</h3>
                        <p className="account__login--header__desc">These are questions you can ask your attendees at the time of registration</p>
                      </div>
                    
                      <div className="account__login--inner">
                       
                        <label> Question Status </label>
                        
                        <div className="event-visibility hand_cursor mb-5" style={{width: "fit-content"}}>
                          <div className={'visibility gap-3 ' + QuestionStatusMClass} onClick={() => { setQuestionStatus(1); setQuestionStatusMClass('active'); setQuestionStatusOpClass(''); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/></svg>
                            Mandatory
                          </div>
                          <div className={'visibility gap-3 ' + QuestionStatusOpClass} onClick={() => { setQuestionStatus(0); setQuestionStatusMClass(''); setQuestionStatusOpClass('active'); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/></svg>
                            Optional
                          </div>
                        </div>

                        <div className="my-3">
                          <div className="form-floating mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={DisplayName}
                              onChange={(e) => { setDisplayName(e.target.value); }}
                            />
                            <label>Display Name<span className="checkout__input--label__star">*</span></label>
                            <small className="text-danger">{DisplayNameError}</small>
                          </div>
                        </div>
                        
                        <div className="row align-items-center mb-3">
                          <div className="col-sm-12">
                            <div className="form-floating">
                              <select className="form-control" name="question_type" value={FormName} onChange={(e) => { setFormName(e.target.value); }}> 
                                  {/* <option value="">-- Select --</option> */}
                                  <option value="999999">Do Not Have Form</option>
                                  {
                                      (FormDetailsAll && FormDetailsAll.length > 0) ?
                                            FormDetailsAll.map((item,index) => {
                                              // console.log(item);
                                              return (
                                                <option value={item.id} key={index}>{item.form_name}</option>
                                              )
                                          }) : null
                                  }
                              </select> 
                              <label className="form-check-label">Choose Form</label>
                            
                            </div>
                          </div>
                        </div>
                        
                        <div className="row align-items-center mb-3">
                          <div className="col-sm-12">
                            <div className="form-floating">
                              
                            <select className="form-control" name="filed_mapping" value={FeildMapping}  onChange={(e) => { setFeildMapping(e.target.value); }}> 
                              <option value="">-- Select --</option>
                            
                              {
                                  (props.FieldMappingDetails && props.FieldMappingDetails.length > 0) ?
                                        props.FieldMappingDetails.map((item,index) => {
                                          // console.log(item);
                                          return (
                                            <option value={item.COLUMN_NAME} key={index}>{item.COLUMN_NAME}</option>
                                          )
                                      }) : null
                              }

                             </select>
                              <label className="form-check-label">Field Mapping</label>
                            
                            </div>
                          </div>
                        </div>
                      
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-floating ">
                                <select className="form-control" name="question_type" value={HintType} onChange={(e) => { setHintType(e.target.value); setUploadfileError(""); questionHintCheck(e); }}> 
                                  <option value="1">Text</option>
                                  <option value="2">Image</option>
                                </select>
                                <label>Hint Type<span className="checkout__input--label__star">*</span></label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            { HintType === 1 || HintType === '1' ?
                              <div className="form-floating mb-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={MainQuestionHint}
                                  onChange={(e) => { setMainQuestionHint(e.target.value); }}
                                />
                                <label>Question Hint</label>
                              </div>
                            :
                              <div className="form-floating mb-0">
                                <input type="file" className="form-control" onChange={uplodFile} />
                                <label>Question Hint (Image)</label>
                                <small className="text-danger">{UploadfileError}</small>
                              </div>
                            }

                          </div>
                        </div>
                          
                        <hr />
                        
                        <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mt-4">

                            {/* <h2 className="section__header--title h3"> Applay Tickets</h2> */}
                              <div className="ebi">
                                <div className="event-visibility hand_cursor">
                                  <div className={'visibility gap-1 ' + AllTicktes} onClick={() => { setApplayTickets(1); setAllTicktes('active'); setSelectedTicktes(''); }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={20}
                                      viewBox="0 -960 960 960"
                                      width={20}
                                    >
                                      <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                                    </svg>
                                    All Race Categories
                                  </div>

                                  <div className={'visibility gap-1 ' + SelectedTicktes} onClick={() => { setApplayTickets(2); setAllTicktes(''); setSelectedTicktes('active'); }} > 
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={20}
                                      viewBox="0 -960 960 960"
                                      width={20}
                                    >
                                      <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                    </svg>
                                    Selected Race Categories
                                  </div>

                                </div>
                              </div>
                        </div>
                     
                      <div className="row">     
                      {TicketsData && TicketsData.length > 0 ?
                        TicketsData.map((item, index) => (
                          <>
                            {ApplayTickets === 2 ?
                              <div className="col-sm-6 my-3" key={index+'11'}>
                                <div className="plans">
                                  <label className="plan basic-plan">
                                    <input name="1"
                                      type="checkbox"
                                      className="btn-check"
                                      value={item.checked}
                                      defaultChecked={item.checked}
                                      onClick={(evnt) => { handleTicketChange(index, evnt); }}
                                    />
                                    <div className="plan-content">
                                      <div className="plan-details" for='btn-check-outlined-1'>
                                        <span className="h3 m-0"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z"/></svg> {item.ticket_name}</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            : null }
                          </>
                        ))
                      : 
                      
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0" >
                          <div className="w-100 not-avilable-card p-3 pb-4 d-flex flex-column align-items-center">
                              <img className=""  src={`${noCoupons}`} alt="" style={{ width: "130px" }} />
                              <p class="section__header--title h4 mb-3">No race categories avilable</p>
                              <p class="mb-4">No race categories to apply discounts. Please create an available race categories first</p>
                          </div>
                        </div>
                      } 

                      <small className="text-danger">{SelectedTicketError}</small>
                      </div>  

                       <hr />    

                       {props.questionType == 'text' ?
                        <div className="row align-items-center mb-5 mt-3">
                         
                           <div className="col-sm-4">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                value={LimitLengthCheck} onChange={(e) => { setLimitLengthCheck(!LimitLengthCheck) }}
                              />
                              <label className="form-check-label">Limit Length </label>
                            </div>
                          </div>

                            <div className={LimitLengthCheck === true ? 'col-sm-4' : 'col-sm-4 invisible'}> 
                           
                              <div className="form-floating mt-3 mb-0">
                                <input className="form-control" placeholder="Min Length" type="text"
                                  name="min_length" value={MinLength} autoComplete="off" onChange={(e) => { setMinLength(e.target.value); }} />
                                <label>Min Length<span className="checkout__input--label__star">*</span></label>
                                <small className="text-danger">{MinLengthError}</small>
                              </div>
                             
                            </div>                       
                          
                            <div className={LimitLengthCheck === true ? 'col-sm-4' : 'col-sm-4 invisible'}>
                           
                              <div className="form-floating mt-3 mb-0">
                                <input className="form-control" placeholder="Max Length" type="text"
                                  name="max_length" value={MaxLength} autoComplete="off" onChange={(e) => { setMaxLength(e.target.value); }} />
                                <label>Max Length<span className="checkout__input--label__star">*</span></label>
                                <small className="text-danger">{MaxLengthError}</small>
                              </div>
                            
                            </div>
                         
                        </div> 
                        : '' }
                        
                        {/* Add Subqustions */}
                        
                        {props.questionType == 'radio' || props.questionType == 'select' || props.questionType == 'select_amount' ?
                          <div className="row align-items-center mb-5 mt-3">
                            <div className="col-sm-12">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  value={SubQustions} onChange={(e) => { setSubQustions(!SubQustions) }}
                                />
                                <label className="form-check-label">Add Subqustions</label>
                              </div>
                            </div>

                           {SubQustions === true ? 
                            
                              <div className="row align-items-center mt-5 justify-content-start">
                                  <div className="col-sm-12">
                                    <div className="form-floating">
                                    
                                      <select className="form-control" name="filed_mapping" value={QuestionType}  onChange={(e) => { setQuestionType(e.target.value); }}> 
                                        <option value="">-- Select --</option>
                                          {props.questionOptionArray.map((item) => {
                                              return (
                                              <option key={item.id} value={item.id}>
                                                  {item.label}
                                              </option>
                                              );
                                          })}
                                      </select>
                                      <label className="form-check-label">Question Option Type <span className="checkout__input--label__star">*</span></label>
                                      <small className="text-danger">{QuestionTypeError}</small>
                                    </div>
                                  </div>
                                  
                                  {QuestionType !== '' ?
                                    <div className="col-sm-12">
                                      <div className="form-floating mt-3 mb-0">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={QuestionTitle}
                                          onChange={(e) => { setQuestionTitle(e.target.value); }}
                                        />
                                        <label>Question Title <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{QuestionTitleError}</small>
                                      </div>
                                    </div>
                                  : ''}

                                  {QuestionType !== '' ?
                                    <div className="col-sm-12 mt-3 mb-0">
                                      {/* <div className="form-floating my-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={questionHint}
                                          onChange={(e) => { setquestionHint(e.target.value); }}
                                        />
                                        <label>Sub Question Hint</label>
                                      </div> */}
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <div className="form-floating ">
                                              <select className="form-control" name="question_type" value={SubQueHintType} onChange={(e) => { setSubQueHintType(e.target.value); setUploadfileError1(""); setquestionHint("")  }}> 
                                                <option value="1">Text</option>
                                                <option value="2">Image</option>
                                              </select>
                                              <label>Hint Type<span className="checkout__input--label__star">*</span></label>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          { SubQueHintType === 1 || SubQueHintType === '1' ?
                                            <div className="form-floating mb-0">
                                             <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={questionHint}
                                                onChange={(e) => { setquestionHint(e.target.value); }}
                                              />
                                              <label>Sub Question Hint</label>
                                            </div>
                                          :
                                            <div className="form-floating mb-0">
                                              <input type="file" className="form-control" onChange={uplodFile1} />
                                              <label>Question Hint (Image)</label>
                                              <small className="text-danger">{UploadfileError1}</small>
                                            </div>
                                          }

                                        </div>
                                      </div>
                                                    
                                    </div>
                                  : ''}
                                  
                                  {QuestionType !== '' ?
                                  <div className="col-sm-12 mb-2 mt-3">
                                    <div className="form-floating">
                                        <select className="form-control" name="question_type" value={questionInputType} onChange={(e) => { setquestionInputType(e.target.value); }}> 
                                            <option value="">-- Select --</option>
                                            <option value="text">Text</option>
                                            <option value="email">Email</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="amount">Amount</option>
                                            <option value="textarea">Textarea</option>
                                            <option value="checkbox">Checkboxes</option>
                                            <option value="radio">Radio</option>
                                            <option value="date">Date</option>
                                            <option value="time">Time</option>
                                            <option value="file">File</option>
                                            <option value="select">Select (Dropdown)</option>
                                            {/* <option value="select_amount">Select Amount (Dropdown)</option> */}
                                        </select>
                                      <label>Question Input Type<span className="checkout__input--label__star">*</span></label>
                                    </div>
                                    <small className="text-danger">{questionInputTypeError}</small>
                                  </div>
                                  : ''}

                                  {QuestionType !== '' && questionInputType !== '' && (questionInputType === 'checkbox' || questionInputType === 'select' || questionInputType === 'radio') ?
                                    <div className="row align-items-center my-3">
                                      <div className="col-sm-4">
                                        <div className="form-check form-switch d-flex align-items-center">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            value={PriceAddedFlag} onChange={(e) => { setPriceAddedFlag(!PriceAddedFlag) }}
                                          />
                                          <label className="form-check-label">Price</label>
                                        </div>
                                      </div>

                                      <div className="col-sm-8">
                                        <div className="form-check form-switch d-flex align-items-center">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            value={CountAddedFlag} onChange={(e) => { setCountAddedFlag(!CountAddedFlag) }}
                                          />
                                          <label className="form-check-label">Maximum Count Limit</label>
                                        </div>
                                      </div>
                                  </div>
                                  : null }

                                  {QuestionType !== '' && questionInputType !== '' && (questionInputType === 'checkbox' || questionInputType === 'select' || questionInputType === 'radio') ?
                                    //  <div className="row">
                                    //   <div className="col-sm-12 my-3">
                                          <table className="ch-box">
                                            <thead>
                                              <tr className="ch-header">
                                                <th className="text-center py-2">Label</th>
                                                {PriceAddedFlag === true ?
                                                  <th className="text-center py-2">Price</th>
                                                : null }
                                               
                                                {CountAddedFlag === true ?
                                                  <th className="text-center py-2">Count</th>
                                                : null }
                                                
                                                <th className="text-center p-3">Action</th>
                            
                                              </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => 
                                                
                                                ( 
                                                 
                                                  <tr key={row.id}>  
                                                    <td>
                                                      <input
                                                        type="text"
                                                        defaultValue={row.label}
                                                        onChange={(e) => { handleInputChange(row.id, 'label', e.target.value) }}
                                                      />
                                                    </td>
                                                   
                                                    {PriceAddedFlag === true ?
                                                      <td>
                                                        <input
                                                          type="text"
                                                          defaultValue={row.price}
                                                          onChange={(e) => handleInputChange(row.id, 'price', e.target.value.replace(/\D/g, ''))}
                                                        />
                                                      </td>
                                                    : null }
                                                    
                                                    {CountAddedFlag === true ?
                                                      <td>
                                                        <input
                                                          type="text"
                                                          defaultValue={row.count}
                                                          onChange={(e) => handleInputChange(row.id, 'count', e.target.value.replace(/\D/g, ''))}
                                                        />
                                                      </td>
                                                    : null }
                                                    
                                                    <td>
                                                      {index !== 0 ?
                                        
                                                        <svg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 -960 960 960" width={20} onClick={() => removeRow(row.id)} >
                                                          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                                                        </svg>
                                                        
                                                      : 
                                                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={addRow}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                                                      }
                                                    </td>
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </table>
                                    //     </div>
                                    //  </div>
                                  : QuestionType !== '' ?
                                  
                                  <div className="col-sm-2 d-flex align-items-center">
                                    <div className="event-visibility hand_cursor mt-2" style={{width: "fit-content"}}>
                                        <div className={'visibility gap-3 ' + SubQuestionStatusMClass} onClick={() => { setSubQuestionStatus(1); setSubQuestionStatusMClass('active'); setSubQuestionStatusOpClass(''); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/></svg>
                                          Mandatory
                                        </div>
                                        <div className={'visibility gap-3 ' + SubQuestionStatusOpClass} onClick={() => { setSubQuestionStatus(0); setSubQuestionStatusMClass(''); setSubQuestionStatusOpClass('active'); }}>
                                          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/></svg>
                                          Optional
                                        </div>
                                    </div>
                                  </div>

                                  : '' }

                                {QuestionType !== '' && questionInputType !== '' && (questionInputType === 'select') ?
                                
                                  <div className="aoa mt-4">
                                    <div className="card px-4 py-3">
                                        <div className="row">
                                      <div className="col-sm-6">
                                        
                                          <div className="form-check form-switch w-100">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckDefault"
                                              value={AllowOtherAmtFlag} onChange={(e) => { setAllowOtherAmtFlag(!AllowOtherAmtFlag) }}
                                            />
                                            <label className="form-check-label">Allow other amount</label>
                                          </div>
                                       
                                      </div>
                                      
                                      {AllowOtherAmtFlag === true ?
                                        <div className="col-sm-6">
                                           <input type="text" className="form-control" placeholder="Other Amount" readOnly/>
                                        </div>
                                      : null }
                                      </div>  
                                    </div>
                                  </div>

                                : null }


                              </div>
                           
                            : '' }
                          
                          </div>
                        : '' }

                        
                        {/* ----- End ----- */}

                        <div className="d-flex gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                           props.subquesFlag === 1 ? props.setOpen1(false) : props.setOpen(false); }}>Close</button>

                          <input type="submit" name="command" value="Save" className="account__login--btn primary__btn mt-2" disabled={ButtonDisable === true ? true : false} />
                          
                        </div>
                   
                      </div>
                    </div>
                  </div>
               

              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormQuestionPopup;
