import React, { useState, useEffect } from 'react';
import axios from "./axios";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Wrapper from './Wrapper';
import EventSection from './EventSection';
import NewsLetter from './NewsLetter';
import CategorySection from './CategorySection';
import TestimonialBannerShippingSection from './TestimonialBannerShippingSection';
import Banner from './Banner';
import LoaderImg from './img/logo/running.gif'
import UpcomingEvent from './UpcomingEvent'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegistrationEventSection from './RegistrationEventSection';
import { token } from "./features/TokenSlice";
import { useDispatch } from "react-redux";

const Home = (props) => {
  const { reset_password } = useParams();
  const { reset_password_token } = useParams();

  const { login_as_org } = useParams();
  const { user_email } = useParams();
  const { user_pass } = useParams();

  // console.log(user_email,user_pass);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [add, setAdd] = useState([]);
  const [EventsData, setEventsData] = useState([]);
  // const [CityData, setCityData] = useState([]);
  const [BannerImages, setBannerImages] = useState([]);

  const UserAddress = localStorage.getItem("UserAddress");

  const [HeaderInputCity, setHeaderInputCity] = useState('');
  const [CityName, setCityName] = useState(localStorage.getItem("City") ? localStorage.getItem("City") : "");
  // console.log(CityName);
  const [CountryName, setCountryName] = useState('');

  const [CountrySearchFlag, setCountrySearchFlag] = useState(false);
  const [CitySearchFlag, setCitySearchFlag] = useState(false);

  const [SearchFlag, setSearchFlag] = useState('');
  const [EventSearch, setEventSearch] = useState('');
  const [EventNameSearch, setEventNameSearch] = useState('');
  const [SearchEventPageFlag, setSearchEventPageFlag] = useState(localStorage.getItem("SearchEventPageFlag") ? localStorage.getItem("SearchEventPageFlag") : "");

  const [Loader, setLoader] = useState(false);

  const [FewSuggestionFlag, setFewSuggestionFlag] = useState(0);

  const [EventViewFlag, setEventViewFlag] = useState(localStorage.getItem("EventViewFlag"));
  // const [EventDetailsId, setEventDetailsId] = useState('');

  const [LoginWishlist, setLoginWishlist] = useState(false);
  const [CategoryFilter, setCategoryFilter] = useState('');
  const [QuicklyFilter, setQuicklyFilter] = useState('');
  const [BannerBackgroundImg, setBannerBackgroundImg] = useState('');
  const [UpcomingEventsData, setUpcomingEventsData] = useState([]);
  const [RegistrationEventData, setRegistrationEventData] = useState([]);
  
  const [LoginFlag, setLoginFlag] = useState(false);

  //FROM SEARCH EVENTS PAGE VIEW CLICK  && FROM REGISTER NOW PAGE && VIEW ORGANIZER PAGE
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state) {
      if (location.state.reset_pwd) {
        console.log("here")
      }
    }
  }, [location.state])

  //FROM SEARCH EVENTS PAGE WISHLIST CLICK
  // const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.login_popup) {
        setLoginWishlist(true);
        //  navigate('/events')
      }
    }
  }, [location.state])
 
  localStorage.removeItem("BookTicketEventId");

  // INITIALLY SHOWS PAGE BLANK ON PRIME
  useEffect(() => {
    if (location.state === 'null' && !CountrySearchFlag && SearchFlag === "") {
      localStorage.removeItem("SearchEventPageFlag");

      const CountryCode = localStorage.getItem("CountryCode") ? localStorage.getItem("CountryCode") : "in";
      const Country = localStorage.getItem("Country") ? localStorage.getItem("Country") : "India";
      const State = localStorage.getItem("StateId") ? localStorage.getItem("StateId") : "";
      const City = localStorage.getItem("City") ? localStorage.getItem("City") : "";

      const payload = {
        country: Country,
        country_code: CountryCode,
        city: City,
        state: State,
        scity: localStorage.getItem("CityId") ? localStorage.getItem("CityId") : "",
        sstate: localStorage.getItem("StateId") ? localStorage.getItem("StateId") : "",
        scountry: localStorage.getItem("CountryId") ? localStorage.getItem("CountryId") : "",
        home_flag: 1
      }
      // console.log(payload);
      axios.post("get_banner_events", payload)
        .then((response) => {
          // console.log(response.data.data.MAX_UPLOAD_FILE_SIZE);
          localStorage.setItem("MAX_UPLOAD_FILE_SIZE", response.data.data.MAX_UPLOAD_FILE_SIZE)
          setFewSuggestionFlag(response.data.FewSuggestionFlag);
          localStorage.setItem("CountryId", response.data.data.CountryId);
          localStorage.setItem("StateId", response.data.data.StateId);
          localStorage.setItem("CityId", response.data.data.CityId);
          // console.log(response.data.data.CityName,Country);
          if (City === '' || City === undefined) {
            setCountryName(Country);
            navigate("/" + CountryCode + "/" + Country);
          } else {
            setCityName(response.data.data.CityName);
            navigate("/" + CountryCode + "/" + response.data.data.CityName);
          }
          setCountrySearchFlag(false);
          setCitySearchFlag(true);
          setEventsData(response.data.data.eventData);
          setRegistrationEventData(response.data.data.RegistrationEventData);
          // setCityData(response.data.data.cityData);
          setBannerImages(response.data.data.BannerImages);
          setBannerBackgroundImg(response.data.data.eventData[0].background_image);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [CountrySearchFlag, SearchFlag, location.state, navigate])

  // GET CURRENT LOCATION 
  useEffect(() => {
    if (UserAddress === null) {
      // console.log("useEffect of GET CURRENT LOCATION");
      setLoader(true);
      navigator.geolocation.getCurrentPosition(pos => {
        const { latitude, longitude } = pos.coords;
        // console.log(latitude, longitude)
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        fetch(url)
          .then(res => res.json())
          .then(data => {
            setAdd(data.address); setCountrySearchFlag(true);
            localStorage.setItem("LocationFlag", 1);
          }
          )
      }, (error) => {
        // console.log(error);
        setAdd({ country_code: "in", country: "India" });
        setCountrySearchFlag(true);
        localStorage.setItem("LocationFlag", 0);
      })
    }
  }, [UserAddress])


  // FIRST API CALL WHILE AUTO LOCATION DETECT
  useEffect(() => {
    if (CountrySearchFlag) {
      // console.log("CountrySearch : ", CountrySearchFlag);
      var address = [];
      address = add;
      // console.log(add);
      if (add) {
        localStorage.setItem("UserAddress", JSON.stringify({ add }));
      }
      else {
        address = JSON.parse(localStorage.getItem("UserAddress"));
      }

      const CountryCode = (address.country_code) ? address.country_code : "in";
      const Country = address.country ? address.country : "India";
      const State = address.state ? address.state : "";
      const City = address.city ? address.city : "";
      // console.log(add);
      //SET NAME OF COUNTRY,STATE AND CITY
      localStorage.setItem("Country", Country);
      localStorage.setItem("CountryCode", CountryCode);
      localStorage.setItem("State", State);
      localStorage.setItem("City", City);

      //SET ID OF COUNTRY,STATE AND CITY
      localStorage.setItem("CountryId", 0);
      localStorage.setItem("StateId", 0);
      localStorage.setItem("CityId", 0);

      const payload = {
        country: Country,
        country_code: CountryCode,
        city: City,
        state: State,
        // filter: CategoryFilter,
        scity: localStorage.getItem("CityId"),
        sstate: localStorage.getItem("StateId"),
        scountry: localStorage.getItem("CountryId"),
        home_flag: 1
      }
      // console.log(payload);
      axios.post("get_banner_events", payload)
        .then((response) => {
          // console.log(response.data.data.BannerImages);
          localStorage.setItem("MAX_UPLOAD_FILE_SIZE", response.data.data.MAX_UPLOAD_FILE_SIZE)
          setFewSuggestionFlag(response.data.FewSuggestionFlag);
          localStorage.setItem("CountryId", response.data.data.CountryId);
          localStorage.setItem("StateId", response.data.data.StateId);
          localStorage.setItem("CityId", response.data.data.CityId);
          // console.log(response.data.data.CityName,Country);
          if (City === '' || City === undefined) {
            // console.log("if");
            setCountryName(Country);
            navigate("/" + CountryCode + "/" + Country);
          } else {
            // console.log("else");
            setCityName(response.data.data.CityName);
            navigate("/" + CountryCode + "/" + response.data.data.CityName);
          }
          // console.log(response.data.data);
          setCountrySearchFlag(false);
          setCitySearchFlag(true);
          setEventsData(response.data.data.eventData);
          setRegistrationEventData(response.data.data.RegistrationEventData);
          setUpcomingEventsData(response.data.data.UpcomingEventData);
          // setCityData(response.data.data.cityData);
          setBannerImages(response.data.data.BannerImages);
          setBannerBackgroundImg(response.data.data.eventData[0].background_image);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [add, navigate, CountrySearchFlag])

  // console.log(SearchFlag);
  // GET CITY WISE BANNER AND EVENTS ETC.
  // useEffect(() => {
  //   if (SearchFlag === 'HeaderInputCity') {
  //     //console.log("CitySearch call : ", SearchFlag);
  //     const payload = {
  //       // city: HeaderInputCity,
  //       city: HeaderInputCity ? HeaderInputCity : localStorage.getItem("CityId"),

  //       search_flag: SearchFlag,
  //       scity: localStorage.getItem("CityId"),
  //       state: localStorage.getItem("StateId"),
  //       country: localStorage.getItem("CountryId"),
  //       home_flag: 1
  //     }
  //     // console.log(payload);
  //     axios.post("get_data_location_wise", payload)
  //       .then((response) => {
  //         //console.log(response.data.data);
  //         localStorage.setItem("MAX_UPLOAD_FILE_SIZE", response.data.data.MAX_UPLOAD_FILE_SIZE)

  //         setFewSuggestionFlag(response.data.FewSuggestionFlag);

  //         const CityName = response.data.data.CityName;
  //         const CountryCode = localStorage.getItem("CountryCode");
  //         const Country = localStorage.getItem("Country");
  //         // console.log(CityName,CountryCode,Country,HeaderInputCity);
  //         if (CityName !== "")
  //           setCityName(CityName);
  //         else
  //           setCityName(Country);

  //         localStorage.setItem("City", CityName);
  //         localStorage.setItem("CountryId", response.data.data.CountryId);
  //         localStorage.setItem("StateId", response.data.data.StateId);
  //         localStorage.setItem("CityId", response.data.data.CityId);
  //         // console.log(CityName);
  //         if (HeaderInputCity !== '' && CityName !== '') navigate("/" + CountryCode + "/" + CityName);
  //         setLoader(false);
  //         setSearchFlag('');
  //         setEventsData(response.data.data.eventData);
  //         setUpcomingEventsData(response.data.data.UpcomingEventData);
  //         setRegistrationEventData(response.data.data.RegistrationEventData);
  //         // setCityData(response.data.data.cityData);
  //         setBannerImages(response.data.data.BannerImages);
  //         setBannerBackgroundImg(response.data.data.eventData[0].background_image);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   }
  // }, [navigate, SearchFlag, HeaderInputCity])
  // console.log(SearchFlag);
  useEffect(() => {
    if (SearchFlag === 'HeaderInputCity') {
      //console.log("CitySearch call : ", SearchFlag);
      const payload = {
        // city: HeaderInputCity,
        city: HeaderInputCity ? HeaderInputCity : localStorage.getItem("CityId"),

        search_flag: SearchFlag,
        scity: localStorage.getItem("CityId"),
        state: localStorage.getItem("StateId"),
        country: localStorage.getItem("CountryId"),
        home_flag: 1
      }
      // console.log(payload);
      axios.post("get_data_location_wise", payload)
        .then((response) => {
          
          localStorage.setItem("MAX_UPLOAD_FILE_SIZE", response.data.data.MAX_UPLOAD_FILE_SIZE)

          setFewSuggestionFlag(response.data.FewSuggestionFlag);

          const CityName = response.data.data.CityName;
          const CountryCode = localStorage.getItem("CountryCode");
          const Country = localStorage.getItem("Country");
          // console.log(CityName,CountryCode,Country,HeaderInputCity);
          if (CityName !== "")
            setCityName(CityName);
          else
            setCityName(Country);

          localStorage.setItem("City", CityName);
          localStorage.setItem("CountryId", response.data.data.CountryId);
          localStorage.setItem("StateId", response.data.data.StateId);
          localStorage.setItem("CityId", response.data.data.CityId);
          // console.log(CityName);
          if (HeaderInputCity !== '' && CityName !== '') navigate("/" + CountryCode + "/" + CityName);
          setLoader(false);
          setSearchFlag('');
          setEventsData(response.data.data.eventData);
          setUpcomingEventsData(response.data.data.UpcomingEventData);
          setRegistrationEventData(response.data.data.RegistrationEventData);
          // setCityData(response.data.data.cityData);
          setBannerImages(response.data.data.BannerImages);
          setBannerBackgroundImg(response.data.data.eventData[0].background_image);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [navigate, HeaderInputCity, SearchFlag])
  //console.log(RegistrationEventData);
  // ------------------------------------------------------------------------------------------------
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll event and toggle button visibility
  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to handle smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Add scroll event listener when component mounts
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //-------------- new added for login as Organiser ,
  useEffect(() => {
    if (login_as_org && login_as_org === '1') {
        //--------------- Logout privious user
        localStorage.removeItem("Token");
        localStorage.removeItem("Name");
        localStorage.removeItem("Modules");
        localStorage.removeItem("Id");
        localStorage.removeItem("booking_pay_id");
        localStorage.removeItem("RacesToken");
        localStorage.removeItem("BookTicketEventId");
        dispatch(token(""));
        setLoginFlag(true);  
    }
  }, [login_as_org])
  
  console.log('login_as_org'+login_as_org);
  console.log('LoginFlag'+LoginFlag);
  useEffect(() => {
    if (LoginFlag) {

      const payload = {
        "Email": user_email,
        "Password": user_pass,
        "LoginAsOrganiser": login_as_org
      }
      console.log(payload)
      axios.post("login",payload)
      .then((response) => {
        console.log(response)
        if (response.data.status === 200) {
          // console.log(response.data.user_id);
          if (parseInt(response.data.validate) === 0) {
            // console.log("validate is 0");
           // props.setMsgFlag("Login");
            localStorage.setItem(
              "RacesToken",
              JSON.stringify({ token: response.data.data.token })
            );

            localStorage.setItem("ID", response.data.data.userData.id)
            localStorage.setItem("EMAIL", response.data.data.userData.email)
            localStorage.setItem("MOBILE", response.data.data.userData.mobile)
            localStorage.setItem("SuperAdmin", response.data.data.userData.type)
            localStorage.setItem("Company_info", response.data.data.company_info_flag)

            if(response.data.data.OrgUserAccessModules){
              localStorage.setItem("OrgUserAccessModules", JSON.stringify(response.data.data.OrgUserAccessModules));
              localStorage.setItem("LoginAsOrganiser", response.data.data.userData.organizer_user)
            } 
            localStorage.setItem("Role_Name", response.data.data.role_name);
            localStorage.setItem("Access_Right_Flag", response.data.data.AccessRightFlag);

            dispatch(token({ token: response.data.data.token }));

            toast.success(response.message);
              // props.setOpen(false);
              // props.setLoginWishlist(false);
              // props.setWelcome(true);
              // if (props.Login)
              //   props.setLogin(false)
            } else {
            // console.log("validate is 1")
            localStorage.setItem("ID", response.data.user_id)
              // props.setOpen(false)
              // props.setUserVerify(true)
              // if (props.Login)
              //   props.setLogin(false)
              //   props.setLoginWishlist(false);
            }
            setLoginFlag(false);  
        }
      })
      .catch((response) => {
        // console.log(response);
        // if (response.response.data.status === 400) {
        //   setErrFlag(true);
        //   setError(response.response.data.message);
        // }
      });
      
      localStorage.setItem("LoginAsOrganiser", login_as_org);
      
    }
  }, [LoginFlag])

  //------------------- End --------------------------
  

  // console.log(EventDetailsId,EventViewFlag,SearchEventPageFlag);
  return (
    <>
      <ToastContainer theme="colored" />
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

     
        <Header ResetPasswordFlag={reset_password} ResetPasswordToken={reset_password_token} setHeaderInputCity={setHeaderInputCity} setSearchFlag={setSearchFlag} HeaderInputCity={HeaderInputCity} CityName={CityName} CountryName={CountryName} setEventSearch={setEventSearch} setEventNameSearch={setEventNameSearch} SearchFlag={setSearchFlag} setEventsData={setEventsData} setLoginWishlist={setLoginWishlist} LoginWishlist={LoginWishlist} setCountrySearchFlag={setCountrySearchFlag} setEventViewFlag={setEventViewFlag} setSearchEventPageFlag={setSearchEventPageFlag} setQuicklyFilter={setQuicklyFilter} setCategoryFilter={setCategoryFilter} login_as_org={login_as_org} user_email={user_email} />
     

      <main className="main__content_wrapper">

        {/* {EventViewFlag && !SearchEventPageFlag ? <EventDetails EventDetailsId={EventDetailsId} EventViewFlag={EventViewFlag} setEventViewFlag={setEventViewFlag} setCityName={setCityName} setLoginWishlist={setLoginWishlist} setCountrySearchFlag={setCountrySearchFlag} /> : null} */}

        {(!EventViewFlag && !SearchEventPageFlag) ? <Banner BannerImages={BannerImages} BannerBackgroundImg={BannerBackgroundImg} /> : null}

        {(!EventViewFlag && !SearchEventPageFlag) ? <EventSection Events={EventsData} FewSuggestionFlag={FewSuggestionFlag} ResetPasswordFlag={reset_password} ResetPasswordToken={reset_password_token} setHeaderInputCity={setHeaderInputCity} setSearchFlag={setSearchFlag} HeaderInputCity={HeaderInputCity} CityName={CityName} CountryName={CountryName} SearchFlag={setSearchFlag} setEventsData={setEventsData} setEventViewFlag={setEventViewFlag} setLoginWishlist={setLoginWishlist} setCityName={setCityName} setCountrySearchFlag={setCountrySearchFlag} /> : null}

        {(!EventViewFlag && !SearchEventPageFlag) ? <UpcomingEvent UpcomingEvents={UpcomingEventsData} FewSuggestionFlag={FewSuggestionFlag} ResetPasswordFlag={reset_password} ResetPasswordToken={reset_password_token} setHeaderInputCity={setHeaderInputCity} setSearchFlag={setSearchFlag} HeaderInputCity={HeaderInputCity} CityName={CityName} CountryName={CountryName} setEventSearch={setEventSearch} setEventNameSearch={setEventNameSearch} SearchFlag={setSearchFlag} setUpcomingEventsData={setUpcomingEventsData} setEventViewFlag={setEventViewFlag} setLoginWishlist={setLoginWishlist} setCityName={setCityName} setCountrySearchFlag={setCountrySearchFlag} /> : null}

        {(!SearchEventPageFlag && RegistrationEventData && RegistrationEventData.length > 0) ? <RegistrationEventSection RegistrationEvents={RegistrationEventData} FewSuggestionFlag={FewSuggestionFlag} ResetPasswordFlag={reset_password} ResetPasswordToken={reset_password_token} setHeaderInputCity={setHeaderInputCity} setSearchFlag={setSearchFlag} HeaderInputCity={HeaderInputCity} CityName={CityName} CountryName={CountryName} SearchFlag={setSearchFlag} setEventsData={setEventsData} setEventViewFlag={setEventViewFlag} setLoginWishlist={setLoginWishlist} setCityName={setCityName} setCountrySearchFlag={setCountrySearchFlag} /> : null}

        {((!EventViewFlag) && !SearchEventPageFlag) ? <CategorySection QuicklyFilter={QuicklyFilter} setQuicklyFilter={setQuicklyFilter} CategoryFilter={CategoryFilter} setCategoryFilter={setCategoryFilter} setCountrySearchFlag={setCountrySearchFlag} setHeaderInputCity={setHeaderInputCity} setSearchFlag={setSearchFlag} setSearchEventPageFlag={setSearchEventPageFlag} /> : null}

        {/* {!SearchEventPageFlag ? <NewsLetter /> : null} */}

        {(!EventViewFlag && !SearchEventPageFlag) ? <TestimonialBannerShippingSection /> : null}

      </main>

      {/* <Footer setEventViewFlag={setEventViewFlag} setCountrySearchFlag={setCountrySearchFlag} setSearchFlag={setSearchFlag} setEventSearch={setEventSearch} EventNameSearch={EventNameSearch} setEventNameSearch={setEventNameSearch} CategoryFilter={CategoryFilter} setCategoryFilter={setCategoryFilter} QuicklyFilter={QuicklyFilter} setQuicklyFilter={setQuicklyFilter} setSearchEventPageFlag={setSearchEventPageFlag} /> */}
      <Footer />
      <Wrapper />

      {/* Scroll top bar */}
      {isVisible ?
        <button
          aria-label="Scroll to top"
          id="scroll__top"
          className={isVisible ? 'show' : 'hide'}
          onClick={scrollToTop}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </button>
        : null}
    </>
  )
}

export default Home