import HeaderSecond from '../HeaderSecond'
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg';
import registerationsImg from '../../src/img/other/agenda.png';
import { useDownloadExcel } from 'react-export-table-to-excel';
import axios from '../axios';
import Pagination from '../Components/Pagination'
import ViewBookingDetails from './ViewBookingDetails';
import LoaderImg from '../img/logo/running.gif';

const EventDashboardNetSales = () => {
    const navigate = useNavigate();
    const { EventId } = useParams();
    const { CouponUsedFlag } = useParams();

    const location = useLocation();
    const { state } = location;
    // console.log(state);
    const EventName = state ? state.event_name : localStorage.getItem("EventName");
   
    const UserId = state ? state.user_id : 0; // localStorage.getItem("ID")
    const EventBookingId = state ? state.EventBookingId : 0;
    const [ApiCall, setApiCall] = useState(true);
    const [AttendeeData, setAttendeeData] = useState([]);
    const [TicketData, setTicketData] = useState([]);
    const [Ticket, setTicket] = useState('');
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [ViewBookingDetailsFlag, setViewBookingDetailsFlag] = useState(false);
    const [BookingId, setBookingId] = useState('');
    const [BookingDetailId, setBookingDetailId] = useState('');
    const [ConfirmEmailPopup, setConfirmEmailPopup] = useState(false);
    const [AttendeeId, setAttendeeId] = useState('');
    const [AttendeeDetailsExcel, setAttendeeDetailsExcel] = useState('');
    const [RemittanceExcel, setRemittanceExcel] = useState('');
    const [ParticipantName, setParticipantName] = useState("");
    const [RegistrationID, setRegistrationID] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [TransactionStatus, setTransactionStatus] = useState("");
    const [Loader, setLoader] = useState(false);
    
    const [TransactionID, setTransactionID] = useState("");
    const [ConfirmPopup, setConfirmPopup] = useState(false);
    
    const [AtteId, setAtteId] = useState(0);
    const [BookingDetailsId, setBookingDetailsId] = useState(0);
    const [CommunicationDetails, setCommunicationDetails] = useState([]);
   
    const [CommunicationTypeId, setCommunicationTypeId] = useState(1);
    const [DownloadParticipantExcel, setDownloadParticipantExcel] = useState(false);

    const [DownloadExcelFlag, setDownloadExcelFlag] = useState('');
    
    var url = new URL(window.location.href);
    // console.log(url);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 30
    const [TotalRecord, setTotalRecord] = useState("");
    // console.log(page);
    const HandleClear = (e) => {
        e.preventDefault();
        setTicket(''); setFromDate(''); setToDate(''); setParticipantName(''); setRegistrationID('');
        setMobileNumber(''); setEmail(''); setTransactionStatus(''); setTransactionID(''); 
        setApiCall(true);
    }

    const handleBackToHome = () => {
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }
    // console.log(UserId);
    // console.log(EventId,UserId,EventBookingId,Ticket,FromDate,ToDate);
    useEffect(() => {
        if (ApiCall || page) {
            setLoader(true);
            axios.post("get_netsales", {
                "event_id": EventId,
                "user_id": UserId,
                "EventBookingId": EventBookingId,
                "participant_name": ParticipantName,
                "reg_id": RegistrationID,
                "mobile_number": MobileNumber,
                "email": Email,
                "ticket_id": Ticket,
                "from_date": FromDate,
                "to_date": ToDate,
                "TransactionStatus": TransactionStatus,
                "coupon_used_flag": CouponUsedFlag ? CouponUsedFlag : 0,
                "TransactionID": TransactionID,
                "limit": limit,
                "page": page,
            })
                .then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        setLoader(false);
                        setAttendeeData(response.data.data.AttendeeData);
                        setTicketData(response.data.data.TicketData);
                        setAttendeeDetailsExcel(response.data.data.attendee_details_excel);
                        setRemittanceExcel(response.data.data.remittance_details_excel);
                        setTotalRecord(response.data.data.TotalRecord)
                        setApiCall(false);
                    }
                    setApiCall(false)
                })
                .catch((error) => {
                    console.log(error)
                    setLoader(false);
                })
        }
    }, [EventId, ApiCall, UserId, Ticket, FromDate, ToDate, EventBookingId, ParticipantName, RegistrationID, MobileNumber, Email, TransactionStatus, TransactionID, page])
    // console.log(TotalRecord);

    useEffect(() => {
       
        if (DownloadParticipantExcel) {
            setLoader(true);
            const payload = {
                "event_id": EventId,
                "user_id": UserId,
                "EventBookingId": EventBookingId,
                "participant_name": ParticipantName,
                "reg_id": RegistrationID,
                "mobile_number": MobileNumber,
                "email": Email,
                "ticket_id": Ticket,
                "from_date": FromDate,
                "to_date": ToDate,
                "TransactionStatus": TransactionStatus,
                "coupon_used_flag": CouponUsedFlag ? CouponUsedFlag : 0,
                "command": DownloadExcelFlag,
                "TransactionID": TransactionID,
            };
            // console.log(payload);
            axios
                .post("attendee_netsales_excell_data", payload)
                .then((response) => {
                    // console.log(response);
                    if (response.status === 200) {
                        setDownloadParticipantExcel(false);
                        setLoader(false);
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.target = "_blank";
                        if(DownloadExcelFlag === 'revenue'){
                            link.href = response.data.data.remittance_details_excel;
                        }else{
                            link.href = response.data.data.attendee_details_excel;
                        }
                        
                        link.download = '';
                        link.click();
                        setDownloadExcelFlag('');
                    }
                })
                .catch(() => {
                    // console.log('error');
                    setLoader(false);
                });
        }
    }, [DownloadParticipantExcel]);  
   
    const ViewBookingData = (id, booking_details_id) => {
        setBookingId(id);
        setBookingDetailId(booking_details_id)
        setViewBookingDetailsFlag(true);
    }

    var copyText = window.location.origin + process.env.PUBLIC_URL;
    const formattedEventName = EventName.replace(/\s/g, '_');
    const EventLink = copyText + "/e/" + formattedEventName;

    const SendEmailAttendee = () => {
        const payload = {
            'event_id': EventId,
            'attendee_id': AttendeeId,
            'event_url': EventLink,
            'email_type': CommunicationTypeId
        }
        // console.log(payload);
        axios.post('resend_email_to_attendee', payload)
            .then((response) => {
                if (response.data.success === 200) {
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }

                setConfirmEmailPopup(false)
                setAttendeeId('')
                setCommunicationTypeId(1)
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const ChangePaymentStatus = () => {
        // console.log(id, booking_details_id);
        const payload = {
            'event_id': EventId,
            'attendee_id': AtteId,
            'booking_details_id': BookingDetailsId
        }
        // console.log(payload);
        axios.post('change_payment_status', payload)
            .then((response) => {
                // console.log(response.data);
                // if (response.success === 200) {
                    toast.success(response.data.message);
                // }
                //  else {
                //     toast.error(response.data.message);
                // }
                setAtteId(0); setBookingDetailsId(0)
                setApiCall(true);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
            setLoader(true);
            axios.get("CommunicationMasterDetails")
                .then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        setCommunicationDetails(response.data.data); 
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    console.log(error)
                    setLoader(false);
                })
       
    }, [])
    
    // console.log(CommunicationDetails);
    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

    return (
        <>
          {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            
          {
                ConfirmPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">

                                                   <p className="account__login--header__desc">Are you sure to change payment status to "In Progress"?</p>
                                                  
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setConfirmPopup(false);
                                                    }}>Cancel</button>

                                                    <button className="account__login--btn primary__btn mt-2" onClick={(e) => { ChangePaymentStatus(); setConfirmPopup(false);  }}>Yes</button>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                ConfirmEmailPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                
                                                <div className="account__login--header mb-20">
                                                    <p className="account__login--header__desc">Are you sure you want to send this email to attendee?</p>
                                                </div>

                                                <div className="col-sm-12 mb-15">
                                                    <div className="form-floating">
                                                        <select className="form-control" name="category" value={CommunicationTypeId} onChange={(e) => { setCommunicationTypeId(e.target.value); }}>
                                                        {CommunicationDetails.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.subject_name}
                                                            </option>
                                                            );
                                                        })}
                                                        </select>
                                                        <label>Email Type</label>
                                                    </div>
                                                    {/* <small className="text-danger">{CategoryError}</small> */}
                                                </div>

                                              
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setConfirmEmailPopup(false); setAttendeeId('');
                                                    }}>Cancel</button>

                                                    <button className="account__login--btn primary__btn mt-2" onClick={(e) => { SendEmailAttendee(); setConfirmEmailPopup(false); }}>Yes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {ViewBookingDetailsFlag ? <ViewBookingDetails setViewBookingDetailsFlag={setViewBookingDetailsFlag} EventId={EventId} UserId={UserId} BookingId={BookingId} BookingDetailId={BookingDetailId} /> : null}
            <ToastContainer theme="colored" />
            <HeaderSecond />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        {EventName}
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">My Event</span>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">{EventName}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='section--padding  pt-5'>
                    <div className='container'>
                        <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                            <h2 className="section__header--title h3">Participants</h2>
                            <div className="event-visibility hand_cursor" onClick={(e) => { navigate('/event_dashboard/' + EventId, { state: { event_name: EventName } }); }}>
                                <div className="visibility gap-1 ">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height={20}
                                        viewBox="0 -960 960 960"
                                        width={20}
                                    >
                                        <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                                    </svg>
                                    Back
                                </div>
                            </div>
                        </div>

                        <div className='dashboard-card border-radius-10 mb-6 p-4'>
                            <div className="row my-2">
                                <div className="col-sm-6 d-flex align-items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                                <p className='h4'>Search Filter</p>
                                </div>
                                <div className="col-sm-6 d-flex align-items-center justify-content-end gap-2">
                                <div>
                                {
                                    (ParticipantName !== "" || TransactionStatus !== "" || RegistrationID !== "" || MobileNumber !== "" || Email !== "" || Ticket !== "" || FromDate !== "" || ToDate !== "" || TransactionID !== "")  ?
                                        
                                            <p className="account__login--btn secondary__btn" onClick={(e) => {
                                                HandleClear(e);
                                            }} style={{cursor: 'pointer'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> Clear</p>
                                        
                                        : null
                                }
                                </div>
                                <div>
                                {AttendeeData && AttendeeData.length > 0 ?
                                        // onClick={onDownload}
                                        
                                        <>
                                           {AccessRightFlag === '1' ?
                                                <>
                                                    {OrgUserAccessModules && OrgUserAccessModules.Insight === 2 ?
                                                        <>
                                                            <div className="d-flex gap-2">
                                                                {/* <a className="account__login--btn secondary__btn d-flex align-items-center gap-2" href={AttendeeDetailsExcel} title='Attendee Details Download' download >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Download 
                                                                </a>

                                                                <a className="account__login--btn secondary__btn d-flex align-items-center gap-2" href={RemittanceExcel} title='Remittance Excel Download' download >
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Revenue
                                                                </a>
                                                                
                                                                */}

                                                                <div className="account__login--btn secondary__btn d-flex align-items-center gap-2" title='Attendee Details Download' onClick={(e) => { setDownloadExcelFlag('attendee'); setDownloadParticipantExcel(true)}} style={{cursor: 'pointer'}} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Download
                                                                </div>

                                                                <div className="account__login--btn secondary__btn d-flex align-items-center gap-2" title='Revenue Details Download' onClick={(e) => { setDownloadExcelFlag('revenue'); setDownloadParticipantExcel(true)}} style={{cursor: 'pointer'}} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Revenue
                                                                </div>

                                                            </div>
                                                        </>
                                                    : null }
                                                </>
                                            : 
                                                <>
                                                    <div className="d-flex gap-2">
                                                        {/* <a className="account__login--btn secondary__btn d-flex align-items-center gap-2" href={AttendeeDetailsExcel} title='Attendee Details Download' download >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Download 
                                                        </a> */}

                                                        <div className="account__login--btn secondary__btn d-flex align-items-center gap-2" title='Attendee Details Download' onClick={(e) => { setDownloadExcelFlag('attendee'); setDownloadParticipantExcel(true)}} style={{cursor: 'pointer'}} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Download
                                                        </div>

                                                        <div className="account__login--btn secondary__btn d-flex align-items-center gap-2" title='Revenue Details Download' onClick={(e) => { setDownloadExcelFlag('revenue'); setDownloadParticipantExcel(true)}} style={{cursor: 'pointer'}} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Revenue
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                
                                        : ''}
                                </div>
                                
                                </div>
                            </div>
                            <div className='row my-2 align-items-center'>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={ParticipantName}
                                            onChange={(e) => {
                                                setParticipantName(e.target.value); setApiCall(true);
                                            }}
                                        />
                                        <label>Participant Name </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <select className="form-control" name="category" value={TransactionStatus} onChange={(e) => { setTransactionStatus(e.target.value); setApiCall(true); }}>
                                            <option value="">-- Select Transaction Status --</option>
                                            <option value="1">Success</option>
                                            <option value="3">Free</option>
                                            <option value="2">Failure</option>
                                            <option value="101">In Process</option>
                                            <option value="102">Success/Free</option>
                                        </select>
                                        <label>Transaction Status <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={RegistrationID}
                                            onChange={(e) => {
                                                setRegistrationID(e.target.value); setApiCall(true);
                                            }}
                                        />
                                        <label>Registration ID </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={MobileNumber}
                                            onChange={(e) => {
                                                setMobileNumber(e.target.value); setApiCall(true);
                                            }}
                                        />
                                        <label>Mobile Number </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={Email}
                                            onChange={(e) => {
                                                setEmail(e.target.value); setApiCall(true);
                                            }}
                                        />
                                        <label>Email </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <select className="form-control" name="category" value={Ticket} onChange={(e) => { setTicket(e.target.value); setApiCall(true); }}>
                                            <option value="">-- Select Category --</option>
                                            {
                                                TicketData && TicketData.length > 0 ? TicketData.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.ticket_name}</option>
                                                }) : null
                                            }
                                        </select>
                                        <label>Category </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={FromDate}
                                            onChange={(e) => {
                                                setFromDate(e.target.value);
                                                setApiCall(true);
                                            }}

                                        />
                                        <label className="select-label">Date From </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={ToDate}
                                            onChange={(e) => {
                                                setToDate(e.target.value);
                                                setApiCall(true);
                                            }}
                                        />
                                        <label className="select-label">Date To </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={TransactionID}
                                            onChange={(e) => {
                                                setTransactionID(e.target.value); setApiCall(true);
                                            }}
                                        />
                                        <label>Transaction ID</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='dashboard-card p-4 border-radius-10 '>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    {/* ref={tableRef} */}
                                    <table className="table table-hover">
                                        {
                                            AttendeeData && AttendeeData.length > 0 ?
                                                <>
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col">Participant Name</th>
                                                            <th scope="col">Email / Mobile Number</th>
                                                            {/* <th scope="col">Mobile Number</th> */}
                                                            <th scope="col">Category Name</th>
                                                            <th scope="col">Booking Date</th>
                                                            <th scope="col">Transaction ID</th>
                                                            <th scope="col">Registration ID</th>
                                                            <th scope="col">Total Amount</th>
                                                            <th scope="col">Transaction Status</th>
                                                            <th scope="col" style={{textAlign: 'center'}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {AttendeeData.map((user, index) => {
                                                            const transactionStatusText = user.transaction_status === 0 ? 'In Progress' :
                                                                user.transaction_status === 1 ? 'Success' :
                                                                    user.transaction_status === 2 ? 'Failure' : user.transaction_status === 3 ? 'Free' : '';
                                                                    // console.log(page);
                                                               const no = page !== 1 ? ((page * limit - limit) + parseFloat(index+1)) : index + 1;   
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row" className=' text-center'>{no}</th>
                                                                    <td>{user.firstname !== "null" ? user.firstname : ""}{" "}{user.lastname !== "null" ? user.lastname : ""}</td>
                                                                    <td>{user.email}<br /> {user.mobile}</td>
                                                                    {/* <td>{user.mobile}</td> */}
                                                                    <td>{user.TicketName}</td>
                                                                    <td>{user.booking_date}</td>
                                                                    <td>{user.transaction_id}</td>
                                                                    <td>{user.registration_id}</td>
                                                                    <td>{'₹ '+parseFloat(user.total_amount).toFixed(2)}</td>
                                                                    <td>{transactionStatusText}</td>
                                                                    <td >
                                                                        {user.transaction_status === 1 || user.transaction_status === 3 ?
                                                                            
                                                                            <>
                                                                               {AccessRightFlag === '1' ?
                                                                                <>
                                                                                    {OrgUserAccessModules.Insight === 2 ?
                                                                                        <div title='Change Status' style={{ display: 'inline' }}  onClick={(e) => {setAtteId(user.aId); setBookingDetailsId(user.booking_details_id); setConfirmPopup(true); } } >
                                                                                            <svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" height="20" width="40" style={{ cursor: 'pointer' }}><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                                                                                        </div>
                                                                                    : null }
                                                                                </>
                                                                            : 
                                                                                    <div title='Change Status' style={{ display: 'inline' }}  onClick={(e) => {setAtteId(user.aId); setBookingDetailsId(user.booking_details_id); setConfirmPopup(true); } } >
                                                                                        <svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" height="20" width="40" style={{ cursor: 'pointer' }}><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                                                                                    </div>
                                                                            }
                                                                            </>
                                                                        : 
                                                                     
                                                                        <>
                                                                        {AccessRightFlag === '1' ?
                                                                        <>
                                                                            {OrgUserAccessModules.Insight === 2 ?
                                                                                <div title='Change Status' style={{ display: 'inline' }}  onClick={(e) => {setAtteId(user.aId); setBookingDetailsId(user.booking_details_id); setConfirmPopup(true); } } className='invisible' >
                                                                                    <svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" height="20" width="40" style={{ cursor: 'pointer' }}><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                                                                                </div>
                                                                            : null }
                                                                        </>
                                                                        : 
                                                                            <div title='Change Status' style={{ display: 'inline' }}  onClick={(e) => {setAtteId(user.aId); setBookingDetailsId(user.booking_details_id); setConfirmPopup(true); } } className='invisible' >
                                                                                <svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" height="20" width="40" style={{ cursor: 'pointer' }}><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                                                                            </div>
                                                                        }
                                                                        </>

                                                                        }

                                                                        
                                                                        {AccessRightFlag === '1' ?
                                                                            <>
                                                                                {OrgUserAccessModules && OrgUserAccessModules.Insight === 2 ?
                                                                                    <div title='Send Confirmation Email' onClick={() => { setConfirmEmailPopup(true); setAttendeeId(user.aId); }} style={{ display: 'inline' }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" height="20" width="40"><path d="M480-440 160-640v400h360v80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v280h-80v-200L480-440Zm0-80 320-200H160l320 200ZM760-40l-56-56 63-64H600v-80h167l-64-64 57-56 160 160L760-40ZM160-640v440-240 3-283 80Z" /></svg>
                                                                                    </div>
                                                                                : null }
                                                                            </>
                                                                        : 
                                                                            <div title='Send Confirmation Email' onClick={() => { setConfirmEmailPopup(true); setAttendeeId(user.aId); }} style={{ display: 'inline' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" height="20" width="40"><path d="M480-440 160-640v400h360v80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v280h-80v-200L480-440Zm0-80 320-200H160l320 200ZM760-40l-56-56 63-64H600v-80h167l-64-64 57-56 160 160L760-40ZM160-640v440-240 3-283 80Z" /></svg>
                                                                            </div>
                                                                        }

                                                                        <div title='View Details' style={{ display: 'inline' }} onClick={(e) => ViewBookingData(user.aId, user.booking_details_id)} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" height="20" width="40"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
                                                                        </div>


                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </>
                                                :
                                                <><p>No participants Found</p></>
                                        }
                                    </table>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-12'>
                                
                                {
                                    TotalRecord > 0 ?
                                    <Pagination
                                        records={AttendeeData.length}
                                        link={"event_netsale/"+EventId}
                                        SelectedPage={page}
                                        search={"&event_id=" + EventId + "&user_id=" + UserId + "&EventBookingId=0&participant_name=" + ParticipantName + "&reg_id=" + RegistrationID + "&mobile_number=" + MobileNumber + "&email=" + Email + "&ticket_id=" + Ticket + "&from_date=" + FromDate + "&to_date=" + ToDate + "&TransactionStatus=" + TransactionStatus + "&TransactionID=" + TransactionID + "&coupon_used_flag=0"}
                                        NoOfRecords={TotalRecord}
                                    /> : null
                                }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default EventDashboardNetSales